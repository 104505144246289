import { Redirect, RouteComponentProps, useNavigate } from "@reach/router";
import CompleteProfileForm from "../../services/auth/CompleteProfileForm";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { PUBLIC } from "../../routes/public";
import useAuth from "../../services/auth/hooks/useAuth";
import { useToasts } from "../../services/toast-notifications";
import { useCallback } from "react";

const CompleteProfile = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation(["auth", "ui"]);
  const { register } = useAuth();
  const { user: auth0User } = useAuth0();
  const { success, error } = useToasts();
  const navigate = useNavigate();

  const { login: authLogin } = useAuth();
  const { getAccessTokenSilently } = useAuth0();

  const login = useCallback(
    () =>
      getAccessTokenSilently()
        .then((auth0AccessToken) => authLogin({ auth0AccessToken }))
        .catch((err) => {
          switch (err.response?.status) {
            case 404:
              error(t("auth:login.CREDENTIAL_ERROR"));
              break;
            case 412:
              error(t("auth:login.ACCOUNT_NOT_ACTIVATED"));
              break;
            default:
              error(t("auth:login.AUTH_ERROR"));
          }
        }),
    [authLogin, error, getAccessTokenSilently, t],
  );

  return auth0User ? (
    <div>
      <h1 className={"page-title"}>{t("auth:COMPLETE_PROFILE")}</h1>

      <CompleteProfileForm
        user={{
          email: auth0User.email as string,
          firstname: auth0User.given_name || "",
          lastname: auth0User.family_name || "",
        }}
        onSubmit={(values) =>
          register(values).then(
            (res) => {
              if (res.status === 201) {
                login();
              } else {
                success(t("auth:register.TOAST_SUCCESS"));
              }
              navigate(PUBLIC);
            },
            () => {
              error(t("auth:register.TOAST_ERROR"));
            },
          )
        }
      />
    </div>
  ) : (
    <Redirect to={PUBLIC} noThrow />
  );
};

export default CompleteProfile;
