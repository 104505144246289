import { createContext, useContext, useState } from "react";
import { Committee, CommitteeBase, CommitteeToSend } from "./committee";
import {
  createCommittee,
  getCommitteeById,
  removeContributionsFromCommittee as removeContributionsFromCommitteeAPI,
  bulkUpdateContributionsStatuses as bulkUpdateContributionsStatusesAPI,
  updateCommittee,
  closeCommitteeById,
  reopenCommitteeById,
} from "./api";
import {
  Contribution,
  ContributionStatus,
} from "../contributions/contribution";

export interface CommitteeAPI {
  committee: CommitteeBase | null;
  loadCommittee(committeeId: Committee["id"]): Promise<Committee>;
  createCommittee(committee: CommitteeToSend): Promise<Committee>;
  updateCommittee(committee: Committee): Promise<void>;
  removeContributionsFromCommittee(
    committeeId: Committee["id"],
    contributionsIds: Contribution["id"][],
  ): Promise<Committee>;
  bulkUpdateContributionsStatuses(
    committeeId: Committee["id"],
    contributionsIds: Contribution["id"][],
    status: ContributionStatus,
  ): Promise<Committee>;
  closeCommittee(committeeId: Committee["id"]): Promise<Committee>;
  reopenCommittee(committeeId: Committee["id"]): Promise<Committee>;
}

export interface CommitteeAPILoaded extends CommitteeAPI {
  committee: Committee;
}

export function useProvideCommittee(): CommitteeAPI {
  const [committee, setCommittee] = useState<CommitteeBase | null>(null);

  const loadCommittee = (committeeId: Committee["id"]): Promise<Committee> => {
    return getCommitteeById(committeeId).then((res) => {
      setCommittee(res.data);
      return res.data;
    });
  };

  return {
    committee,
    loadCommittee,
    createCommittee(committee) {
      return createCommittee(committee).then((res) => {
        setCommittee(res.data);
        return res.data;
      });
    },
    updateCommittee(committee) {
      return updateCommittee(committee).then(() => {
        setCommittee(committee);
      });
    },
    removeContributionsFromCommittee(committeeId, contributionsIds) {
      return removeContributionsFromCommitteeAPI(
        committeeId,
        contributionsIds,
      ).then(() => loadCommittee(committeeId));
    },
    bulkUpdateContributionsStatuses(committeeId, contributionsIds, status) {
      return bulkUpdateContributionsStatusesAPI(
        committeeId,
        contributionsIds,
        status,
      ).then(() => loadCommittee(committeeId));
    },
    closeCommittee(committeeId) {
      return closeCommitteeById(committeeId).then((res) => {
        setCommittee((prevCommittee) => ({ ...prevCommittee, ...res.data }));
        return res.data;
      });
    },
    reopenCommittee(committeeId) {
      return reopenCommitteeById(committeeId).then((res) => {
        setCommittee((prevCommittee) => ({ ...prevCommittee, ...res.data }));
        return res.data;
      });
    },
  };
}

export const CommitteeContext = createContext<CommitteeAPI>({
  committee: null,
  loadCommittee(committeeId) {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  createCommittee(committee) {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  updateCommittee(committee) {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  removeContributionsFromCommittee() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  bulkUpdateContributionsStatuses() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  closeCommittee(committeeId) {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  reopenCommittee(committeeId) {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
});

export function useCommittee(): CommitteeAPI {
  return useContext(CommitteeContext);
}

export interface WithProvideCommittee {
  committeeAPI: CommitteeAPI;
}
