import React, { FunctionComponent, useMemo, useState } from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import { date, number, object, string } from "yup";
import {
  COMMITTEE_STATUS_MAP,
  COMMITTEE_TYPES,
  getCommitteeStatus,
} from "../../../../services/committees/committee";
import ValidationsErrors from "../../../../services/validations/ValidationsErrors";
import { useTranslation } from "react-i18next";
import {
  CommitteeAPILoaded,
  useCommittee,
} from "../../../../services/committees/useProvideCommittee";
import { useToasts } from "../../../../services/toast-notifications";
import Link from "../../../../services/routing/components/Link";
import Dialog from "../../../../services/ui/elements/Dialog";
import { useProvideCommittees } from "../../../../services/committees/useProvideCommittees";
import { COMMITTEES_PANEL_LINK } from "../../../../routes/committees";
import CommitteeFields from "../../../../services/committees/CommitteeFields";
import IconTrash from "../../../../services/icons/IconTrash";

const CommitteeDetails: FunctionComponent<RouteComponentProps> = () => {
  /* APIs */
  const { t } = useTranslation(["committees", "ui"]);
  const navigate = useNavigate();
  const { success, error } = useToasts();
  const {
    committee,
    updateCommittee,
    reopenCommittee,
    closeCommittee,
  } = useCommittee() as CommitteeAPILoaded;
  const { deleteCommittee } = useProvideCommittees();

  /* Hooks */
  const [closeCommitteeDialogOpen, setCloseCommitteeDialogOpen] = useState(
    false,
  );
  const [deleteCommitteeDialogOpen, setDeleteCommitteeDialogOpen] = useState(
    false,
  );

  const CommitteeSchema = useMemo(
    () =>
      object({
        id: number().defined(),
        name: string().label(t("committees:NAME")).required(),
        type: number()
          .label(t("committees:TYPE"))
          .nullable()
          .required()
          .oneOf(COMMITTEE_TYPES),
        date: date().label(t("committees:DATE")).required(),
        closureDate: string().nullable().defined(),
      }),
    [t],
  );

  /* Methods */
  const deleteThisCommittee = () => {
    return deleteCommittee(committee.id).then(
      () => {
        success(t("committees:DELETE_COMMITTEE.SUCCESS"));
        return navigate(COMMITTEES_PANEL_LINK);
      },
      (err) => {
        if (err.response.status === 409) {
          error(t("committees:DELETE_COMMITTEE.ERROR_NOT_EMPTY"));
        } else {
          error(t("committees:DELETE_COMMITTEE.ERROR"));
        }
      },
    );
  };

  const toggleCommittee = () => {
    if (committee.closureDate) {
      reopenCommittee(committee.id).then(
        () => {
          success(t("committees:REOPEN_COMMITTEE.SUCCESS"));
          setCloseCommitteeDialogOpen(false);
        },
        () => error(t("committees:REOPEN_COMMITTEE.ERROR")),
      );
    } else {
      closeCommittee(committee.id).then(
        () => {
          success(t("committees:CLOSE_COMMITTEE.SUCCESS"));
          setCloseCommitteeDialogOpen(false);
        },
        () => error(t("committees:CLOSE_COMMITTEE.ERROR")),
      );
    }
  };

  return (
    <>
      <div className="page-content">
        <Formik
          validationSchema={CommitteeSchema}
          initialValues={committee}
          enableReinitialize={true}
          onSubmit={(values) => {
            return updateCommittee(values).then(
              () => {
                success(t("committees:UPDATE_COMMITTEE.SUCCESS"));
              },
              () => error(t("committees:UPDATE_COMMITTEE.ERROR")),
            );
          }}
        >
          {({ values, errors }) => {
            return (
              <>
                <Form>
                  <div className="grid">
                    <div className="col-md-1-3">
                      <fieldset disabled={!!values.closureDate}>
                        <CommitteeFields />
                      </fieldset>
                    </div>
                    <div className="col-md-1-3">
                      <div className="grid">
                        <div className={"input-block col-md-1-3"}>
                          <label className="input-label" htmlFor="status">
                            {t("committees:STATUS")}
                          </label>
                          <p className="status">
                            {t(
                              `committees:status.${
                                COMMITTEE_STATUS_MAP[getCommitteeStatus(values)]
                              }`,
                            )}
                          </p>
                        </div>
                        <div className={"input-block col-md-2-3"}>
                          <button
                            className="btn-2"
                            type="button"
                            onClick={() => setCloseCommitteeDialogOpen(true)}
                          >
                            {t(
                              !!values.closureDate
                                ? "committees:REOPEN"
                                : "committees:CLOSE",
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <ValidationsErrors errors={errors} />
                  </div>

                  <div className="form-footer btns-bar">
                    <Link className="btn-2" to={COMMITTEES_PANEL_LINK}>
                      {t("ui:CANCEL")}
                    </Link>
                    {!values.closureDate && (
                      <button className="btn-1" type="submit">
                        {t("ui:SAVE")}
                      </button>
                    )}
                    <button
                      className="link"
                      type="button"
                      onClick={() => setDeleteCommitteeDialogOpen(true)}
                    >
                      <IconTrash />
                    </button>
                  </div>
                </Form>
                {deleteCommitteeDialogOpen && (
                  <Dialog onClose={() => setDeleteCommitteeDialogOpen(false)}>
                    {t("committees:CONFIRMATION_DELETE")}
                    <div className="btns-bar dialog-footer">
                      <button
                        type={"button"}
                        className={"btn-outlined"}
                        onClick={() => setDeleteCommitteeDialogOpen(false)}
                      >
                        {t("ui:CANCEL")}
                      </button>
                      <button
                        type={"button"}
                        className={"btn-1"}
                        onClick={deleteThisCommittee}
                      >
                        {t("ui:CONFIRM")}
                      </button>
                    </div>
                  </Dialog>
                )}
                {closeCommitteeDialogOpen && (
                  <Dialog onClose={() => setCloseCommitteeDialogOpen(false)}>
                    {t(
                      !!values.closureDate
                        ? "committees:CONFIRMATION_REOPEN"
                        : "committees:CONFIRMATION_CLOSE",
                    )}
                    <div className={"btns-bar dialog-footer"}>
                      <button
                        type={"button"}
                        className={"btn-outlined"}
                        onClick={() => setCloseCommitteeDialogOpen(false)}
                      >
                        {t("ui:CANCEL")}
                      </button>
                      <button
                        type={"button"}
                        className={"btn-1"}
                        onClick={toggleCommittee}
                      >
                        {t("ui:CONFIRM")}
                      </button>
                    </div>
                  </Dialog>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CommitteeDetails;
