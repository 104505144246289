import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import ValidationsErrors from "../services/validations/ValidationsErrors";
import { sendContact } from "../services/auth/api";
import { useToasts } from "../services/toast-notifications";
import { useTranslation } from "react-i18next";

const ContactSchema = object({
  email: string().label("email").required().email(),
  name: string().label("nom").required(),
  message: string().label("message").required(),
  subject: string().label("sujet").required(),
});

const Contact: FunctionComponent<RouteComponentProps> = () => {
  const { error: toastError, success: toastSuccess } = useToasts();
  const { t } = useTranslation(["auth", "ui"]);

  return (
    <div>
      <h1 className="page-title">{t("auth:contact.TITLE")}</h1>

      <Formik
        validationSchema={ContactSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          email: "",
          name: "",
          message: "",
          subject: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          sendContact(ContactSchema.validateSync(values)).then(
            () => {
              resetForm({});
              toastSuccess(t("auth:SEND_CONTACT.SUCCESS"));
              setSubmitting(false);
            },
            () => {
              toastError(t("auth:SEND_CONTACT.ERROR"));
              setSubmitting(false);
            },
          );
        }}
      >
        {({ errors, submitForm, isSubmitting }) => {
          return (
            <Form noValidate>
              <div className={"input-block"}>
                <label className="input-label">
                  {t("auth:contact.NAME")} *
                </label>
                <Field name={"name"} className={"input"} />
              </div>

              <div className={"input-block"}>
                <label className="input-label">
                  {t("auth:contact.EMAIL")} *
                </label>
                <Field name={"email"} className={"input"} />
              </div>

              <div className={"input-block"}>
                <label className="input-label">
                  {t("auth:contact.SUBJECT")} *
                </label>
                <Field name={"subject"} className={"input"} />
              </div>

              <div className={"input-block"}>
                <label className="input-label">
                  {t("auth:contact.MESSAGE")} *
                </label>
                <Field
                  name={"message"}
                  as={"textarea"}
                  className="textarea"
                  cols={30}
                  rows={10}
                />
              </div>

              {/* Handling errors */}
              <ValidationsErrors errors={errors} />

              <div className={"form-footer btns-bar"}>
                <button type="submit" className="btn-1">
                  {t("ui:SEND")}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Contact;
