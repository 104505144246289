import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import pictoLogout from "../../assets/img/picto-logout.svg";

const IconLogout: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return (
    <img src={pictoLogout} className="picto" alt={t("icons:LOGOUT_ALT")} />
  );
};

export default IconLogout;
