import React, { FunctionComponent } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface Props {
  reload(): void;
  error: unknown;
}

const LoaderErrors: FunctionComponent<Props> = ({ reload, error }) => {
  const { t } = useTranslation(["ui"]);

  if (axios.isAxiosError(error)) {
    return (
      <div>
        {t("ui:ERROR_HAPPENED")} ({error.message}){" "}
        <button onClick={reload}>{t("ui:RELOAD")}</button>
      </div>
    );
  }
  return (
    <div>
      {t("ui:ERROR_HAPPENED")}{" "}
      <button onClick={reload}>{t("ui:RELOAD")}</button>
    </div>
  );
};

export default LoaderErrors;
