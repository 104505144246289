import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import logger from "../i18n/logger";
import { useFormikContext } from "formik";

interface Props {
  errors?: unknown;
}

interface ValidationTRObject {
  key: string;
  values: unknown;
}

type ErrorsObject =
  | Record<string, string | ValidationTRObject>
  | { [name: string]: ErrorsObject };

function flattenErrors(
  errors: ErrorsObject,
): [string, string | ValidationTRObject][] {
  return Object.entries(errors).flatMap(([key, value]) => {
    if (typeof value !== "string") {
      if (
        typeof value === "object" &&
        // eslint-disable-next-line i18next/no-literal-string
        !Object.prototype.hasOwnProperty.call(value, "key")
      ) {
        const flatten = flattenErrors(value);
        return flatten.map(
          ([subKey, subValue]) =>
            [`${key}.${subKey}`, subValue] as [
              string,
              string | ValidationTRObject,
            ],
        );
      } else {
        return [[key, value]];
      }
    } else {
      return [[key, value]];
    }
  });
}

const ValidationsErrors: FunctionComponent<Props> = ({
  errors: propsErrors,
}) => {
  const { t } = useTranslation(["validations"]);
  const { errors: formikErrors } = useFormikContext();

  const errors = propsErrors ? propsErrors : formikErrors;

  if (errors && typeof errors == "object") {
    return (
      <ul className={"form-errors"}>
        {flattenErrors(errors as ErrorsObject).map(([field, e]) => {
          const validationKey =
            typeof e === "object" ? `validations:${e.key}` : `validations:${e}`;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          const tr = t(validationKey, e.values);
          if (!tr || tr === "undefined") {
            logger.error(
              `Une validation n'a pas été renseignée pour le champ ${field}`,
              validationKey,
              e,
            );
            return <li key={field}>{validationKey}</li>;
          }
          return <li key={field}>{tr}</li>;
        })}
      </ul>
    );
  }

  return null;
};

export default ValidationsErrors;
