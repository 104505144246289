import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import ContributionForm from "../../services/contributions/ContributionForm";
import {
  ContributionToSend,
  getDefaultContribution,
} from "../../services/contributions/contribution";
import RegisterForm from "../../services/auth/RegisterForm";
import { useToasts } from "../../services/toast-notifications";
import useAuth from "../../services/auth/hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import { PUBLIC } from "../../routes/public";
import CompleteProfileForm from "../../services/auth/CompleteProfileForm";

enum Steps {
  CONTRIBUTION_FORM,
  LOGIN_OR_REGISTER,
  REGISTER,
  COMPLETE_PROFILE,
}

const PublicNewContribution: FunctionComponent<RouteComponentProps> = () => {
  const { error, success } = useToasts();
  const navigate = useNavigate();
  const {
    login,
    auth0State,
    registerAndCreateContributionWithCaptcha,
    registerAndCreateContributionWithAuth0,
  } = useAuth();
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    user: auth0User,
  } = useAuth0();
  const { t } = useTranslation(["auth", "contributions", "ui"]);

  const contribution = useRef<ContributionToSend | null>(null);
  const [step, setStep] = useState(Steps.CONTRIBUTION_FORM);

  const [gotToken, setGotToken] = useState(false);
  useEffect(() => {
    if (
      !gotToken &&
      auth0State?.initierSpan === "new-contrib" &&
      !!auth0State?.contribution
    ) {
      getAccessTokenSilently()
        .then((auth0AccessToken) => {
          setGotToken(true);
          contribution.current = auth0State.contribution as ContributionToSend;
          return login(
            { auth0AccessToken },
            auth0State.contribution as ContributionToSend,
          );
        })
        .catch((err: AxiosError) => {
          switch (err.response?.status) {
            case 404:
              error(t("auth:login.CREDENTIAL_ERROR"));
              break;
            case 428:
              setStep(Steps.COMPLETE_PROFILE);
              break;
            case 412:
              error(t("auth:login.ACCOUNT_NOT_ACTIVATED"));
              break;
            default:
              error(t("auth:login.AUTH_ERROR"));
          }
        });
    }
  }, [auth0State, error, getAccessTokenSilently, gotToken, login, t]);

  const onLogin = useCallback(() => {
    if (contribution.current !== null) {
      const contributionToSend = contribution.current as NonNullable<ContributionToSend>;

      loginWithRedirect({
        appState: {
          // eslint-disable-next-line i18next/no-literal-string
          initierSpan: "new-contrib",
          contribution: contributionToSend,
        },
        organization: process.env.REACT_APP_AUTH0_ORGANIZATION_ID,
      });
    }
  }, [loginWithRedirect]);

  return (
    <>
      <h1 className="page-title">
        {step === Steps.CONTRIBUTION_FORM
          ? t("contributions:NEW_CONTRIBUTION")
          : step === Steps.REGISTER
          ? t("contributions:page.public.new-contribution.REGISTER_TITLE")
          : step === Steps.COMPLETE_PROFILE
          ? t(
              "contributions:page.public.new-contribution.COMPLETE_PROFILE_TITLE",
            )
          : t("contributions:page.public.new-contribution.DEFAULT_TITLE")}
      </h1>
      {step === Steps.LOGIN_OR_REGISTER && (
        <p>{t("contributions:page.public.new-contribution.WHY_REGISTER")}</p>
      )}

      {step === Steps.CONTRIBUTION_FORM && (
        <ContributionForm
          user={null}
          contribution={contribution.current || getDefaultContribution()}
          onSubmit={(values) => {
            contribution.current = values;
            setStep(Steps.LOGIN_OR_REGISTER);
          }}
        />
      )}
      {step === Steps.LOGIN_OR_REGISTER && (
        <div className="section btns-bar vertical">
          <button className="btn-1" onClick={() => onLogin()}>
            {t("auth:I_HAVE_AN_ACCOUNT_LOGIN")}
          </button>
          <button className="btn-2" onClick={() => setStep(Steps.REGISTER)}>
            {t("auth:I_DONT_HAVE_AN_ACCOUNT_REGISTER")}
          </button>
          <button
            className="link link-small"
            onClick={() => setStep(Steps.CONTRIBUTION_FORM)}
          >
            {t("ui:CANCEL")}
          </button>
        </div>
      )}
      {step === Steps.REGISTER && (
        <RegisterForm
          needCaptcha
          onSubmit={(registerInfos, setSubmitting) => {
            if (contribution.current) {
              registerAndCreateContributionWithCaptcha(
                registerInfos,
                contribution.current,
                registerInfos.gRecaptchaToken,
              ).then(
                () => {
                  success(t("auth:register.TOAST_SUCCESS"));
                  setSubmitting(false);
                  return navigate(PUBLIC);
                },
                (err: AxiosError) => {
                  switch (err.response?.status) {
                    case 409:
                      error(t("auth:register.MAIL_ALREADY_USED"));
                      break;
                    case 428:
                      setStep(Steps.LOGIN_OR_REGISTER);
                      error(t("auth:register.AUTH0_ACCOUNT_FOUND"));
                      break;
                    default:
                      error(t("auth:register.TOAST_ERROR"));
                  }
                  setSubmitting(false);
                },
              );
            } else {
              error(t("auth:register.TOAST_ERROR"));
              setSubmitting(false);
            }
          }}
          onCancel={() => setStep(Steps.LOGIN_OR_REGISTER)}
        />
      )}

      {step === Steps.COMPLETE_PROFILE && (
        <CompleteProfileForm
          user={{
            email: auth0User!.email as string,
            firstname: auth0User!.given_name || "",
            lastname: auth0User!.family_name || "",
          }}
          onSubmit={(values) =>
            getAccessTokenSilently().then((auth0AccessToken) => {
              if (contribution.current) {
                return registerAndCreateContributionWithAuth0(
                  values,
                  contribution.current,
                  auth0AccessToken,
                ).then(
                  (resp) => {
                    if (resp.status === 201) {
                      login({ auth0AccessToken });
                    } else {
                      success(t("auth:register.TOAST_SUCCESS"));
                      return navigate(PUBLIC);
                    }
                  },
                  (err: AxiosError) => {
                    switch (err.response?.status) {
                      case 409:
                        error(t("auth:register.MAIL_ALREADY_USED"));
                        break;
                      default:
                        error(t("auth:register.TOAST_ERROR"));
                    }
                  },
                );
              } else {
                error(t("auth:register.TOAST_ERROR"));
              }
            })
          }
        />
      )}
    </>
  );
};

export default PublicNewContribution;
