import React, { FunctionComponent, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Link from "../../../services/routing/components/Link";
import { CONTRIBUTIONS_LINK } from "../../../routes/private";
import useLoader from "../../../services/routing/useLoader";
import Loading from "../../../services/routing/components/Loading";
import LoaderErrors from "../../../services/routing/components/LoaderErrors";
import { useProvideUsers } from "../../../services/users/useProvideUsers";
import {
  getUserType,
  User,
  USER_ALL_TYPES,
  USER_COMMITTEE_ENTRIES,
  USER_TYPES_ENTRIES,
  USER_TYPES_MAP,
  UserCommittee,
  UserRole,
  UserType,
  USER_ALL_COMMITTEES,
} from "../../../services/auth/user";
import { useTranslation } from "react-i18next";
import { searchGenerator } from "../../../services/filter/search";
import { useToasts } from "../../../services/toast-notifications";
import useAuth from "../../../services/auth/hooks/useAuth";
import { AuthAPIConnected } from "src/services/auth/types";

const UsersAdminPanel: FunctionComponent<RouteComponentProps> = () => {
  /* Loading */
  const {
    users,
    loadAllUsers,
    updateUserRole,
    updateUserCommittee,
  } = useProvideUsers();
  const { loading, error: loadingError, reload } = useLoader(loadAllUsers, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* APIs */
  const { t } = useTranslation(["users", "auth"]);
  const { success, error } = useToasts();
  const { user: currentUser } = useAuth() as AuthAPIConnected;

  /* Hooks */
  const [searchedText, setSearchedText] = useState("");
  const [filteredType, setFilteredType] = useState(USER_ALL_TYPES);
  const [filteredRole, setFilteredRole] = useState(USER_ALL_COMMITTEES);

  const filteredUsers = useMemo(() => {
    let filteredUsers = [...users.values()];

    if (filteredType !== USER_ALL_TYPES) {
      filteredUsers = filteredUsers.filter(
        (user) => getUserType(user) === filteredType,
      );
    }

    if (filteredRole !== USER_ALL_COMMITTEES) {
      filteredUsers = filteredUsers.filter(
        (user) => user.committee === filteredRole,
      );
    }

    filteredUsers = filteredUsers.filter(
      searchGenerator(searchedText, (user) => [
        user.email,
        user.firstname,
        user.lastname,
        user.pseudo,
      ]),
    );

    return filteredUsers;
  }, [users, searchedText, filteredType, filteredRole]);

  /* Methods */
  const setUserRole = (user: User, newRole: UserRole) => {
    updateUserRole(user.userId, newRole).then(
      () => success(t("users:update-user-role.SUCCESS")),
      () => error(t("users:update-user-role.ERROR")),
    );
  };

  const setUserCommittee = (user: User, newCommittee: UserCommittee) => {
    updateUserCommittee(user.userId, newCommittee).then(
      () => success(t("users:update-user-committee.SUCCESS")),
      () => error(t("users:update-user-committee.ERROR")),
    );
  };

  /* End loading */
  if (loading) return <Loading />;
  if (loadingError)
    return <LoaderErrors reload={reload} error={loadingError} />;

  return (
    <>
      <div className="page-head">
        <h1 className="page-title">{t("users:USERS_MANAGEMENT")}</h1>
        <Link className="link link-small" to={CONTRIBUTIONS_LINK}>
          &lt; {t("users:GO_BACK_TO_CONTRIBUTIONS")}
        </Link>
      </div>
      <div className="page-content">
        <div className="grid">
          {users.size > 0 && (
            <div className="col-md-1-2">
              <input
                type="text"
                className="input input-search"
                placeholder={t("users:SEARCH_USER")}
                onChange={(ev) => setSearchedText(ev.target.value)}
              />
            </div>
          )}
        </div>
        <div className="grid input-block">
          <div className="col-md-1-2">
            <label className="input-label">{t("users:FILTER_BY_TYPE")}</label>
            <select
              className="select-s"
              onChange={(ev) => setFilteredType(parseInt(ev.target.value, 10))}
              defaultValue={filteredType}
            >
              <option value={USER_ALL_TYPES}>{t("users:type.ALL")}</option>
              {USER_TYPES_ENTRIES.map(([id, name]) => (
                <option value={id} key={id}>
                  {t(`users:type.${name}`)}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1-2">
            <label className="input-label">{t("users:FILTER_BY_ROLE")}</label>
            <select
              className="select-s"
              onChange={(ev) => setFilteredRole(parseInt(ev.target.value, 10))}
              defaultValue={filteredRole}
            >
              <option value={USER_ALL_COMMITTEES}>{t("users:role.ALL")}</option>
              {USER_COMMITTEE_ENTRIES.map(([id, name]) => (
                <option value={id} key={id}>
                  {t(`users:committee.${name}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="grid input-block">
          <table className="table-1 col-1-1">
            <thead>
              <tr>
                <th>{t("auth:EMAIL")}</th>
                <th>{t("auth:LASTNAME")}</th>
                <th>{t("auth:FIRSTNAME")}</th>
                <th>{t("auth:TYPE")}</th>
                <th>{t("auth:ROLE")}</th>
                <th>{t("auth:ADMIN")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.userId}>
                  <td>{user.email}</td>
                  <td>{user.lastname}</td>
                  <td>{user.firstname}</td>
                  <td>
                    {t(`users:type.${USER_TYPES_MAP[getUserType(user)]}`)}
                  </td>
                  <td>
                    <select
                      className="select-s"
                      defaultValue={user.committee}
                      onChange={(ev) =>
                        setUserCommittee(user, parseInt(ev.target.value, 10))
                      }
                      disabled={user.userId === currentUser.userId}
                    >
                      {USER_COMMITTEE_ENTRIES.map(([id, name]) => (
                        <option value={id} key={id}>
                          {t(`users:committee.${name}`)}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={user.admin === 1}
                      onChange={(ev) =>
                        setUserRole(user, ev.target.checked ? 1 : 0)
                      }
                      disabled={
                        user.userId === currentUser.userId ||
                        getUserType(user) === UserType.EXTERNAL
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UsersAdminPanel;
