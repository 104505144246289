import { FunctionComponent, useMemo } from "react";
import Dialog from "../ui/elements/Dialog";
import { Field, Form, Formik } from "formik";
import FSelectParse from "../ui/elements/FSelectParse";
import {
  CONTRIBUTION_STATUSES_ENTRIES,
  ContributionStatus,
} from "./contribution";
import ValidationsErrors from "../validations/ValidationsErrors";
import { useTranslation } from "react-i18next";
import { number, object, string } from "yup";
interface Props {
  onClose(): void;
  currentStatus: ContributionStatus | null;
  onSubmit(statusValues: Status): void;
}

type Status = {
  status: Props["currentStatus"];
  comment: string;
};

const ContributionStatusDialog: FunctionComponent<Props> = ({
  onClose,
  currentStatus,
  onSubmit,
}) => {
  /* APIs */
  const { t } = useTranslation(["contributions", "ui"]);

  const StatusSchema = useMemo(
    () =>
      object()
        .shape({
          status: number()
            .label(t("contributions:status.update.CHANGE_THE_STATUS"))
            .required()
            .oneOf(CONTRIBUTION_STATUSES_ENTRIES.map(([id]) => parseInt(id))),
          comment: string().label(t("contributions:status.COMMENT")),
        })
        .defined(),
    [t],
  );

  return (
    <Dialog onClose={onClose}>
      <div className="dialog-head">
        <h3 className="dialog-title">
          {t("contributions:status.update.CHANGE_THE_STATUS")}
        </h3>
      </div>
      <Formik
        validationSchema={StatusSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          status: currentStatus,
          comment: "",
        }}
        onSubmit={onSubmit}
      >
        {({ values: statusValues, errors: statusErrors }) => (
          <Form>
            <div className="popup-body">
              <label className="input-label">
                {t("contributions:status.update.NEW_STATUS")} *
              </label>
              <FSelectParse
                parse={(val) => parseInt(val) || ""}
                name={"status"}
                className="select"
                value={statusValues.status || ""}
              >
                <option value="">--</option>
                {CONTRIBUTION_STATUSES_ENTRIES.slice(1).map(([id, name]) => (
                  <option
                    value={id}
                    key={id}
                    disabled={parseInt(id) === currentStatus}
                  >
                    {t(`contributions:status.${name}`)}
                  </option>
                ))}
              </FSelectParse>
              <label className="input-label">
                {t("contributions:status.COMMENT")}
              </label>
              <Field
                as={"textarea"}
                className="textarea input"
                cols={30}
                rows={10}
                name={"comment"}
                placeholder={t(
                  "contributions:status.update.COMMENT_WILL_BE_SENT",
                )}
              />
            </div>
            <ValidationsErrors errors={statusErrors} />
            <div className="btns-bar dialog-footer">
              <button
                className="btn-outlined"
                type={"button"}
                onClick={onClose}
              >
                {t("ui:CANCEL")}
              </button>
              <button className="btn-1">{t("ui:OK")}</button>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ContributionStatusDialog;
