import React, { FunctionComponent, useMemo, useState } from "react";
import { useProvideContributions } from "../../services/contributions/useProvideContributions";
import { RouteComponentProps } from "@reach/router";
import useLoader from "../../services/routing/useLoader";
import Loading from "../../services/routing/components/Loading";
import LoaderErrors from "../../services/routing/components/LoaderErrors";
import useAuth from "../../services/auth/hooks/useAuth";
import { AuthAPIConnected } from "../../services/auth/types";
import { canSeeAllContributions, isAdmin } from "../../services/auth/user";
import { cx } from "@emotion/css";
import {
  Contribution,
  CONTRIBUTION_ALL_DOMAINS,
  CONTRIBUTION_ALL_STATUSES,
  CONTRIBUTION_ALL_TYPES,
  CONTRIBUTION_DOMAINS_ENTRIES,
  CONTRIBUTION_STATUSES_ENTRIES,
  CONTRIBUTION_TYPES_ENTRIES,
  ContributionDomain,
  ContributionStatus,
  ContributionType,
  getCurrentStatus,
  getTotalScoreSum,
  CONTRIBUTION_ALL_COMMITTEES,
} from "../../services/contributions/contribution";
import { useTranslation } from "react-i18next";
import ContributionCard from "../../services/contributions/ContributionCard";
import { compareAsc, compareDesc, parseISO } from "date-fns";
import { searchGenerator } from "../../services/filter/search";
import Link from "../../services/routing/components/Link";
import { NEW_CONTRIBUTION_LINK } from "../../routes/private";
import { useProvideCommittees } from "src/services/committees/useProvideCommittees";
import { Committee } from "src/services/committees/committee";

const PAGE_SIZE = 12;

const Contributions: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation(["contributions", "ui", "auth"]);
  /* Loader */
  const { contributions, loadAllContributions } = useProvideContributions();
  const { committees, loadAllCommittees } = useProvideCommittees();

  const { user } = useAuth() as AuthAPIConnected;

  const { loading, error, reload } = useLoader(
    () =>
      Promise.all([
        loadAllContributions(),
        user.committee > 0 ? loadAllCommittees() : null,
      ]),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [mineContributions, setMineContributions] = useState(!isAdmin(user));

  /* Order */
  const [orderField, setOrderField] = useState<keyof Contribution>("createdAt");
  const [orderAsc, setOrderAsc] = useState(false);

  /* Filters */
  const [filteredStatus, setFilteredStatus] = useState<
    ContributionStatus | typeof CONTRIBUTION_ALL_STATUSES
  >(CONTRIBUTION_ALL_STATUSES);
  const [filteredType, setFilteredType] = useState<
    ContributionType | typeof CONTRIBUTION_ALL_TYPES
  >(CONTRIBUTION_ALL_TYPES);
  const [filteredDomain, setFilteredDomain] = useState<
    ContributionDomain | typeof CONTRIBUTION_ALL_DOMAINS
  >(CONTRIBUTION_ALL_DOMAINS);
  const [searchedText, setSearchedText] = useState("");
  const [filteredCommittee, setFilteredCommittee] = useState<number>(
    CONTRIBUTION_ALL_COMMITTEES,
  );

  /* Size */
  const [listSize, setListSize] = useState(PAGE_SIZE);

  const filteredContributions = useMemo(() => {
    let filteredContributions = [...contributions.values()];
    if (mineContributions) {
      filteredContributions = filteredContributions.filter(
        (c) => c.userId === user.userId,
      );
    }

    if (filteredStatus !== CONTRIBUTION_ALL_STATUSES)
      filteredContributions = filteredContributions.filter(
        (c) => getCurrentStatus(c) === filteredStatus,
      );

    if (filteredType !== CONTRIBUTION_ALL_TYPES)
      filteredContributions = filteredContributions.filter(
        (c) => c.type === filteredType,
      );

    if (filteredDomain !== CONTRIBUTION_ALL_DOMAINS)
      filteredContributions = filteredContributions.filter(
        (c) => c.domain === filteredDomain,
      );

    if (filteredCommittee !== CONTRIBUTION_ALL_COMMITTEES)
      filteredContributions = filteredContributions.filter(
        (c) =>
          c.Committees?.map((com) => com.id).indexOf(filteredCommittee) !== -1,
      );

    filteredContributions = filteredContributions.filter(
      searchGenerator(searchedText),
    );

    if (orderField === "createdAt") {
      filteredContributions.sort(
        orderAsc
          ? (a, b) => compareAsc(parseISO(a.createdAt), parseISO(b.createdAt))
          : (a, b) => compareDesc(parseISO(a.createdAt), parseISO(b.createdAt)),
      );
    } else {
      /* When ordering asc we don't want the null scores to be at the top */
      filteredContributions.sort(
        orderAsc
          ? (a, b) => {
              const aScore = getTotalScoreSum(a);
              const bScore = getTotalScoreSum(b);
              return aScore === null && bScore === null
                ? 0
                : aScore === null
                ? 1
                : bScore === null
                ? -1
                : aScore - bScore;
            }
          : (a, b) => (getTotalScoreSum(b) || 0) - (getTotalScoreSum(a) || 0),
      );
    }

    return filteredContributions;
  }, [
    contributions,
    mineContributions,
    user.userId,
    filteredStatus,
    filteredType,
    filteredDomain,
    filteredCommittee,
    searchedText,
    orderField,
    orderAsc,
  ]);

  if (loading) return <Loading />;
  if (error) return <LoaderErrors reload={reload} error={error} />;

  const isInternal = user.internal;
  const isEvaluator = user.committee > 0;

  return (
    <>
      <div className="page-head">
        <h1 className="page-title">
          {t(
            `contributions:ALL_CONTRIBUTIONS.${
              isInternal ? "INTERNAL" : "EXTERNAL"
            }`,
          )}
        </h1>
      </div>

      <div className="grid">
        <div className="col-md-1-2">
          <Link to={NEW_CONTRIBUTION_LINK} className="btn-1 block-up-to-xs">
            {t("contributions:NEW_CONTRIBUTION")}
          </Link>
        </div>
        {contributions.size > 0 && (
          <div className="col-md-1-2">
            <input
              type="text"
              className="input input-search"
              placeholder={t("contributions:SEARCH_CONTRIBUTION")}
              onChange={(ev) => setSearchedText(ev.target.value)}
            />
          </div>
        )}
      </div>

      {canSeeAllContributions(user) && (
        <ul className="tabs">
          <li
            className={cx(["tab-item", !mineContributions && "active"])}
            onClick={() => setMineContributions(false)}
          >
            {t("contributions:page.contributions.TAB_ALL_CONTRIBUTIONS")}
          </li>
          <li
            className={cx(["tab-item", mineContributions && "active"])}
            onClick={() => setMineContributions(true)}
          >
            {t("contributions:page.contributions.TAB_MY_CONTRIBUTIONS")}
          </li>
        </ul>
      )}
      {contributions.size > 0 && (
        <div className="grid">
          <div className="col-md-1-2 col-lg-1-4">
            <label htmlFor="" className="input-label">
              {t("contributions:filter.FILTER_BY")}
            </label>
            <select
              className="select"
              onChange={(ev) => {
                setOrderField(ev.target.value.slice(1) as keyof Contribution);
                setOrderAsc(ev.target.value[0] !== "-");
              }}
              defaultValue={`${orderAsc ? "+" : "-"}${orderField}`}
            >
              <option value="+createdAt">{t("ui:ASCENDANT_DATE")}</option>
              <option value="-createdAt">{t("ui:DESCENDANT_DATE")}</option>
              <option value="+totalScore">
                {t("contributions:filter.ASCENDANT_SCORE")}
              </option>
              <option value="-totalScore">
                {t("contributions:filter.DESCENDANT_SCORE")}
              </option>
            </select>
          </div>
          <div className="col-md-1-2 col-lg-1-4">
            <label className="input-label">
              {t("contributions:filter.FILTER_BY_STATUS")}
            </label>
            <select
              className="select"
              onChange={(ev) =>
                setFilteredStatus(parseInt(ev.target.value, 10))
              }
              defaultValue={filteredStatus}
            >
              <option value={CONTRIBUTION_ALL_STATUSES}>
                {t("contributions:status.ALL")}
              </option>
              {CONTRIBUTION_STATUSES_ENTRIES.slice(1).map(([id, name]) => (
                <option value={id} key={id}>
                  {t(`contributions:status.${name}`)}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1-2 col-lg-1-4">
            <label className="input-label">
              {t("contributions:filter.FILTER_BY_TYPE")}
            </label>
            <select
              className="select"
              onChange={(ev) => setFilteredType(parseInt(ev.target.value, 10))}
              defaultValue={filteredType}
            >
              <option value={CONTRIBUTION_ALL_TYPES}>
                {t("contributions:type.ALL")}
              </option>
              {CONTRIBUTION_TYPES_ENTRIES.map(([id, name]) => (
                <option value={id} key={id}>
                  {t(`contributions:type.${name}`)}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1-2 col-lg-1-4">
            <label className="input-label">
              {t("contributions:filter.FILTER_BY_DOMAIN")}
            </label>
            <select
              className="select"
              onChange={(ev) =>
                setFilteredDomain(parseInt(ev.target.value, 10))
              }
              defaultValue={filteredDomain}
            >
              <option value={CONTRIBUTION_ALL_DOMAINS}>
                {t("contributions:domain.ALL")}
              </option>
              {CONTRIBUTION_DOMAINS_ENTRIES.map(([id, name]) => (
                <option value={id} key={id}>
                  {t(`contributions:domain.${name}`)}
                </option>
              ))}
            </select>
          </div>
          {isEvaluator && (
            <div className="col-md-1-2 col-lg-1-4">
              <label className="input-label">
                {t("contributions:filter.FILTER_BY_COMMITTEE")}
              </label>
              <select
                className="select"
                onChange={(ev) =>
                  setFilteredCommittee(parseInt(ev.target.value, 10))
                }
                defaultValue={filteredStatus}
              >
                <option value={CONTRIBUTION_ALL_COMMITTEES}>
                  {t("contributions:committee.ALL")}
                </option>
                {[...committees.values()].map((committee: Committee) => (
                  <option value={committee.id} key={committee.id}>
                    {committee.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      <div className="grid contributions-list">
        {filteredContributions.slice(0, listSize).map((c) => (
          <div key={c.id} className="col-md-1-2 col-lg-1-3">
            <ContributionCard contribution={c} />
          </div>
        ))}
        {filteredContributions.length > listSize && (
          <div className="col-1-1 load-more">
            <button
              className="link"
              onClick={() => setListSize((s) => s + PAGE_SIZE)}
            >
              {t("ui:SEE_MORE")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Contributions;
