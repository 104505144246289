import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import iconChart from "../../assets/img/picto-chart.png";

const IconChart: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img className={"icon"} src={iconChart} alt={t("icons:CHART_ALT")} />;
};

export default IconChart;
