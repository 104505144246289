import {
  Contribution,
  ContributionEvaluationToSend,
  ContributionToSend,
} from "./contribution";
import { createContext, useContext, useState } from "react";
import {
  evaluateContribution,
  createContribution,
  getContribution,
  GetContributionResponse,
  updateContribution,
  deleteContribution,
} from "./api";
import { upsertObjectInNewArray } from "../data-structures/array";

interface ContributionAPI {
  contribution: GetContributionResponse | null;
  createContribution(contribution: ContributionToSend): Promise<Contribution>;
  loadContribution(id: Contribution["id"]): Promise<GetContributionResponse>;
  deleteContribution(id: Contribution["id"]): Promise<void>;
  updateContribution(contribution: ContributionToSend): Promise<void>;
  evaluateContribution(
    contributionId: Contribution["id"],
    evaluation: ContributionEvaluationToSend,
  ): Promise<void>;
}

export default function useProvideContribution(): ContributionAPI {
  const [
    contribution,
    setContribution,
  ] = useState<GetContributionResponse | null>(null);

  return {
    contribution,
    createContribution(contribution) {
      return createContribution(contribution).then((res) => {
        setContribution({
          ...res.data,
        });
        return Promise.resolve(res.data);
      });
    },
    loadContribution(id) {
      return getContribution(id).then((res) => {
        setContribution(res.data);
        return Promise.resolve(res.data);
      });
    },
    deleteContribution(id) {
      return deleteContribution(id).then((res) => {
        setContribution(null);
        return Promise.resolve(res.data);
      });
    },
    updateContribution(contribution) {
      return updateContribution(contribution).then((res) => {
        setContribution((c) => ({
          ...c,
          ...res.data,
        }));
        return Promise.resolve();
      });
    },
    evaluateContribution(contributionId, evaluation) {
      return evaluateContribution(contributionId, evaluation).then((res) => {
        setContribution((prevContribution: GetContributionResponse) => ({
          ...prevContribution,
          Evaluations: upsertObjectInNewArray(
            prevContribution.Evaluations,
            res.data,
            (ev) => ev.userId === res.data.userId,
          ),
        }));
      });
    },
  };
}

export const ContributionContext = createContext<ContributionAPI>({
  contribution: null,
  loadContribution() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  createContribution() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  deleteContribution() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  updateContribution() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  evaluateContribution() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
});

export function useContribution(): ContributionAPI {
  return useContext(ContributionContext);
}
