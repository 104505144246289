export interface UserToSend {
  auth0AccessToken: string;
}

export interface User {
  userId: number;
  email: string;
  lastname: string;
  firstname: string;
  admin: number;
  committee: number;
  pseudo: string | null;
  country: string | null;
  tel: string | null;
  bio: string | null;
  birthdate: string | null;
  password: string;
  internal: boolean;
  company: string | null;
  function: string | null;
  type: number;
  otherType: string | null;
}

export interface PublicUser {
  userId: User["userId"];
  firstname: User["firstname"];
  lastname: User["lastname"];
  type: User["type"];
  otherType: User["otherType"];
}

export interface LoggedUser extends User {
  xsrfToken: string;
}

export interface UserToRegister {
  email: string;
  password?: string;
  lastname: string;
  firstname: string;
  type: User["type"] | null;
  otherType: User["otherType"] | null;
}

export interface RegisterFormUserInfos extends UserToRegister {
  passwordConfirmation: string;
  termsOfUse: boolean;
  securityPolicy: boolean;
  gRecaptchaToken: string;
}

export interface ContactForm {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export interface UserCompleteProfileForm {
  email: string;
  lastname: string;
  firstname: string;
  tel: string | null;
  type: User["type"] | null;
  otherType: User["otherType"] | null;
  termsOfUse: boolean;
  securityPolicy: boolean;
}

/* User typology */
export const USER_ALL_TYPOLOGIES = -1;

export enum UserTypology {
  PRIME_CONTRACTOR,
  INSTALLATION_COMPANY,
  DISTRIBUTOR,
  PRIVATE_INDIVIDUAL,
  COLLABORATOR,
  OTHER,
}

export const USER_TYPOLOGY_ENTRIES = Object.entries(UserTypology).filter(
  ([id]) => !isNaN(parseInt(id)),
) as [string, keyof typeof UserTypology][];

export const USER_TYPOLOGY_MAP = Object.fromEntries(
  Object.entries(UserTypology).map(([key, value]) => [
    value as UserTypology,
    key as keyof typeof UserTypology,
  ]),
);

export function needsTypologyInput(type: UserTypology | null): boolean {
  return type === UserTypology.OTHER;
}

/* User types */
export const USER_ALL_TYPES = -1;

export enum UserType {
  INTERNAL,
  EXTERNAL,
}

export const USER_TYPES_ENTRIES = Object.entries(UserType).filter(
  ([id]) => !isNaN(parseInt(id)),
) as [string, keyof typeof UserType][];

export const USER_TYPES_MAP = Object.fromEntries(
  Object.entries(UserType).map(([key, value]) => [
    value as UserType,
    key as keyof typeof UserType,
  ]),
);

export function getUserType(user: User): UserType {
  return user.internal ? UserType.INTERNAL : UserType.EXTERNAL;
}

/* User roles */
export const USER_ALL_COMMITTEES = -1;

export enum UserRole {
  CONTRIBUTOR,
  ADMINISTRATOR,
}
export const USER_ROLES_ENTRIES = Object.entries(UserRole).filter(
  ([id]) => !isNaN(parseInt(id)),
) as [string, keyof typeof UserRole][];

/* User evaluation */
export enum UserCommittee {
  NO_COMMITTEE = 0b00,
  COMMITTEE_1 = 0b01,
  COMMITTEE_2 = 0b10,
  COMMITTEE_1_AND_2 = 0b11,
}
export const USER_COMMITTEE_ENTRIES = Object.entries(UserCommittee).filter(
  ([id]) => !isNaN(parseInt(id)),
) as [string, keyof typeof UserCommittee][];

export function getShortName(user: User, fallback: string): string {
  return user.firstname && user.lastname
    ? `${user.firstname.slice(0, 1)}. ${user.lastname}`
    : fallback;
}

export function getFullName(user: User): string {
  return `${user.firstname} ${user.lastname.toUpperCase()}`;
}

export function getPseudo(user: User, fallback: string): string {
  return user.pseudo ? user.pseudo : getShortName(user, fallback);
}

export function isInternal(user: User): boolean {
  return user.internal;
}

export function isAdmin(user: User): boolean {
  return user.admin === UserRole.ADMINISTRATOR;
}

export function isEvaluator(user: User): boolean {
  return user.committee !== UserCommittee.NO_COMMITTEE;
}

export function canSeeAllContributions(user: User): boolean {
  return isInternal(user) || isEvaluator(user) || isAdmin(user);
}

export function getCanEvaluateCustomerFit(user: User): boolean {
  return !!(user.committee & UserCommittee.COMMITTEE_1);
}

export function getCanEvaluateCompanyFit(user: User): boolean {
  return !!(user.committee & UserCommittee.COMMITTEE_2);
}
