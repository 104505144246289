import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import useAuth from "./hooks/useAuth";

const LoginButton = ({
  onClick,
  className,
}: {
  onClick(auth0AccessToken: string): Promise<unknown>;
  className: string;
}): JSX.Element => {
  const { t } = useTranslation(["contributions", "auth"]);
  const { auth0State } = useAuth();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [gotToken, setGotToken] = useState(false);
  useEffect(() => {
    if (!gotToken && auth0State?.initierSpan === "login-button") {
      getAccessTokenSilently().then((token) => {
        setGotToken(true);
        return onClick(token);
      });
    }
  }, [auth0State, getAccessTokenSilently, gotToken, onClick]);

  return (
    <button
      className={className}
      onClick={() => {
        loginWithRedirect({
          appState: {
            initierSpan: "login-button",
          },
          organization: process.env.REACT_APP_AUTH0_ORGANIZATION_ID,
        });
      }}
    >
      {t("auth:LOGIN")}
    </button>
  );
};

export default LoginButton;
