import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import pictoFocus from "../../assets/img/picto-focus.svg";

const IconFocus: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img src={pictoFocus} alt={t("icons:FOCUS_ALT")} />;
};

export default IconFocus;
