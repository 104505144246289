import React, { FunctionComponent, useRef, useState } from "react";
import { RouteComponentProps, useNavigate, useParams } from "@reach/router";
import { useScrollTo } from "../../services/ui/utils";
import useProvideContribution from "../../services/contributions/useProvideContribution";
import useLoader from "../../services/routing/useLoader";
import Loading from "../../services/routing/components/Loading";
import LoaderErrors from "../../services/routing/components/LoaderErrors";
import { CONTRIBUTIONS_LINK } from "../../routes/private";
import useAuth from "../../services/auth/hooks/useAuth";
import { AuthAPIConnected } from "../../services/auth/types";
import ContributionForm from "../../services/contributions/ContributionForm";
import { useToasts } from "../../services/toast-notifications";
import { useTranslation } from "react-i18next";
import Dialog from "../../services/ui/elements/Dialog";
import {
  getCanUserMark,
  getDefaultContributionEvaluation,
  getIsPublished,
  getOwnEvaluation,
} from "../../services/contributions/contribution";
import { cx } from "@emotion/css";
import ContributionEvaluationForm from "../../services/contributions/ContributionEvaluationForm";
import { isAdmin } from "../../services/auth/user";
import ContributionAdminForm from "../../services/contributions/ContributionAdminForm";
import ContributionEvaluationsTable from "src/services/contributions/ContributionEvaluationsTable";

enum CONTRIBUTION_TABS {
  DETAILS,
  EVALUATION,
  ADMIN,
}

const ContributionDetails: FunctionComponent<RouteComponentProps> = () => {
  /* APIs */
  useScrollTo();
  const { contributionId } = useParams();
  const {
    loadContribution,
    contribution: nullableContribution,
    updateContribution,
    evaluateContribution,
  } = useProvideContribution();
  const { loading, error, reload } = useLoader(
    () => loadContribution(contributionId),
    [contributionId], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const { user } = useAuth() as AuthAPIConnected;
  const navigate = useNavigate();
  const {
    success: notificationSuccess,
    error: notificationError,
  } = useToasts();
  const { t } = useTranslation(["contributions", "ui", "committees"]);

  /* Hooks */
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(CONTRIBUTION_TABS.DETAILS);
  const formIsDirty = useRef(false);

  /* Handling errors */
  if (loading) return <Loading />;
  if (error) return <LoaderErrors reload={reload} error={error} />;

  const contribution = nullableContribution as NonNullable<
    typeof nullableContribution
  >;

  /* Getters */
  const canUserMark =
    !!user &&
    getIsPublished(contribution) &&
    getCanUserMark(user, contribution);
  const userIsAdmin = !!user && isAdmin(user);

  /* Methods */
  const goBack = async () => {
    if (window.history.length > 1) {
      window.history.go(-1);
    } else {
      await navigate(CONTRIBUTIONS_LINK);
    }
  };

  const cancel = (isDirty: boolean) => {
    isDirty ? setCancelModalOpen(true) : goBack();
  };

  return (
    <>
      <div className="page-head">
        <div className="row-s">
          <div className="col">
            <h1 className="page-title">
              {t("contributions:CONTRIBUTION_DETAILS")}
            </h1>
            <button
              className="link link-small"
              onClick={() => cancel(formIsDirty.current)}
            >
              &lt; {t("ui:GO_BACK")}
            </button>
          </div>
        </div>
      </div>

      {canUserMark && (
        <ul className="tabs">
          <li
            className={cx([
              "tab-item",
              currentTab === CONTRIBUTION_TABS.DETAILS && "active",
            ])}
            onClick={() => setCurrentTab(CONTRIBUTION_TABS.DETAILS)}
          >
            {t("contributions:DETAILS")}
          </li>
          <li
            className={cx([
              "tab-item",
              currentTab === CONTRIBUTION_TABS.EVALUATION && "active",
            ])}
            onClick={() => setCurrentTab(CONTRIBUTION_TABS.EVALUATION)}
          >
            {t("contributions:EVALUATION")}
          </li>
          {userIsAdmin && (
            <li
              className={cx([
                "tab-item",
                currentTab === CONTRIBUTION_TABS.ADMIN && "active",
              ])}
              onClick={() => setCurrentTab(CONTRIBUTION_TABS.ADMIN)}
            >
              {t("committees:COMMITTEE")}
            </li>
          )}
        </ul>
      )}

      {currentTab === CONTRIBUTION_TABS.DETAILS && (
        <ContributionForm
          user={user}
          contribution={contribution}
          onDirtyChange={(isDirty) => (formIsDirty.current = isDirty)}
          onSubmit={(values) => {
            updateContribution(values).then(
              () => {
                notificationSuccess(
                  t(
                    contribution.userId !== user.userId
                      ? "contributions:sent-contribution.ADMIN_SAVED"
                      : values.draft
                      ? "contributions:sent-contribution.SAVED_DRAFT"
                      : "contributions:sent-contribution.SUBMITTED",
                  ),
                );
                navigate(CONTRIBUTIONS_LINK);
              },
              (error) => {
                notificationError(
                  t(
                    error?.response?.status === 400
                      ? "contributions:sent-contribution.ERROR_CREATION_REQUIRED"
                      : "contributions:sent-contribution.ERROR_CREATION",
                  ),
                );
              },
            );
          }}
          onCancel={cancel}
        />
      )}

      {currentTab === CONTRIBUTION_TABS.EVALUATION && (
        <ContributionEvaluationForm
          initialEvaluation={
            getOwnEvaluation(user.userId, contribution) ||
            getDefaultContributionEvaluation()
          }
          contribution={contribution}
          onSubmit={(values) => {
            evaluateContribution(contributionId, values).then(
              () => {
                notificationSuccess(
                  t("contributions:sent-contribution.SUCCESS_EVALUATION"),
                );
              },
              () => {
                notificationError(
                  t("contributions:sent-contribution.ERROR_EVALUATION"),
                );
              },
            );
          }}
          onDirtyChange={(isDirty) => (formIsDirty.current = isDirty)}
          onCancel={cancel}
        />
      )}

      {currentTab === CONTRIBUTION_TABS.ADMIN && (
        <>
          {/* Admin synthesis table to review notes, comments and see averages */}
          <ContributionEvaluationsTable contribution={contribution} />
          {/* now displays form to review the idea and chage status */}
          <ContributionAdminForm
            contribution={contribution}
            onSubmit={(values) => {
              updateContribution(values).then(
                () => {
                  notificationSuccess(
                    t(
                      contribution.userId !== user.userId
                        ? "contributions:sent-contribution.ADMIN_SAVED"
                        : values.draft
                        ? "contributions:sent-contribution.SAVED_DRAFT"
                        : "contributions:sent-contribution.SUBMITTED",
                    ),
                  );
                },
                (error) => {
                  notificationError(
                    t(
                      error?.response?.status === 400
                        ? "contributions:sent-contribution.ERROR_CREATION_REQUIRED"
                        : "contributions:sent-contribution.ERROR_CREATION",
                    ),
                  );
                },
              );
            }}
            onDirtyChange={(isDirty) => (formIsDirty.current = isDirty)}
            onCancel={cancel}
          />
        </>
      )}

      {cancelModalOpen && (
        <Dialog>
          {t("contributions:sent-contribution.CANCELLED")}

          <div className={"btns-bar dialog-footer"}>
            <button
              type={"button"}
              className={"btn-outlined"}
              onClick={() => setCancelModalOpen(false)}
            >
              {t("ui:CANCEL")}
            </button>
            <button type={"button"} className={"btn-1"} onClick={goBack}>
              {t("ui:OK")}
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ContributionDetails;
