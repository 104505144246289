import React, { createContext, useContext } from "react";
import { ToastConsumer, ToastProvider } from "react-toast-notifications";

interface ToastAPI {
  success(title: string, description?: string): void;
  error(title: string, description?: string): void;
  warning(title: string, description?: string): void;
  info(title: string, description?: string): void;
}

const ToastNotificationsContext = createContext<ToastAPI>({
  error(): void {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
  },
  info(): void {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
  },
  warning(): void {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
  },
  success() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
  },
});

const renderContent = (title: string, description?: string) => {
  return (
    <div>
      <div>{title}</div>
      {!!description && <div>{description}</div>}
    </div>
  );
};

export const ProvideToast: React.FC = ({ children }) => {
  return (
    <ToastProvider autoDismiss placement={"top-right"}>
      <ToastConsumer>
        {(toaster) => {
          const notifications: ToastAPI = {
            success(title: string, description?: string) {
              toaster.add(renderContent(title, description), {
                appearance: "success",
              });
            },
            error(title: string, description?: string) {
              toaster.add(renderContent(title, description), {
                appearance: "error",
              });
            },
            warning(title: string, description?: string) {
              toaster.add(renderContent(title, description), {
                appearance: "warning",
              });
            },
            info(title: string, description?: string) {
              toaster.add(renderContent(title, description), {
                appearance: "info",
              });
            },
          };

          return (
            <ToastNotificationsContext.Provider value={notifications}>
              {children}
            </ToastNotificationsContext.Provider>
          );
        }}
      </ToastConsumer>
    </ToastProvider>
  );
};

export const useToasts = (): ToastAPI => {
  return useContext(ToastNotificationsContext);
};
