import { FunctionComponent } from "react";
import { ContributionEvaluation } from "./contribution";
import Dialog from "../ui/elements/Dialog";
import { useDate } from "../date/DateContext";
import { useTranslation } from "react-i18next";

interface Props {
  onClose(): void;
  evaluations: ContributionEvaluation[];
}

const ContributionEvaluationCommentDialog: FunctionComponent<Props> = ({
  onClose,
  evaluations,
}) => {
  /* APIs */
  const { formatISO } = useDate();
  const { t } = useTranslation(["contributions", "ui"]);

  return (
    <Dialog onClose={onClose}>
      <div className="dialog-head">
        <h3 className="dialog-title">
          {evaluations.length > 1
            ? t("contributions:ALL_THE_COMMENTS")
            : t("contributions:CONTRIBUTOR_COMMENT")}
        </h3>
      </div>
      <div className="list">
        {evaluations.map((evaluation) => (
          <div className="list-item" key={evaluation.userId}>
            <div className="item-title">
              <span>{formatISO(evaluation.createdAt, "P")}</span> -{" "}
              <span className="status">{`${evaluation.User.firstname} ${evaluation.User.lastname}`}</span>
            </div>
            <div className="item-body">{evaluation.comment}</div>
          </div>
        ))}
      </div>
      <div className="dialog-footer">
        <button className="btn-1" type={"button"} onClick={onClose}>
          {t("ui:CLOSE")}
        </button>
      </div>
    </Dialog>
  );
};

export default ContributionEvaluationCommentDialog;
