import { FunctionComponent, useMemo } from "react";
import {
  Contribution,
  ContributionDocument,
  ContributionHistory,
  ContributionStatus,
  getCompanyScore,
  getCover,
  getCurrentStatus,
  getCustomerScore,
} from "./contribution";
import { getPseudo, User } from "../auth/user";
import { cx } from "@emotion/css";
import { format, parseISO } from "date-fns";
import { useDate } from "../date/DateContext";
import { useTranslation } from "react-i18next";
import Link from "../routing/components/Link";
import { CONTRIBUTION_DETAILS_LINK } from "../../routes/private";
import useFileAsObjectURL from "../files/useFileAsObjectURL";
import IconUser from "../icons/IconUser";
import IconCalendar from "../icons/IconCalendar";
import IconScore from "../icons/IconScore";

interface Props {
  contribution: Contribution & {
    Histories: ContributionHistory[];
    User: User;
    UserProblemDocs: ContributionDocument[];
    UserSolutionDocs: ContributionDocument[];
  };
}

const ContributionCard: FunctionComponent<Props> = ({ contribution }) => {
  const { t } = useTranslation(["contributions", "auth"]);
  const { locale } = useDate();

  const currentStatus = useMemo(
    () => getCurrentStatus(contribution) as ContributionStatus,
    [contribution],
  );
  const customerScore = useMemo(() => getCustomerScore(contribution), [
    contribution,
  ]);
  const companyScore = useMemo(() => getCompanyScore(contribution), [
    contribution,
  ]);
  const cover = useMemo(() => getCover(contribution), [contribution]);
  const coverObjectURL = useFileAsObjectURL({
    url: cover?.name || null,
    name: cover?.originalName,
  });
  const formattedDate = useMemo(
    () => format(parseISO(contribution.createdAt), "P", { locale }),
    [contribution, locale],
  );

  return (
    <Link
      to={CONTRIBUTION_DETAILS_LINK}
      params={{ contributionId: contribution.id }}
      className="ghost-link"
    >
      <div className="card card-contribution">
        <div className="card-head">
          <h3 className="card-title">
            {contribution.project.slice(0, 50)}
            {contribution.project.length > 50 && <span>...</span>}
          </h3>
        </div>
        <div className="card-body">
          <p className="status">
            {t(
              `contributions:status.${
                ContributionStatus[
                  currentStatus
                ] as keyof typeof ContributionStatus
              }`,
            )}
          </p>
          <div className="description">
            <p>{contribution.problem}</p>
          </div>
          <div className="row-s">
            <div className="col-fit date">
              <IconCalendar />
              <span>{formattedDate}</span>
            </div>
            <div className="col user">
              <IconUser />
              <span>{getPseudo(contribution.User, t("auth:OWN_ACCOUNT"))}</span>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {contribution.User.internal && <span className="chip">KI</span>}
            </div>
          </div>
          <div className="scores">
            <div
              className={cx(["score score-customer", !customerScore && "nd"])}
            >
              <span>{customerScore || "-"}/15</span>
              <p className="label">{t("contributions:commission.FIRST")}</p>
            </div>
            <div className={cx(["score score-company", !companyScore && "nd"])}>
              <span>{companyScore || "-"}/15</span>
              <p className="label">{t("contributions:commission.SECOND")}</p>
            </div>
            <br />
            <div
              className={cx([
                "score score-big",
                customerScore === null && companyScore === null && "nd",
              ])}
            >
              <IconScore />
              <span>
                {(customerScore || 0) + (companyScore || 0) || "-"}/30
              </span>
              <p className="label">{t("contributions:TOTAL_SCORE")}</p>
            </div>
          </div>
        </div>
        <div
          className={cx(["card-footer-img", !coverObjectURL && "default-img"])}
          style={
            coverObjectURL
              ? { backgroundImage: `url(${coverObjectURL.url})` }
              : undefined
          }
        />
      </div>
    </Link>
  );
};

export default ContributionCard;
