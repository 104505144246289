import React, { FunctionComponent, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { number, object } from "yup";
import {
  ContributionStatus,
  ContributionToSend,
  getCompanyScore,
  getCurrentStatus,
  getCustomerScore,
  getTotalScoreSum,
  isClosed,
} from "./contribution";
import { cx } from "@emotion/css";
import FSelectParse from "../ui/elements/FSelectParse";
import ValidationsErrors from "../validations/ValidationsErrors";
import { useTranslation } from "react-i18next";
import ContributionStatusDialog from "./ContributionStatusDialog";
import ContributionHistoryDialog from "./ContributionHistoryDialog";
import IconScore from "../icons/IconScore";

interface Props {
  contribution: ContributionToSend;
  onSubmit: (values: ContributionToSend) => void;
  onCancel?: (isDirty: boolean) => void;
  onDirtyChange?: (isDirty: boolean) => void;
}

const ContributionAdminForm: FunctionComponent<Props> = ({
  contribution,
  onSubmit,
  onDirtyChange,
  onCancel,
}) => {
  /* APIs */
  const { t } = useTranslation(["contributions", "ui"]);

  const AdminSchema = useMemo(
    () =>
      object({
        customerScore1: number()
          .label(t("contributions:form.IMPORTANCE"))
          .nullable()
          .defined(),
        customerScore2: number()
          .label(t("contributions:form.TANGIBLE"))
          .nullable()
          .defined(),
        customerScore3: number()
          .label(t("contributions:form.SATISFACTION"))
          .nullable()
          .defined(),
        companyScore1: number()
          .label(t("contributions:form.LUCRATIVE"))
          .nullable()
          .defined(),
        companyScore2: number()
          .label(t("contributions:form.PROFITABLE"))
          .nullable()
          .defined(),
        companyScore3: number()
          .label(t("contributions:form.ACHIEVABLE"))
          .nullable()
          .defined(),
      }).defined(),
    [t],
  );

  /* Hooks */
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

  /* Getters */
  const currentStatus = getCurrentStatus(contribution);
  const canModify = !isClosed(contribution);

  return (
    <Formik
      validationSchema={AdminSchema}
      initialValues={contribution}
      enableReinitialize={true}
      validateOnChange={true}
      onSubmit={(values) => {
        onSubmit(values);
        setStatusDialogOpen(true);
      }}
    >
      {({ values, errors, dirty, setFieldValue, submitForm }) => {
        const customerScore = getCustomerScore(values);
        const companyScore = getCompanyScore(values);
        const totalScore = getTotalScoreSum(values);

        if (onDirtyChange) onDirtyChange(dirty);

        return (
          <Form>
            <div className="section section-sep-2">
              <h2 className="section-title">
                {t("contributions:status.STATUS")}
              </h2>
              <p className="status">
                {t(
                  `contributions:status.${
                    ContributionStatus[
                      currentStatus as ContributionStatus
                    ] as keyof typeof ContributionStatus
                  }`,
                )}
              </p>
              <button
                type="button"
                className="link link-small"
                onClick={() => setStatusDialogOpen(true)}
              >
                {t("contributions:status.SWITCH_STATUS")}
              </button>
              <br />
              <button
                type="button"
                className="link link-small"
                onClick={() => setHistoryDialogOpen(true)}
              >
                {t("contributions:SEE_THE_HISTORY")}
              </button>
            </div>

            <div className={"section section-sep-2"}>
              <fieldset disabled={!canModify}>
                <div className="input-block grid">
                  <label className="section-subtitle">
                    {t("contributions:CUSTOMER_FIT")}
                  </label>
                  <div className="col-s-1-2 col-md-1-4">
                    <label className="input-label">
                      {t("contributions:form.IMPORTANCE")}&nbsp;
                      <div className="tooltip">
                        <span className="tooltiptext">
                          {t("contributions:tooltip.IMPORTANCE")}
                        </span>
                      </div>
                    </label>
                    <FSelectParse
                      name="customerScore1"
                      className="select"
                      parse={(val) => (val === "" ? null : parseInt(val))}
                    >
                      <option value={""} />
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </FSelectParse>
                  </div>
                  <div className="col-s-1-2 col-md-1-4">
                    <label className="input-label">
                      {t("contributions:form.TANGIBLE")}&nbsp;
                      <div className="tooltip">
                        <span className="tooltiptext">
                          {t("contributions:tooltip.TANGIBLE")}
                        </span>
                      </div>
                    </label>
                    <FSelectParse
                      name="customerScore2"
                      className="select"
                      parse={(val) => (val === "" ? null : parseInt(val))}
                    >
                      <option value={""} />
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </FSelectParse>
                  </div>
                  <div className="col-s-1-2 col-md-1-4">
                    <label className="input-label">
                      {t("contributions:form.SATISFACTION")}&nbsp;
                      <div className="tooltip">
                        <span className="tooltiptext">
                          {t("contributions:tooltip.SATISFACTION")}
                        </span>
                      </div>
                    </label>
                    <FSelectParse
                      name="customerScore3"
                      className="select"
                      parse={(val) => (val === "" ? null : parseInt(val))}
                    >
                      <option value={""} />
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </FSelectParse>
                  </div>
                  <div className="col-s-1-2 col-md-1-4">
                    <label className="input-label">
                      <strong>{t("contributions:form.SUM")}</strong>
                    </label>
                    <p className="input computed-result">
                      {customerScore || "-"}
                    </p>
                  </div>
                </div>

                <div className="input-block grid">
                  <label className="section-subtitle">
                    {t("contributions:COMPANY_FIT")}
                  </label>
                  <div className="col-s-1-2 col-md-1-4">
                    <label className="input-label">
                      {t("contributions:form.LUCRATIVE")}&nbsp;
                      <div className="tooltip">
                        <span className="tooltiptext">
                          {t("contributions:tooltip.LUCRATIVE")}
                        </span>
                      </div>
                    </label>
                    <FSelectParse
                      name="companyScore1"
                      className="select"
                      parse={(val) => (val === "" ? null : parseInt(val))}
                    >
                      <option value={""} />
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </FSelectParse>
                  </div>
                  <div className="col-s-1-2 col-md-1-4">
                    <label className="input-label">
                      {t("contributions:form.PROFITABLE")}&nbsp;
                      <div className="tooltip">
                        <span className="tooltiptext">
                          {t("contributions:tooltip.PROFITABLE")}
                        </span>
                      </div>
                    </label>
                    <FSelectParse
                      name="companyScore2"
                      className="select"
                      parse={(val) => (val === "" ? null : parseInt(val))}
                    >
                      <option value={""} />
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </FSelectParse>
                  </div>
                  <div className="col-s-1-2 col-md-1-4">
                    <label className="input-label">
                      {t("contributions:form.ACHIEVABLE")}&nbsp;
                      <div className="tooltip">
                        <span className="tooltiptext">
                          {t("contributions:tooltip.ACHIEVABLE")}
                        </span>
                      </div>
                    </label>
                    <FSelectParse
                      name="companyScore3"
                      className="select"
                      parse={(val) => (val === "" ? null : parseInt(val))}
                    >
                      <option value={""} />
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </FSelectParse>
                  </div>
                  <div className="col-s-1-2 col-md-1-4">
                    <label className="input-label">
                      <strong>{t("contributions:form.SUM")}</strong>
                    </label>
                    <p className="input computed-result">
                      {companyScore || "-"}
                    </p>
                  </div>
                </div>

                <div className="input-block">
                  <label className="input-label">
                    {t("contributions:TOTAL_SCORE")}
                  </label>
                  <div className={cx(["score score-big", !totalScore && "nd"])}>
                    <IconScore />
                    <span>{totalScore || "ND"}</span>
                  </div>
                </div>
              </fieldset>
            </div>

            {/* Handling errors */}
            <div>
              <ValidationsErrors errors={errors} />
            </div>

            {canModify && (
              <div className="btns-bar">
                {onCancel && (
                  <button
                    onClick={() => onCancel(dirty)}
                    type={"button"}
                    className="btn-outlined"
                  >
                    {t("ui:CANCEL")}
                  </button>
                )}
                <button type="submit" className="btn-1">
                  {t("ui:SAVE")}
                </button>
              </div>
            )}

            {statusDialogOpen && (
              <ContributionStatusDialog
                onClose={() => setStatusDialogOpen(false)}
                currentStatus={currentStatus}
                onSubmit={(statusValues) => {
                  setFieldValue("status", statusValues);
                  submitForm().then(() => {
                    setStatusDialogOpen(false);
                  });
                }}
              />
            )}

            {historyDialogOpen && (
              <ContributionHistoryDialog
                onClose={() => setHistoryDialogOpen(false)}
                histories={contribution.Histories}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContributionAdminForm;
