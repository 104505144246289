import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import pictoClose from "../../assets/img/picto-close.svg";

const IconClose: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img src={pictoClose} alt={t("icons:CLOSE_ALT")} />;
};

export default IconClose;
