import { useLayoutEffect } from "react";

export default function useLockBodyScroll(): void {
  useLayoutEffect(() => {
    // eslint-disable-next-line i18next/no-literal-string
    document.body.classList.add("modal-open");

    // eslint-disable-next-line i18next/no-literal-string
    return () => document.body.classList.remove("modal-open");
  }, []);
}
