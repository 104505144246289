import React, { FunctionComponent } from "react";
import { ContributionHistory, ContributionStatus } from "./contribution";
import Dialog from "../ui/elements/Dialog";
import { useDate } from "../date/DateContext";
import { useTranslation } from "react-i18next";

interface Props {
  onClose(): void;
  histories: ContributionHistory[];
}

const ContributionHistoryDialog: FunctionComponent<Props> = ({
  onClose,
  histories,
}) => {
  /* APIs */
  const { formatISO } = useDate();
  const { t } = useTranslation(["contributions", "ui"]);

  return (
    <Dialog onClose={onClose}>
      <div className="dialog-head">
        <h3 className="dialog-title">{t("contributions:HISTORY")}</h3>
      </div>
      <div className="list">
        {histories.map((history) => (
          <div className="list-item" key={history.id}>
            <div className="item-title">
              <span>{formatISO(history.createdAt, "P")}</span> -{" "}
              <span className="status">
                {t(
                  `contributions:status.${
                    ContributionStatus[
                      history.status
                    ] as keyof typeof ContributionStatus
                  }`,
                )}
              </span>
            </div>
            <div className="item-body">{history.comment}</div>
          </div>
        ))}
      </div>
      <div className="dialog-footer">
        <button className="btn-1" type={"button"} onClick={onClose}>
          {t("ui:CLOSE")}
        </button>
      </div>
    </Dialog>
  );
};

export default ContributionHistoryDialog;
