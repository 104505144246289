import { boolean, object, SchemaOf, string } from "yup";
import { useTranslation } from "react-i18next";
import { needsTypologyInput, UserCompleteProfileForm } from "./user";
import React, { useMemo, useState } from "react";
import { Field, Form, Formik } from "formik";
import ValidationsErrors from "../validations/ValidationsErrors";
import { SUBMIT_BUTTON_ID } from "../../fixtures/auth";
import TermsOfUseDialog from "./TermsOfUseDialog";

const CompleteProfileForm = ({
  user,
  onSubmit,
}: {
  user: {
    email: string;
    firstname: string;
    lastname: string;
  };
  onSubmit: (values: UserCompleteProfileForm) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation(["auth", "ui"]);

  const CompleteProfileSchema: SchemaOf<UserCompleteProfileForm> = useMemo(
    () =>
      object()
        .shape({
          email: string().label(t("auth:EMAIL")).email(),
          lastname: string().label(t("auth:LASTNAME")).required(),
          firstname: string().label(t("auth:FIRSTNAME")).required(),
          tel: string().nullable().label(t("auth:TEL")),
          otherType: string().when("type", {
            is: needsTypologyInput,
            then: string().label(t("auth:form.SPECIFY")).nullable().required(),
            otherwise: string()
              .nullable()
              .transform(() => null),
          }),
          termsOfUse: boolean()
            .label(t("auth:TERMS_OF_USE"))
            .required()
            .oneOf([true]),
          securityPolicy: boolean()
            .label(t("auth:SECURITY_POLICY"))
            .required()
            .oneOf([true]),
        })
        .defined(),
    [t],
  );

  const [termsOfUseDialogOpen, setTermsOfUseDialogOpen] = useState(false);

  return (
    <Formik
      validationSchema={CompleteProfileSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={
        {
          type: null,
          otherType: null,
          tel: null,
          termsOfUse: false,
          securityPolicy: false,
          ...user,
        } as UserCompleteProfileForm
      }
      onSubmit={(values, { setSubmitting }) =>
        onSubmit(values).finally(() => setSubmitting(false))
      }
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className={"auth-form"} noValidate>
          <div className={"input-block"}>
            <label className={"input-label"}>{t("auth:EMAIL")}</label>
            <Field
              className={"input"}
              name={"email"}
              type={"email"}
              placeholder={t("auth:EMAIL_PLACEHOLDER")}
              disabled
            />
          </div>

          <div className="input-block grid">
            <div className="col">
              <label className="input-label">{t("auth:LASTNAME")}</label>
              <Field className="input" name="lastname" />
            </div>

            <div className="col">
              <label className="input-label">{t("auth:FIRSTNAME")}</label>
              <Field className="input" name="firstname" />
            </div>
          </div>

          <div className={"input-block"}>
            <label className={"input-label"}>{t("auth:TEL")}</label>
            <Field className={"input"} name={"tel"} />
          </div>

          {/* <div className={"input-block grid"}>
            <div
              className={cx([
                "col",
                "col-1-1",
                values.type !== null &&
                  needsTypologyInput(values.type) &&
                  "col-s-1-3",
              ])}
            >
              <label className={"input-label"}>
                {t("auth:form.WHO_ARE_YOU")} *
              </label>
              <FSelectParse
                className={"input select"}
                name={"type"}
                parse={(v) => (v.length !== 0 ? parseInt(v, 10) : null)}
                id={"type-select"}
              >
                <option value="">--</option>
                {USER_TYPOLOGY_ENTRIES.map(([id, name]) => (
                  <option value={id} key={id}>
                    {t(`auth:userTypology.${name}`)}
                  </option>
                ))}
              </FSelectParse>
            </div>

            {values.type !== null && needsTypologyInput(values.type) && (
              <div className="col col-1-1 col-s-2-3">
                <label className="input-label" htmlFor={"othertype-select"}>
                  {t("auth:form.SPECIFY")} *
                </label>
                <Field type="text" className="input" name={"otherType"} />
              </div>
            )}
          </div> */}

          <div className="input-block">
            <div>
              <input
                id="termsOfUse"
                checked={values.termsOfUse}
                type="checkbox"
                readOnly
                onClick={(event) => {
                  event.preventDefault();
                  setTermsOfUseDialogOpen(true);
                }}
              />
              <label htmlFor="termsOfUse" className="input-label label-inline">
                {t("auth:register.ACCEPT_TERMS_OF_USE")}
              </label>
            </div>

            <div>
              <Field name="securityPolicy" type="checkbox" />
              <a
                href="https://www.knaufinsulation.fr/politique-de-confidentialité"
                rel="noreferrer"
                className="input-label link"
                target="_blank"
              >
                {t("auth:register.ACCEPT_SECURITY_POLICY")}
              </a>
            </div>
          </div>

          <ValidationsErrors />

          <div className="btns-bar vertical form-footer">
            <button
              className="btn-1"
              type="submit"
              disabled={isSubmitting}
              value="Connexion"
              data-testid={SUBMIT_BUTTON_ID}
            >
              {isSubmitting ? `${t("ui:LOADING")}` : t("auth:LOGIN")}
            </button>
          </div>

          {termsOfUseDialogOpen && (
            <TermsOfUseDialog
              initialTermsOfUse={values.termsOfUse}
              onSubmit={(termsOfUse) => {
                setFieldValue("termsOfUse", termsOfUse);
                setTermsOfUseDialogOpen(false);
              }}
              onClose={() => setTermsOfUseDialogOpen(false)}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CompleteProfileForm;
