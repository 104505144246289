export const PUBLIC = "/public" as const;

export const LOGOUT = "/logout" as const;

export const REGISTER = "/register" as const;
export const REGISTER_LINK = `${PUBLIC}${REGISTER}` as const;

export const LOST_PASSWORD = "/lost-password" as const;
export const LOST_PASSWORD_LINK = `${PUBLIC}${LOST_PASSWORD}` as const;

export const PUBLIC_NEW_CONTRIBUTION = "/new-idea" as const;
export const PUBLIC_NEW_CONTRIBUTION_LINK = `${PUBLIC}${PUBLIC_NEW_CONTRIBUTION}` as const;

export const PUBLIC_TERMS_OF_USE = "/terms-of-use" as const;
export const PUBLIC_TERMS_OF_USE_LINK = `${PUBLIC}${PUBLIC_TERMS_OF_USE}` as const;

export const PUBLIC_COOKIE_POLICY = "/cookie-policy" as const;
export const PUBLIC_COOKIE_POLICY_LINK = `${PUBLIC}${PUBLIC_COOKIE_POLICY}` as const;

export const PUBLIC_CONTACT = "/contact" as const;
export const PUBLIC_CONTACT_LINK = `${PUBLIC}${PUBLIC_CONTACT}` as const;

export const PUBLIC_COMPLETE_PROFILE = "/profile" as const;
export const PUBLIC_COMPLETE_PROFILE_LINK = `${PUBLIC}${PUBLIC_COMPLETE_PROFILE}` as const;
