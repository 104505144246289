import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import logoKnauf from "../../assets/img/logo-white.png";

const IconLogo: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img src={logoKnauf} alt={t("icons:LOGO_ALT")} className="logo" />;
};

export default IconLogo;
