import React, { FunctionComponent, useMemo } from "react";
import Dialog from "../ui/elements/Dialog";
import { Form, Formik, Field } from "formik";
import { boolean, object } from "yup";
import { useTranslation } from "react-i18next";
import TermsOfUse from "src/views/TermsOfUse";
import ValidationsErrors from "../validations/ValidationsErrors";

interface Props {
  initialTermsOfUse: boolean;
  onSubmit: (termsOfUse: boolean) => void;
  onClose: () => void;
}

const TermsOfUseDialog: FunctionComponent<Props> = ({
  initialTermsOfUse,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation(["auth", "ui"]);

  const Schema = useMemo(
    () =>
      object({
        termsOfUse: boolean()
          .label(t("auth:TERMS_OF_USE"))
          .required()
          .oneOf([true]),
      }),
    [t],
  );

  return (
    <Dialog onClose={onClose} className="large">
      <Formik
        validationSchema={Schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          termsOfUse: initialTermsOfUse,
        }}
        onSubmit={(values) => onSubmit(values.termsOfUse)}
      >
        {({ errors }) => (
          <Form>
            <div className="popup-body">
              <TermsOfUse />
              <div className="input-block">
                <Field
                  id="termsOfUseInDialog"
                  name="termsOfUse"
                  type="checkbox"
                />
                <label
                  htmlFor="termsOfUseInDialog"
                  className="input-label label-inline"
                >
                  {t("auth:register.READ_AND_ACCEPT_TERMS_OF_USE")}
                </label>
              </div>

              <div>
                <ValidationsErrors errors={errors} />
              </div>

              <div className="form-footer">
                <button type="submit" className="btn-1">
                  {t("ui:OK")}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default TermsOfUseDialog;
