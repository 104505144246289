import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import iconFile from "../../assets/img/picto-file.png";

const IconFile: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img className={"icon"} src={iconFile} alt={t("icons:FILE_ALT")} />;
};

export default IconFile;
