export const PRIVATE = "/private" as const;

export const PROFILE = "/profile" as const;
export const PROFILE_LINK = `${PRIVATE}${PROFILE}` as const;

export const CONTRIBUTIONS = "/ideas" as const;
export const CONTRIBUTIONS_LINK = `${PRIVATE}${CONTRIBUTIONS}` as const;

export const CONTRIBUTION_DETAILS = "/ideas/:contributionId" as const;
export const CONTRIBUTION_DETAILS_LINK = `${PRIVATE}${CONTRIBUTION_DETAILS}` as const;

export const NEW_CONTRIBUTION = "/ideas/new" as const;
export const NEW_CONTRIBUTION_LINK = `${PRIVATE}${NEW_CONTRIBUTION}` as const;

export const PRIVATE_TERMS_OF_USE = "/terms-of-use" as const;
export const PRIVATE_TERMS_OF_USE_LINK = `${PRIVATE}${PRIVATE_TERMS_OF_USE}` as const;

export const PRIVATE_COOKIE_POLICY = "/cookie-policy" as const;
export const PRIVATE_COOKIE_POLICY_LINK = `${PRIVATE}${PRIVATE_COOKIE_POLICY}` as const;

export const PRIVATE_CONTACT = "/contact" as const;
export const PRIVATE_CONTACT_LINK = `${PRIVATE}${PRIVATE_CONTACT}` as const;
