import { createContext, useContext, useState } from "react";
import { Committee } from "./committee";
import { deleteCommittee, getAllCommittees } from "./api";
import { copyMapAndDelete } from "../data-structures/map";

export interface CommitteesAPI {
  committees: Map<Committee["id"], Committee>;
  loadAllCommittees(): Promise<Committee[]>;
  deleteCommittee(committeeId: Committee["id"]): Promise<void>;
}

export function useProvideCommittees(
  defaultCommittees: Map<Committee["id"], Committee> = new Map(),
): CommitteesAPI {
  const [committees, setCommittees] = useState(defaultCommittees);

  return {
    committees,
    loadAllCommittees() {
      return getAllCommittees().then((committees) => {
        setCommittees(new Map(committees.data.map((c) => [c.id, c])));
        return committees.data;
      });
    },
    deleteCommittee(committeeId) {
      return deleteCommittee(committeeId).then(() => {
        setCommittees((prevCommittee) =>
          copyMapAndDelete(prevCommittee, committeeId),
        );
      });
    },
  };
}

export const CommitteesContext = createContext<CommitteesAPI>({
  committees: new Map(),
  loadAllCommittees() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  deleteCommittee(committeeId): Promise<void> {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
});

export function useCommittees(): CommitteesAPI {
  return useContext(CommitteesContext);
}

export interface WithProvideCommittees {
  committeesAPI: CommitteesAPI;
}
