import SimpleInputFile from "../files/SimpleInputFile";
import ContributionDocument from "./ContributionDocument";
import React, { FunctionComponent, useRef } from "react";
import { ContributionDocumentBase } from "./contribution";
import { useToasts } from "../toast-notifications";
import { useTranslation } from "react-i18next";

interface Props {
  docs: Array<ContributionDocumentBase>;
  isDisabled?: boolean;
  onChange: (docs: Array<ContributionDocumentBase>) => void;
  onCoverChange?: (checked: boolean) => void;
  label?: string;
}

// eslint-disable-next-line i18next/no-literal-string
const authorizedDocumentExt = ["jpg", "jpeg", "png", "pdf", "avi", "mp4"];

const ContributionFormFileUpload: FunctionComponent<Props> = ({
  docs,
  isDisabled,
  label,
  onChange,
  onCoverChange,
}) => {
  /* Hooks */
  const { warning } = useToasts();
  const lastFileRef = useRef<File | null>(null);
  const { t } = useTranslation(["contributions"]);

  /* Methods */
  const push = (d: ContributionDocumentBase) => {
    const newDocs = [...docs];
    newDocs.push(d);
    onChange(newDocs);
  };

  const remove = (index: number) => {
    const newDocs = [...docs];
    newDocs.splice(index, 1);
    onChange(newDocs);
  };

  const setDocumentField = <FIELD extends keyof ContributionDocumentBase>(
    index: number,
    field: FIELD,
    value: ContributionDocumentBase[FIELD],
  ) => {
    const newDocs = [...docs];
    newDocs[index][field] = value;
    onChange(newDocs);
  };

  const reader = new FileReader();
  reader.onloadend = function () {
    const file = lastFileRef.current as File;
    push({
      name: reader.result as string,
      originalName: file.name,
      origin: 1,
      cover: false,
    });
  };

  return (
    <div className="input-block">
      {(!isDisabled || docs.length > 0) && (
        <label className="input-label">
          {label || t("contributions:form.UPLOADED_FILES")}
          <br />
          {!isDisabled && (
            <small title={t("contributions:form.FILES_TYPES")}>
              {t("contributions:form.FILES_TYPES")}
            </small>
          )}
        </label>
      )}
      {!isDisabled && (
        <p className="input-tip">{t("contributions:form.ACCEPTED_FILES")}</p>
      )}
      <div>
        {!isDisabled && (
          <SimpleInputFile
            className={"input"}
            accept={authorizedDocumentExt.map((e) => `.${e}`).join(",")}
            onChange={(files) => {
              if (files && files.length > 0) {
                const file = files[0];
                lastFileRef.current = file;
                const fileSize = file.size / 1024 / 1024;

                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const ext = name.substring(lastDot + 1);

                if (authorizedDocumentExt.indexOf(ext) < 0) {
                  warning(t("contributions:file.UNAUTHORIZED_FILE_TYPE"));
                  return;
                }
                if ((ext === "avi" || ext === "mp4") && fileSize > 50) {
                  // limit video 50mo
                  warning(t("contributions:file.VIDEO_IS_TOO_BIG"));
                } else if (fileSize > 3) {
                  // other attachment max 3mo
                  warning(t("contributions:file.FILE_IS_TOO_BIG"));
                } else {
                  reader.readAsDataURL(file);
                }
              }
            }}
          />
        )}
        <div>
          {docs.map((doc, index) => (
            <ContributionDocument
              document={doc}
              key={doc.id || `${doc.originalName}-${index}`}
              canEdit={!isDisabled}
              onRemove={() => remove(index)}
              onDescriptionChange={(event) =>
                setDocumentField(index, "description", event.target.value)
              }
              canCoverChange
              onCoverChange={(event) => {
                if (event.target.checked) {
                  const newDocs = docs.map((doc) => ({ ...doc, cover: false }));
                  newDocs[index].cover = true;
                  onChange(newDocs);
                } else {
                  setDocumentField(index, "cover", event.target.checked);
                }
                if (onCoverChange) onCoverChange(event.target.checked);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContributionFormFileUpload;
