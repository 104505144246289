import { Contribution } from "../contributions/contribution";
import { FunctionComponent } from "react";
import Dialog from "../ui/elements/Dialog";
import CommitteeNotationPlot, {
  COLOR_CONSUMER_AND_COMPANY_ARE_AVERAGES,
  COLOR_CONSUMER_AND_COMPANY_ARE_FINALS,
  COLOR_CONSUMER_OR_COMPANY_IS_AVERAGE,
} from "./CommitteeNotationPlot";
import { cx } from "@emotion/css";
import { flexCenter, flexCrossCenter } from "../ui/layouts/flex";
import { useTranslation } from "react-i18next";

interface Props {
  contributions: Contribution[];
  onClose?(): void;
  onContributionClick(contributionId: Contribution["id"]): void;
}

const CommitteeNotationPlotDialog: FunctionComponent<Props> = ({
  contributions,
  onClose,
  onContributionClick,
}) => {
  const { t } = useTranslation(["committees"]);

  return (
    <Dialog className={"large"} onClose={onClose}>
      <CommitteeNotationPlot
        contributions={contributions}
        onContributionClick={onContributionClick}
      />
      <div className={"grid"}>
        <div className={"row"}>
          <div className={cx(["col-md-1-3", flexCenter, flexCrossCenter])}>
            <div
              className={"chip"}
              style={{
                backgroundColor: COLOR_CONSUMER_AND_COMPANY_ARE_AVERAGES,
              }}
            />
            <span className={"chip-label"}>
              {t("committees:notationPlot.NOTES_ARE_AVERAGES")}
            </span>
          </div>
          <div className={cx(["col-md-1-3", flexCrossCenter])}>
            <div
              className={"chip"}
              style={{ backgroundColor: COLOR_CONSUMER_OR_COMPANY_IS_AVERAGE }}
            />
            <span className={"chip-label"}>
              {t("committees:notationPlot.CUSTOMER_FIT_IS_FINAL")}
            </span>
          </div>
          <div className={cx(["col-md-1-3", flexCrossCenter])}>
            <div
              className={"chip"}
              style={{ backgroundColor: COLOR_CONSUMER_AND_COMPANY_ARE_FINALS }}
            />
            <span className={"chip-label"}>
              {t("committees:notationPlot.NOTES_ARE_NOT_FINALS")}
            </span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CommitteeNotationPlotDialog;
