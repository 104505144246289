import baseAPI from "../auth/baseAPI";
import { AxiosPromise } from "axios";
import { User, UserCommittee, UserRole } from "../auth/user";

export function getAllUsers(): AxiosPromise<User[]> {
  return baseAPI.get("/users");
}

export function updateUserRole(
  userId: User["userId"],
  role: UserRole,
): AxiosPromise<void> {
  return baseAPI.post(`/users/${userId}/update-role`, {
    admin: role,
  });
}

export function updateUserCommittee(
  userId: User["userId"],
  committee: UserCommittee,
): AxiosPromise<void> {
  return baseAPI.post(`/users/${userId}/update-committee`, {
    committee,
  });
}
