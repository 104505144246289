import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import pictoEdit from "../../assets/img/picto-edit.svg";

const IconEdit: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img src={pictoEdit} alt={t("icons:EDIT_ALT")} />;
};

export default IconEdit;
