import React, { FunctionComponent, useMemo, useState } from "react";
import { RouteComponentProps, Router } from "@reach/router";
import { PRIVATE } from "../../../../routes/private";
import Loading from "../../../../services/routing/components/Loading";
import LoaderErrors from "../../../../services/routing/components/LoaderErrors";
import useLoader from "../../../../services/routing/useLoader";
import { useProvideCommittees } from "../../../../services/committees/useProvideCommittees";
import {
  Committee,
  COMMITTEE_ALL_STATUSES,
  COMMITTEE_ALL_TYPES,
  COMMITTEE_STATUS_ENTRIES,
  COMMITTEE_STATUS_MAP,
  COMMITTEE_TYPES,
  getCommitteeStatus,
} from "../../../../services/committees/committee";
import { useTranslation } from "react-i18next";
import { useDate } from "../../../../services/date/DateContext";
import {
  COMMITTEE_DETAILS_EVALUATIONS_LINK,
  COMMITTEE_NEW,
  COMMITTEE_NEW_LINK,
  COMMITTEE_PATH,
} from "../../../../routes/committees";
import { compareAsc, compareDesc, parseISO } from "date-fns";
import Dialog from "../../../../services/ui/elements/Dialog";
import { useToasts } from "../../../../services/toast-notifications";
import Link from "../../../../services/routing/components/Link";
import { searchGenerator } from "../../../../services/filter/search";
import CommitteeNew from "./CommitteeNew";
import CommitteeComponent from "./Committee";
import IconTrash from "../../../../services/icons/IconTrash";
import IconEdit from "../../../../services/icons/IconEdit";

const CommitteePanel: FunctionComponent<RouteComponentProps> = () => {
  /* Loading */
  const {
    loadAllCommittees,
    committees,
    deleteCommittee,
  } = useProvideCommittees();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { loading, error: loadingError, reload } = useLoader(
    loadAllCommittees,
    [],
  );

  /* APIs */
  const { t } = useTranslation(["committees", "ui"]);
  const { formatISO } = useDate();
  const { success, error } = useToasts();

  /* Hooks */
  const [filteredType, setFilteredType] = useState(COMMITTEE_ALL_TYPES);
  const [filteredStatus, setFilteredStatus] = useState(COMMITTEE_ALL_STATUSES);
  const [searchedText, setSearchedText] = useState("");
  const [orderAsc, setOrderAsc] = useState(false);
  const [committeeToDelete, setCommitteeToDelete] = useState<
    Committee["id"] | null
  >(null);

  const filteredCommittees = useMemo(() => {
    let filteredCommittees = [...committees.values()];

    if (filteredType !== COMMITTEE_ALL_TYPES) {
      filteredCommittees = filteredCommittees.filter(
        (committee) => committee.type === filteredType,
      );
    }

    if (filteredStatus !== COMMITTEE_ALL_STATUSES) {
      filteredCommittees = filteredCommittees.filter(
        (committee) => getCommitteeStatus(committee) === filteredStatus,
      );
    }

    filteredCommittees = filteredCommittees.filter(
      searchGenerator(searchedText, (committee) => committee.name),
    );

    filteredCommittees.sort(
      orderAsc
        ? (a, b) => compareAsc(parseISO(a.date), parseISO(b.date))
        : (a, b) => compareDesc(parseISO(a.date), parseISO(b.date)),
    );

    return filteredCommittees;
  }, [committees, orderAsc, searchedText, filteredType, filteredStatus]);

  /* Methods */
  const deleteCommitteeById = (committeeId: Committee["id"]) => {
    return deleteCommittee(committeeId).then(
      () => {
        success(t("committees:DELETE_COMMITTEE.SUCCESS"));
        setCommitteeToDelete(null);
      },
      (err) => {
        if (err.response.status === 409) {
          error(t("committees:DELETE_COMMITTEE.ERROR_NOT_EMPTY"));
        } else {
          error(t("committees:DELETE_COMMITTEE.ERROR"));
        }
      },
    );
  };

  /* End loading */
  if (loading) return <Loading />;
  if (loadingError)
    return <LoaderErrors reload={reload} error={loadingError} />;

  return (
    <>
      <div className="page-head">
        <h1 className="page-title">{t("committees:COMMITTEES")}</h1>
        <Link className="link link-small" to={PRIVATE}>
          &lt; {t("ui:GO_BACK")}
        </Link>
      </div>
      <div className="page-content">
        <Link to={COMMITTEE_NEW_LINK} className="btn btn-1">
          {t("committees:NEW_COMMITTEE")}
        </Link>

        <div className="grid input-block">
          <div className="col-md-1-2">
            <input
              type="text"
              className="input input-search"
              placeholder={t("committees:SEARCH_COMMITTEE")}
              onChange={(ev) => setSearchedText(ev.target.value)}
            />
          </div>
        </div>

        <div className="grid input-block">
          <div className="col-md-1-2">
            <label className="input-label">{t("ui:SORT_BY")}</label>
            <select
              className="select"
              onChange={(ev) => {
                setOrderAsc(ev.target.value[0] !== "-");
              }}
              defaultValue={`${orderAsc ? "+" : "-"}createdAt`}
            >
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <option value="+createdAt">{t("ui:ASCENDANT_DATE")}</option>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <option value="-createdAt">{t("ui:DESCENDANT_DATE")}</option>
            </select>
          </div>

          <div className="col-md-1-2">
            <label className="input-label">
              {t("committees:FILTER_BY_TYPE")}
            </label>
            <select
              className="select"
              onChange={(ev) => setFilteredType(parseInt(ev.target.value, 10))}
              defaultValue={COMMITTEE_ALL_TYPES}
            >
              <option value={COMMITTEE_ALL_TYPES}>
                {t("committees:type.ALL")}
              </option>
              {COMMITTEE_TYPES.map((committeeType) => (
                <option value={committeeType} key={committeeType}>
                  {committeeType}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-1-2">
            <label className="input-label">
              {t("committees:FILTER_BY_STATUS")}
            </label>
            <select
              className="select"
              onChange={(ev) =>
                setFilteredStatus(parseInt(ev.target.value, 10))
              }
              defaultValue={COMMITTEE_ALL_STATUSES}
            >
              <option value={COMMITTEE_ALL_STATUSES}>
                {t("committees:status.ALL")}
              </option>
              {COMMITTEE_STATUS_ENTRIES.map(([id, name]) => (
                <option value={id} key={id}>
                  {t(`committees:status.${name}`)}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid input-block">
          <table className="table-1">
            <thead>
              <tr>
                <th>{t("committees:NAME")}</th>
                <th>{t("committees:DATE")}</th>
                <th>{t("committees:TYPE")}</th>
                <th>{t("committees:STATUS")}</th>
                <th>{t("committees:ACTIONS")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredCommittees.map((committee) => (
                <tr key={committee.id}>
                  <td>{committee.name}</td>
                  <td>{formatISO(committee.date)}</td>
                  <td>{committee.type}</td>
                  <td>
                    {t(
                      `committees:status.${
                        COMMITTEE_STATUS_MAP[getCommitteeStatus(committee)]
                      }`,
                    )}
                  </td>
                  <td className="cell-actions">
                    <Link
                      className="link"
                      to={COMMITTEE_DETAILS_EVALUATIONS_LINK}
                      params={{ committeeId: committee.id }}
                    >
                      <IconEdit />
                    </Link>
                    <button
                      className="link"
                      type="button"
                      onClick={() => setCommitteeToDelete(committee.id)}
                    >
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {committeeToDelete && (
        <Dialog onClose={() => setCommitteeToDelete(null)}>
          {t("committees:DELETE_COMMITTEE.CONFIRMATION")}
          <div className="btns-bar dialog-footer">
            <button
              type={"button"}
              className={"btn-outlined"}
              onClick={() => setCommitteeToDelete(null)}
            >
              {t("ui:CANCEL")}
            </button>
            <button
              type={"button"}
              className={"btn-1"}
              onClick={() => deleteCommitteeById(committeeToDelete)}
            >
              {t("ui:CONFIRM")}
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
};

const CommitteesRouter: FunctionComponent<RouteComponentProps> = () => {
  return (
    <Router>
      <CommitteePanel path={"/"} />
      <CommitteeNew path={COMMITTEE_NEW} />
      <CommitteeComponent path={COMMITTEE_PATH} />
    </Router>
  );
};

export default CommitteesRouter;
