import baseAPI from "../auth/baseAPI";
import { AxiosPromise } from "axios";
import {
  Contribution,
  ContributionDocument,
  ContributionEvaluation,
  ContributionEvaluationToSend,
  ContributionHistory,
  ContributionToSend,
} from "./contribution";
import { User } from "../auth/user";
import { Committee } from "../committees/committee";

export interface CommitteeContribution extends Contribution {
  Histories: ContributionHistory[];
  User: User;
}

export interface AllContributionsResponse extends Contribution {
  Histories: ContributionHistory[];
  User: User;
  Committees?: Committee[];
  UserProblemDocs: ContributionDocument[];
  UserSolutionDocs: ContributionDocument[];
  AdminProblemDocs: ContributionDocument[];
  AdminSolutionDocs: ContributionDocument[];
}

export function getAllContributions(): AxiosPromise<
  AllContributionsResponse[]
> {
  return baseAPI.get("/contributions");
}

export interface GetContributionResponse extends Contribution {
  Histories: ContributionHistory[];
  User: User;
  UserProblemDocs: ContributionDocument[];
  UserSolutionDocs: ContributionDocument[];
  AdminProblemDocs: ContributionDocument[];
  AdminSolutionDocs: ContributionDocument[];
}

export function getContribution(
  id: Contribution["id"],
): AxiosPromise<GetContributionResponse> {
  return baseAPI.get(`/contributions/${id}`);
}

export function createContribution(
  contribution: ContributionToSend,
): AxiosPromise<GetContributionResponse> {
  return baseAPI.post("/contributions", contribution);
}

export function updateContribution(
  contribution: ContributionToSend,
): AxiosPromise<GetContributionResponse> {
  return baseAPI.put(`/contributions/${contribution.id}`, contribution);
}

export function deleteContribution(id: Contribution["id"]): AxiosPromise<void> {
  return baseAPI.delete(`/contributions/${id}`);
}

export function evaluateContribution(
  contributionId: Contribution["id"],
  evaluation: ContributionEvaluationToSend,
): AxiosPromise<ContributionEvaluation> {
  return baseAPI.post(
    `/contributions/${contributionId}/evaluations`,
    evaluation,
  );
}

export function addContributionsToCommittee(
  committeeId: Committee["id"],
  contributionsIds: Contribution["id"][],
): AxiosPromise<void> {
  return baseAPI.post(
    `/committees/${committeeId}/contributions`,
    contributionsIds,
  );
}

export function bulkRefuseContributions(
  contributionsIds: Contribution["id"][],
): AxiosPromise<ContributionHistory[]> {
  return baseAPI.post(`/contributions/status/refuse`, {
    contributionsIds,
  });
}
