import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import pictoCheck from "../../assets/img/picto-check.svg";

const IconChart: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img className="picto" alt={t("icons:CHECK_ALT")} src={pictoCheck} />;
};

export default IconChart;
