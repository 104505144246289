import { FunctionComponent } from "react";
import pictoBurger from "../../assets/img/picto-burger.svg";
import { useTranslation } from "react-i18next";

const IconMenu: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img src={pictoBurger} alt={t("icons:MENU_ALT")} />;
};

export default IconMenu;
