import { FunctionComponent, useState } from "react";
import {
  ContributionEvaluation,
  getCompanyScoreTotal,
  getContributionAverageScores,
  getCustomerScoreTotal,
  getTotalScoreSum,
} from "./contribution";
import { GetContributionResponse } from "./api";
import ContributionEvaluationCommentDialog from "./ContributionEvaluationCommentDialog";
import IconFile from "../icons/IconFile";
import { useTranslation } from "react-i18next";

interface Props {
  contribution: GetContributionResponse;
}

const DIGITS_COUNT = 2;

const ContributionsDetailsTable: FunctionComponent<Props> = ({
  contribution,
}) => {
  const { t } = useTranslation(["contributions"]);

  const averageScores = getContributionAverageScores(contribution);
  const [currentEval, setCurrentEval] = useState<
    ContributionEvaluation[] | null
  >();

  return (
    <div>
      <h2 className="section-title">
        {t("contributions:page.evaluation.TABLE_TITLE")}
      </h2>
      <table className="table-1">
        <thead>
          <tr>
            <th>{t("contributions:CONTRIBUTOR")}</th>
            <th className={"th-1"}>{t("contributions:form.IMPORTANCE")}</th>
            <th className={"th-1"}>{t("contributions:form.TANGIBLE")}</th>
            <th className={"th-1"}>{t("contributions:form.SATISFACTION")}</th>
            <th className={"th-1"}>{t("contributions:TOTAL_CUSTOMER_FIT")}</th>
            <th className={"th-2"}>{t("contributions:form.LUCRATIVE")}</th>
            <th className={"th-2"}>{t("contributions:form.PROFITABLE")}</th>
            <th className={"th-2"}>{t("contributions:form.ACHIEVABLE")}</th>
            <th className={"th-2"}>{t("contributions:TOTAL_COMPANY_FIT")}</th>
            <th className={"th-3"}>{t("contributions:TOTAL_GLOBAL_FIT")}</th>
            <th className={"th-3"} />
          </tr>
        </thead>
        <tbody>
          <tr className={"bold"}>
            <td>{t("contributions:AVERAGE_ABBREV")}</td>
            <td>
              {averageScores.customerScore1
                ? averageScores.customerScore1.toFixed(DIGITS_COUNT)
                : "-"}
            </td>
            <td>
              {averageScores.customerScore2
                ? averageScores.customerScore2.toFixed(DIGITS_COUNT)
                : "-"}
            </td>
            <td>
              {averageScores.customerScore3
                ? averageScores.customerScore3.toFixed(DIGITS_COUNT)
                : "-"}
            </td>
            <td className={"td-1 sep-l sep-r"}>
              {getCustomerScoreTotal(averageScores)?.toFixed(DIGITS_COUNT) ||
                "-"}
            </td>
            <td>
              {averageScores.companyScore1
                ? averageScores.companyScore1.toFixed(DIGITS_COUNT)
                : "-"}
            </td>
            <td>
              {averageScores.companyScore2
                ? averageScores.companyScore2.toFixed(DIGITS_COUNT)
                : "-"}
            </td>
            <td>
              {averageScores.companyScore3
                ? averageScores.companyScore3.toFixed(DIGITS_COUNT)
                : "-"}
            </td>
            <td className={"td-2 sep-l sep-r"}>
              {getCompanyScoreTotal(averageScores)?.toFixed(DIGITS_COUNT) ||
                "-"}
            </td>
            <td>
              {getTotalScoreSum(averageScores)?.toFixed(DIGITS_COUNT) || "-"}
            </td>
            <td>
              {contribution.Evaluations && contribution.Evaluations.length > 0 && (
                <button
                  className="btn-icon btn-icon-m"
                  onClick={() => {
                    setCurrentEval(contribution.Evaluations);
                  }}
                  type={"button"}
                >
                  <IconFile />
                </button>
              )}
            </td>
          </tr>
          {contribution.Evaluations.map((evaluation) => {
            const totalCustomerScore =
              evaluation.customerScore1 ||
              evaluation.customerScore2 ||
              evaluation.customerScore3
                ? (evaluation.customerScore1 || 0) +
                  (evaluation.customerScore2 || 0) +
                  (evaluation.customerScore3 || 0)
                : null;
            const totalCompanyScore =
              evaluation.companyScore1 ||
              evaluation.companyScore2 ||
              evaluation.companyScore3
                ? (evaluation.companyScore1 || 0) +
                  (evaluation.companyScore2 || 0) +
                  (evaluation.companyScore3 || 0)
                : null;
            const totalGeneral =
              totalCustomerScore && totalCompanyScore
                ? totalCustomerScore + totalCompanyScore
                : null;
            return (
              <tr key={evaluation.userId}>
                <td>
                  {`${evaluation.User.firstname} ${evaluation.User.lastname}`}
                </td>
                <td>{evaluation.customerScore1 || "-"}</td>
                <td>{evaluation.customerScore2 || "-"}</td>
                <td>{evaluation.customerScore3 || "-"}</td>
                <td className={"td-1 sep-l sep-r"}>
                  {totalCustomerScore || "-"}
                </td>
                <td>{evaluation.companyScore1 || "-"}</td>
                <td>{evaluation.companyScore2 || "-"}</td>
                <td>{evaluation.companyScore3 || "-"}</td>
                <td className={"td-2 sep-l sep-r"}>
                  {totalCompanyScore || "-"}
                </td>
                <td>{totalGeneral || "-"}</td>
                <td>
                  <button
                    className="btn-icon btn-icon-m"
                    onClick={() => {
                      setCurrentEval([evaluation]);
                    }}
                    type={"button"}
                  >
                    <IconFile />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {currentEval && (
        <ContributionEvaluationCommentDialog
          onClose={() => setCurrentEval(null)}
          evaluations={currentEval}
        />
      )}
    </div>
  );
};

export default ContributionsDetailsTable;
