export function deepSearch<E>(element: E, word: string): boolean {
  if (!element) return false;
  if (typeof element === "string") {
    return element.toLowerCase().indexOf(word) >= 0;
  } else if (typeof element === "number") {
    return (
      element.toString(10).indexOf(word) >= 0 || parseFloat(word) === element
    );
  } else if (Array.isArray(element)) {
    return element.some((subElement) => deepSearch(subElement, word));
  } else if (typeof element === "object") {
    const properties = Object.getOwnPropertyNames(element) as (keyof E)[];
    for (const i in properties) {
      if (deepSearch(element[properties[i]], word)) return true;
    }
  }
  return false;
}

export function searchGenerator<E>(
  expression: string,
  mapper?: (element: E) => unknown,
): (element: E) => boolean {
  if (expression.length === 0) return () => true;
  const words = expression.split(" ").map((e) => e.toLowerCase());

  return mapper
    ? (element: E) => {
        const mappedElement = mapper(element);
        return words.every((w) => deepSearch(mappedElement, w));
      }
    : (element: E) => words.every((w) => deepSearch(element, w));
}
