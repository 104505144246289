import React, { FunctionComponent, useMemo, useState } from "react";
import {
  Contribution,
  CONTRIBUTION_STATUSES_MAP,
  getCompanyScoreTotal,
  getContributionAverageScores,
  getCurrentStatus,
  getCustomerScoreTotal,
  getTotalScore,
} from "./contribution";
import Link from "../routing/components/Link";
import { CONTRIBUTION_DETAILS_LINK } from "../../routes/private";
import { getFullName } from "../auth/user";
import { useTranslation } from "react-i18next";
import { AllContributionsResponse } from "./api";
import { copySetAndDelete } from "../data-structures/set";
import PartialCheck from "../ui/inputs/PartialCheck";

interface Props {
  visibleContributions: AllContributionsResponse[];
  selectedContributions: Set<Contribution["id"]>;
  readOnly?: boolean;
  hideStatusColumn?: boolean;

  onSelectContributions(selectedContributions: Set<Contribution["id"]>): void;
}

const CONTRIBUTIONS_PAGE_COUNT = 15;
const DIGITS_COUNT = 2;

const ContributionsDetailsTable: FunctionComponent<Props> = ({
  visibleContributions,
  onSelectContributions,
  readOnly,
  hideStatusColumn,
  selectedContributions,
}) => {
  /* APIs */
  const { t } = useTranslation(["contributions"]);

  /* Hooks */
  const [contributionsShownCount, setContributionsShownCount] = useState(
    CONTRIBUTIONS_PAGE_COUNT,
  );
  const shownContributions = useMemo(
    () => visibleContributions.slice(0, contributionsShownCount),
    [contributionsShownCount, visibleContributions],
  );

  const isAllFilteredContributionsSelected = useMemo(
    () =>
      visibleContributions.every((contribution) =>
        selectedContributions.has(contribution.id),
      ),
    [visibleContributions, selectedContributions],
  );

  const isAFilteredContributionSelected = useMemo(
    () =>
      visibleContributions.findIndex((contribution) =>
        selectedContributions.has(contribution.id),
      ) >= 0,
    [visibleContributions, selectedContributions],
  );

  /* Methods */
  const toggleSelectedContributions = () => {
    if (isAFilteredContributionSelected) {
      onSelectContributions(new Set());
    } else {
      onSelectContributions(
        new Set(visibleContributions.map((contribution) => contribution.id)),
      );
    }
  };

  const toggleContribution = (contribution: Contribution) => {
    if (selectedContributions.has(contribution.id)) {
      onSelectContributions(
        copySetAndDelete(selectedContributions, contribution.id),
      );
    } else {
      onSelectContributions(
        new Set(selectedContributions.add(contribution.id)),
      );
    }
  };

  const showMoreContributions = () => {
    setContributionsShownCount(
      (prevContributionsShownCount) =>
        prevContributionsShownCount + CONTRIBUTIONS_PAGE_COUNT,
    );
  };

  /* Getters */
  const canShowMoreContribution =
    contributionsShownCount < visibleContributions.length;

  return (
    <div>
      <div className={"table-container"}>
        <table className={"table-1"}>
          <thead>
            <tr>
              {!readOnly && (
                <th>
                  {visibleContributions.length > 0 && (
                    <PartialCheck
                      checked={isAllFilteredContributionsSelected}
                      partiallyChecked={isAFilteredContributionSelected}
                      onClick={toggleSelectedContributions}
                    />
                  )}
                </th>
              )}
              <th>{t("contributions:CONTRIBUTOR_NAME")}</th>
              <th>{t("contributions:CONTRIBUTOR")}</th>
              {!hideStatusColumn && <th>{t("contributions:status.STATUS")}</th>}
              <th className={"th-1"}>{t("contributions:form.IMPORTANCE")}</th>
              <th className={"th-1"}>{t("contributions:form.TANGIBLE")}</th>
              <th className={"th-1"}>{t("contributions:form.SATISFACTION")}</th>
              <th colSpan={2} className={"th-1"}>
                {t("contributions:CUSTOMER_FIT")}
              </th>
              <th className={"th-2"}>{t("contributions:form.LUCRATIVE")}</th>
              <th className={"th-2"}>{t("contributions:form.PROFITABLE")}</th>
              <th className={"th-2"}>{t("contributions:form.ACHIEVABLE")}</th>
              <th colSpan={2} className={"th-2"}>
                {t("contributions:COMPANY_FIT")}
              </th>
              <th colSpan={2} className={"th-3"}>
                {t("contributions:GLOBAL_FIT")}
              </th>
            </tr>
            <tr>
              {!readOnly && <th />}
              <th />
              <th />
              {!hideStatusColumn && <th />}
              <th className={"th-1"} />
              <th className={"th-1"} />
              <th className={"th-1"} />
              <th className={"th-1 sep-l"}>
                {t("contributions:AVERAGE_ABBREV")}
              </th>
              <th className={"th-1 bold sep-r"}>
                {t("contributions:FINAL_ABBREV")}
              </th>
              <th className={"th-2"} />
              <th className={"th-2"} />
              <th className={"th-2"} />
              <th className={"th-2 sep-l"}>
                {t("contributions:AVERAGE_ABBREV")}
              </th>
              <th className={"th-2 bold sep-r"}>
                {t("contributions:FINAL_ABBREV")}
              </th>
              <th className={"th-3"}>{t("contributions:AVERAGE_ABBREV")}</th>
              <th className={"th-3 bold"}>{t("contributions:FINAL_ABBREV")}</th>
            </tr>
          </thead>
          <tbody>
            {shownContributions.map((contribution) => {
              const currentStatus = getCurrentStatus(contribution);
              const averageScores = getContributionAverageScores(contribution);

              return (
                <tr key={contribution.id}>
                  {!readOnly && (
                    <td>
                      <input
                        type={"checkbox"}
                        checked={selectedContributions.has(contribution.id)}
                        onChange={() => toggleContribution(contribution)}
                      />
                    </td>
                  )}
                  <td>
                    <Link
                      className={"link"}
                      to={CONTRIBUTION_DETAILS_LINK}
                      params={{ contributionId: contribution.id }}
                    >
                      {contribution.project}
                    </Link>
                  </td>
                  <td>{getFullName(contribution.User)}</td>
                  {!hideStatusColumn && (
                    <td className="sep-r">
                      {currentStatus
                        ? t(
                            `contributions:status.${CONTRIBUTION_STATUSES_MAP[currentStatus]}`,
                          )
                        : "-"}
                    </td>
                  )}
                  <td>
                    <div className={"nowrap"}>
                      {averageScores.customerScore1
                        ? `${t(
                            "contributions:AVERAGE_ABBREV",
                          )} ${averageScores.customerScore1.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                    <div className={"nowrap bold"}>
                      {contribution.customerScore1
                        ? `${t(
                            "contributions:FINAL_ABBREV",
                          )} ${contribution.customerScore1.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className={"nowrap"}>
                      {averageScores.customerScore2
                        ? `${t(
                            "contributions:AVERAGE_ABBREV",
                          )} ${averageScores.customerScore2.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                    <div className={"nowrap bold"}>
                      {contribution.customerScore2
                        ? `${t(
                            "contributions:FINAL_ABBREV",
                          )} ${contribution.customerScore2.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className={"nowrap"}>
                      {averageScores.customerScore3
                        ? `${t(
                            "contributions:AVERAGE_ABBREV",
                          )} ${averageScores.customerScore3.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                    <div className={"nowrap bold"}>
                      {contribution.customerScore3
                        ? `${t(
                            "contributions:FINAL_ABBREV",
                          )} ${contribution.customerScore3.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                  </td>
                  <td className="td-1 sep-l">
                    {getCustomerScoreTotal(averageScores)?.toFixed(
                      DIGITS_COUNT,
                    ) || "-"}
                  </td>
                  <td className={"td-1 sep-r bold"}>
                    {getCustomerScoreTotal(contribution)?.toFixed(
                      DIGITS_COUNT,
                    ) || "-"}
                  </td>
                  <td>
                    <div className={"nowrap"}>
                      {averageScores.companyScore1
                        ? `${t(
                            "contributions:AVERAGE_ABBREV",
                          )} ${averageScores.companyScore1.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                    <div className={"nowrap bold"}>
                      {contribution.companyScore1
                        ? `${t(
                            "contributions:FINAL_ABBREV",
                          )} ${contribution.companyScore1.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className={"nowrap"}>
                      {averageScores.companyScore2
                        ? `${t(
                            "contributions:AVERAGE_ABBREV",
                          )} ${averageScores.companyScore2.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                    <div className={"nowrap bold"}>
                      {contribution.companyScore2
                        ? `${t(
                            "contributions:FINAL_ABBREV",
                          )} ${contribution.companyScore2.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className={"nowrap"}>
                      {averageScores.companyScore3
                        ? `${t(
                            "contributions:AVERAGE_ABBREV",
                          )} ${averageScores.companyScore3.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                    <div className={"nowrap bold"}>
                      {contribution.companyScore3
                        ? `${t(
                            "contributions:FINAL_ABBREV",
                          )} ${contribution.companyScore3.toFixed(
                            DIGITS_COUNT,
                          )}`
                        : "-"}
                    </div>
                  </td>
                  <td className="td-2 sep-l">
                    {getCompanyScoreTotal(averageScores)?.toFixed(
                      DIGITS_COUNT,
                    ) || "-"}
                  </td>
                  <td className={"td-2 sep-r bold"}>
                    {getCompanyScoreTotal(contribution)?.toFixed(
                      DIGITS_COUNT,
                    ) || "-"}
                  </td>
                  <td>
                    {getTotalScore(averageScores)?.toFixed(DIGITS_COUNT) || "-"}
                  </td>
                  <td className={"bold"}>
                    {getTotalScore(contribution)?.toFixed(DIGITS_COUNT) || "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {canShowMoreContribution && (
        <button
          type={"button"}
          className={"btn-2"}
          onClick={showMoreContributions}
        >
          {t("contributions:SHOW_MORE_CONTRIBUTIONS")}
        </button>
      )}
    </div>
  );
};

export default ContributionsDetailsTable;
