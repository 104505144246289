import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useToasts } from "../../services/toast-notifications";
import { useTranslation } from "react-i18next";
import useAuth from "../../services/auth/hooks/useAuth";
import { Form, Formik } from "formik";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import { object, string } from "yup";
import ValidationsErrors from "../../services/validations/ValidationsErrors";

const LostPassword: React.FC<RouteComponentProps> = () => {
  const { lostPassword } = useAuth();
  const { error, success } = useToasts();
  const { t } = useTranslation(["auth", "ui"]);

  const LostPasswordSchema = useMemo(
    () =>
      object().shape({
        email: string().label(t("auth:EMAIL")).required().email(),
      }),
    [t],
  );

  return (
    <>
      <h1 className={"page-title"}>{t("auth:lostPassword.TITLE")}</h1>

      <Formik
        validationSchema={LostPasswordSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ email: "" }}
        onSubmit={(values, { setSubmitting }) => {
          lostPassword(values.email).then(
            () => {
              setSubmitting(false);
              success(t("auth:lostPassword.SUCCESS"));
            },
            () => {
              setSubmitting(false);
              error(t("auth:lostPassword.ERROR"));
            },
          );
        }}
      >
        {({ handleChange, isSubmitting }) => (
          <Form className={"auth-form"} noValidate>
            <div>
              <label className={"input-label"}>{t("auth:EMAIL")} *</label>
              <input
                className={"input"}
                onChange={handleChange}
                name={"email"}
                type={"email"}
                placeholder={t("auth:EMAIL_PLACEHOLDER")}
              />
            </div>

            <ValidationsErrors />

            <div className="form-footer">
              <button className="btn-1" disabled={isSubmitting}>
                {t("ui:SEND")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withRedirectToPrivate(LostPassword);
