import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import pictoScore from "../../assets/img/picto-score.svg";

const IconScore: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img className="picto" alt={t("icons:SCORE_ALT")} src={pictoScore} />;
};

export default IconScore;
