import { FunctionComponent } from "react";
import { createPortal } from "react-dom";
import useLockBodyScroll from "../useLockBodyScroll";
import { cx } from "@emotion/css";
import IconClose from "../../icons/IconClose";

interface Props {
  onClose?(): void;
  "aria-describedby"?: string;
  "aria-labelledby"?: string;
  className?: string;
}

const Dialog: FunctionComponent<Props> = ({
  onClose,
  "aria-describedby": ariaDescribedBy,
  "aria-labelledby": ariaLabelledBy,
  children,
  className,
}) => {
  useLockBodyScroll();

  const template = (
    <div
      className={"modal-overlay"}
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
    >
      <div className={cx(["dialog", className])}>
        {onClose && (
          <button className={"btn-icon close-btn"} onClick={onClose}>
            <IconClose />
          </button>
        )}

        {children}
      </div>
    </div>
  );

  return createPortal(template, document.body);
};

export default Dialog;
