import { css } from "@emotion/css";
import { ComponentProp } from "./styled/types";
import { useEffect } from "react";

export function propsToClassNames(
  props: ComponentProp,
): Record<PropertyKey, string> {
  return Object.fromEntries(
    Object.entries(props).map(([key, val]) => [key, css(val)]),
  );
}

export function useScrollTo(x: number = 0, y: number = 0): void {
  useEffect(() => {
    window.scroll(x, y);
  }, [x, y]);
}

export function getElementOffsets(
  element: HTMLElement,
): { offsetTop: number; offsetLeft: number } {
  const parentOffsets = element.parentElement
    ? getElementOffsets(element.parentElement)
    : { offsetTop: 0, offsetLeft: 0 };

  return {
    offsetTop: element.offsetTop + parentOffsets.offsetTop,
    offsetLeft: element.offsetLeft + parentOffsets.offsetLeft,
  };
}
