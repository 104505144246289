/* eslint-disable i18next/no-literal-string */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetectorI18next from "i18next-browser-languagedetector";
import AUTH_IT from "../auth/i18n/it.json";
import AUTH_EN from "../auth/i18n/en.json";
import CONTRIBUTIONS_IT from "../contributions/i18n/it.json";
import VALIDATIONS_IT from "../validations/i18n/it.json";
import USERS_IT from "../users/i18n/it.json";
import COMMITTEES_IT from "../committees/i18n/it.json";
import UI_IT from "../ui/i18n/it.json";
import ICONS_IT from "../icons/i18n/it.json";
import CONTRIBUTIONS_EN from "../contributions/i18n/en.json";
import VALIDATIONS_EN from "../validations/i18n/en.json";
import USERS_EN from "../users/i18n/en.json";
import COMMITTEES_EN from "../committees/i18n/en.json";
import UI_EN from "../ui/i18n/en.json";
import ICONS_EN from "../icons/i18n/en.json";
import { setLocale } from "yup";

i18n
  .use(languageDetectorI18next)
  .use(initReactI18next)
  .init({
    ns: ["auth", "contributions", "validations", "users", "committees"],
    resources: {
      it: {
        auth: AUTH_IT,
        contributions: CONTRIBUTIONS_IT,
        validations: VALIDATIONS_IT,
        users: USERS_IT,
        committees: COMMITTEES_IT,
        ui: UI_IT,
        icons: ICONS_IT,
      },
      en: {
        auth: AUTH_EN,
        contributions: CONTRIBUTIONS_EN,
        validations: VALIDATIONS_EN,
        users: USERS_EN,
        committees: COMMITTEES_EN,
        ui: UI_EN,
        icons: ICONS_EN,
      },
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
    },
    fallbackLng: "it",

    interpolation: {
      escapeValue: false,
    },
  });

export interface ValidationError {
  key: string;
  values: Record<string, unknown>;
}

function getError(key: string) {
  return (values: unknown) => ({ key, values } as ValidationError);
}

setLocale({
  mixed: {
    default: getError("invalid_field"),
    required: getError("required"),
    defined: getError("defined"),
    oneOf: getError("one_of"),
  },
  string: {
    email: getError("email"),
  },
});

export default i18n;
