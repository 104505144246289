import * as d3 from "d3";
import React, {
  FunctionComponent,
  RefObject,
  useEffect,
  useMemo,
  MouseEvent,
  useCallback,
} from "react";
import {
  Contribution,
  getCompanyScoreTotal,
  getContributionAverageScores,
  getCustomerScoreTotal,
} from "../contributions/contribution";
import { Selection } from "d3-selection";
import { getElementOffsets } from "../ui/utils";
import { useTranslation } from "react-i18next";

interface Props {
  contributions: Contribution[];
  onContributionClick(contributionId: Contribution["id"]): void;
}

interface PartialContributionD3 {
  id: Contribution["id"];
  consFit: number | null;
  consFitIsAverage: boolean;
  compFit: number | null;
  compFitIsAverage: boolean;
  name: Contribution["project"];
  color?: string;
}

interface ContributionD3 extends PartialContributionD3 {
  consFit: NonNullable<PartialContributionD3["consFit"]>;
  compFit: NonNullable<PartialContributionD3["compFit"]>;
  color: NonNullable<PartialContributionD3["color"]>;
}

type SelectionSVG = Selection<SVGSVGElement, unknown, null, undefined>;

const FONT_SIZE = 24;
const TOOLTIP_OFFSET = { top: 10, left: 10 };
const MARGIN_BETWEEN_TEXT = 10;

const PLOT_MARGINS = {
  TOP: FONT_SIZE + MARGIN_BETWEEN_TEXT + FONT_SIZE,
  RIGHT: 100, // To prevent the tooltip from shrinking too much on the right border
  BOTTOM: FONT_SIZE + MARGIN_BETWEEN_TEXT + FONT_SIZE + MARGIN_BETWEEN_TEXT,
  LEFT: FONT_SIZE + FONT_SIZE,
};
const SVG_VIEW_BOX_HEIGHT = 600;
const SVG_VIEW_BOX_WIDTH = 1200;

const X_AXIS_LABEL = "CustomerFit →";
const Y_AXIS_LABEL = "↑ CompanyFit";

const CIRCLE_RADIUS = 15;

export const COLOR_CONSUMER_AND_COMPANY_ARE_AVERAGES = "#fff700";
export const COLOR_CONSUMER_OR_COMPANY_IS_AVERAGE = "#0000da";
export const COLOR_CONSUMER_AND_COMPANY_ARE_FINALS = "#23a866";

const SCALE_MIN = 0;
const SCALE_MAX = 15;

const CommitteeNotationPlot: FunctionComponent<Props> = ({
  contributions,
  onContributionClick,
}) => {
  const { t } = useTranslation(["committees"]);

  const plotDiv: RefObject<HTMLDivElement> = React.createRef();

  const onCircleClick = useCallback(
    (ev: MouseEvent, d: ContributionD3) => {
      onContributionClick(d.id);
    },
    [onContributionClick],
  );

  /* Preparing the data */
  const contributionsD3 = useMemo(() => {
    return contributions
      .map((c) => {
        const contributionAverages = getContributionAverageScores(c);

        const consFit = getCustomerScoreTotal(c);
        const compFit = getCompanyScoreTotal(c);

        const contributionD3: PartialContributionD3 = {
          id: c.id,
          consFit: consFit || getCustomerScoreTotal(contributionAverages),
          compFit: compFit || getCompanyScoreTotal(contributionAverages),
          consFitIsAverage: consFit === null,
          compFitIsAverage: compFit === null,
          name: c.project,
        };

        contributionD3.color =
          contributionD3.consFitIsAverage && contributionD3.compFitIsAverage
            ? COLOR_CONSUMER_AND_COMPANY_ARE_AVERAGES
            : contributionD3.consFitIsAverage || contributionD3.compFitIsAverage
            ? COLOR_CONSUMER_OR_COMPANY_IS_AVERAGE
            : COLOR_CONSUMER_AND_COMPANY_ARE_FINALS;

        return contributionD3;
      })
      .filter(
        (c) => c.compFit !== null && c.consFit !== null,
      ) as ContributionD3[];
  }, [contributions]);

  // Consumer fit axis
  const xScale = d3
    .scaleLinear()
    .domain([SCALE_MIN, SCALE_MAX])
    .range([PLOT_MARGINS.LEFT, SVG_VIEW_BOX_WIDTH - PLOT_MARGINS.RIGHT]);

  // Company fit axis
  const yScale = d3
    .scaleLinear()
    .domain([SCALE_MIN, SCALE_MAX])
    .range([SVG_VIEW_BOX_HEIGHT - PLOT_MARGINS.BOTTOM, PLOT_MARGINS.TOP]);

  /* Drawing the plot */
  useEffect(() => {
    const xAxisLegend = (g: SelectionSVG) =>
      g
        .attr(
          "transform",
          `translate(0,${SVG_VIEW_BOX_HEIGHT - PLOT_MARGINS.BOTTOM})`,
        )
        .style("font-size", FONT_SIZE + "px")
        .call(d3.axisBottom(xScale).ticks(SCALE_MAX - SCALE_MIN))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .append("text")
            .text(X_AXIS_LABEL)
            .attr("class", "axis-label")
            .attr("x", SVG_VIEW_BOX_WIDTH)
            .attr("y", PLOT_MARGINS.BOTTOM - MARGIN_BETWEEN_TEXT)
            .attr("fill", "currentColor")
            .attr("text-anchor", "end"),
        );

    const yAxisLegend = (g: SelectionSVG) =>
      g
        .attr("transform", `translate(${PLOT_MARGINS.LEFT},0)`)
        .style("font-size", FONT_SIZE + "px")
        .call(d3.axisLeft(yScale).ticks(SCALE_MAX - SCALE_MIN))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .append("text")
            .text(Y_AXIS_LABEL)
            .attr("class", "axis-label")
            .attr("x", -PLOT_MARGINS.LEFT)
            .attr("y", FONT_SIZE)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start"),
        );

    const grid = (g: SelectionSVG) =>
      g
        .attr("stroke", "currentColor")
        .attr("stroke-opacity", 0.1)
        .call((g) =>
          g // X Axis
            .append("g")
            .selectAll("line")
            .data(xScale.ticks(SCALE_MAX - SCALE_MIN))
            .join("line")
            .attr("x1", (d) => xScale(d))
            .attr("x2", (d) => xScale(d))
            .attr("y1", PLOT_MARGINS.TOP)
            .attr("y2", SVG_VIEW_BOX_HEIGHT - PLOT_MARGINS.BOTTOM),
        )
        .call((g) =>
          g // Y axis
            .append("g")
            .selectAll("line")
            .data(yScale.ticks(SCALE_MAX - SCALE_MIN))
            .join("line")
            .attr("y1", (d) => yScale(d))
            .attr("y2", (d) => yScale(d))
            .attr("x1", PLOT_MARGINS.LEFT)
            .attr("x2", SVG_VIEW_BOX_WIDTH - PLOT_MARGINS.RIGHT),
        )
        .call((g) =>
          g // Vertical middle bar
            .append("g")
            .append("line")
            .attr("stroke-opacity", 0.5)
            .attr(
              "x1",
              (-PLOT_MARGINS.LEFT + SVG_VIEW_BOX_WIDTH - PLOT_MARGINS.RIGHT) /
                2 +
                PLOT_MARGINS.LEFT,
            )
            .attr(
              "x2",
              (-PLOT_MARGINS.LEFT + SVG_VIEW_BOX_WIDTH - PLOT_MARGINS.RIGHT) /
                2 +
                PLOT_MARGINS.LEFT,
            )
            .attr("y1", PLOT_MARGINS.TOP)
            .attr("y2", SVG_VIEW_BOX_HEIGHT - PLOT_MARGINS.BOTTOM),
        )
        .call((g) =>
          g // Horizontal middle bar
            .append("g")
            .append("line")
            .attr("stroke-opacity", 0.5)
            .attr(
              "y1",
              (-PLOT_MARGINS.TOP + SVG_VIEW_BOX_HEIGHT - PLOT_MARGINS.BOTTOM) /
                2 +
                PLOT_MARGINS.TOP,
            )
            .attr(
              "y2",
              (-PLOT_MARGINS.TOP + SVG_VIEW_BOX_HEIGHT - PLOT_MARGINS.BOTTOM) /
                2 +
                PLOT_MARGINS.TOP,
            )
            .attr("x1", PLOT_MARGINS.LEFT)
            .attr("x2", SVG_VIEW_BOX_WIDTH - PLOT_MARGINS.RIGHT),
        );

    const container = d3.select(plotDiv.current).attr("class", "plot");

    // Remove all the children
    container.selectChildren().remove();

    const tooltip = container.append("div").attr("class", "plot-tooltip");

    const mouseover = (ev: MouseEvent<SVGElement>) => {
      tooltip.style("display", "inherit");
    };

    const mousemove = (ev: MouseEvent<SVGElement>, d: ContributionD3) => {
      const offsets = getElementOffsets(
        plotDiv.current as NonNullable<typeof plotDiv.current>,
      );

      tooltip
        .html(
          `<strong>${d.name}</strong><br>consumer fit${
            d.consFitIsAverage
              ? ` (${t("committees:notationPlot.AVERAGE")})`
              : ""
          }: ${d.consFit.toFixed(2)}<br>company fit${
            d.compFitIsAverage
              ? ` (${t("committees:notationPlot.AVERAGE")})`
              : ""
          }: ${d.compFit.toFixed(2)}`,
        )
        .style(
          "left",
          ev.clientX - offsets.offsetLeft + TOOLTIP_OFFSET.left + "px",
        )
        .style(
          "top",
          ev.clientY - offsets.offsetTop + TOOLTIP_OFFSET.top + "px",
        );
    };

    const mouseleave = (ev: MouseEvent<SVGElement>) => {
      tooltip.style("display", "none");
    };

    const svg = container
      .append("svg")
      .attr("viewBox", `0 0 ${SVG_VIEW_BOX_WIDTH} ${SVG_VIEW_BOX_HEIGHT}`)
      .attr("class", "plot-draw");

    svg.append("g").call(xAxisLegend);

    svg.append("g").call(yAxisLegend);

    svg.append("g").call(grid);

    svg
      .append("g")
      .selectAll("circle")
      .data(contributionsD3)
      .join("circle")
      .attr("class", "plot-circle")
      .attr("cx", (d) => xScale(d.consFit))
      .attr("cy", (d) => yScale(d.compFit))
      .attr("fill", (d) => (d.color ? d.color : "none"))
      .attr("r", CIRCLE_RADIUS)
      .attr("key", (_d, idx) => idx)
      .style("cursor", "pointer")
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      // eslint-disable-next-line i18next/no-literal-string
      .on("click", onCircleClick);
  }, [t, plotDiv, contributionsD3, xScale, yScale, onCircleClick]);

  return <div ref={plotDiv} />;
};

export default CommitteeNotationPlot;
