import { createContext, useContext, useState } from "react";
import { getAllUsers, updateUserCommittee, updateUserRole } from "./api";
import { User, UserCommittee, UserRole } from "../auth/user";
import { AxiosPromise } from "axios";

export interface UsersAPI {
  users: Map<User["userId"], User>;
  updateUserRole(userId: User["userId"], role: UserRole): AxiosPromise<void>;
  updateUserCommittee(
    userId: User["userId"],
    committee: UserCommittee,
  ): AxiosPromise<void>;
  loadAllUsers(): Promise<User[]>;
}

export function useProvideUsers(
  defaultUsers: Map<User["userId"], User> = new Map(),
): UsersAPI {
  const [users, setUsers] = useState(defaultUsers);

  return {
    users,
    loadAllUsers() {
      return getAllUsers().then((users) => {
        setUsers(new Map(users.data.map((c) => [c.userId, c])));
        return users.data;
      });
    },
    updateUserRole(userId, role) {
      return updateUserRole(userId, role).then((res) => {
        setUsers((prevUsers) => {
          const newUsers = new Map(prevUsers);

          if (newUsers.has(userId)) {
            newUsers.set(userId, {
              ...(newUsers.get(userId) as User),
              admin: role,
            });
          }

          return newUsers;
        });
        return res;
      });
    },
    updateUserCommittee(userId, committee) {
      return updateUserCommittee(userId, committee).then((res) => {
        setUsers((prevUsers) => {
          const newUsers = new Map(prevUsers);

          if (newUsers.has(userId)) {
            newUsers.set(userId, {
              ...(newUsers.get(userId) as User),
              committee: committee,
            });
          }

          return newUsers;
        });
        return res;
      });
    },
  };
}

export const UsersContext = createContext<UsersAPI>({
  users: new Map(),
  loadAllUsers() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  updateUserRole() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  updateUserCommittee() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
});

export function useUsers(): UsersAPI {
  return useContext(UsersContext);
}

export interface WithProvideUsers {
  usersAPI: UsersAPI;
}
