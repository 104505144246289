import React, { FunctionComponent } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import Link from "../../../../services/routing/components/Link";
import {
  COMMITTEE_DETAILS,
  COMMITTEE_DETAILS_CONTRIBUTIONS,
  COMMITTEE_DETAILS_EVALUATIONS,
  COMMITTEES_PANEL_LINK,
} from "../../../../routes/committees";
import CommitteeDetails from "./CommitteeDetails";
import NavLink from "../../../../services/routing/components/NavLink";
import useLoader from "../../../../services/routing/useLoader";
import {
  CommitteeContext,
  useProvideCommittee,
} from "../../../../services/committees/useProvideCommittee";
import Loading from "../../../../services/routing/components/Loading";
import LoaderErrors from "../../../../services/routing/components/LoaderErrors";
import CommitteeDetailsContributions from "./CommitteeDetailsContributions";
import CommitteeDetailsEvaluation from "./CommitteeDetailsEvaluation";
import { Committee as CommitteeType } from "../../../../services/committees/committee";
import { useTranslation } from "react-i18next";

const Committee: FunctionComponent<
  RouteComponentProps<{ committeeId: string }>
> = ({ committeeId, uri }) => {
  /* Hooks */
  const committee = useProvideCommittee();
  const { t } = useTranslation(["committees", "ui"]);

  /* Loading */
  const { loadCommittee, committee: loadedCommittee } = committee;
  const { loading, error: loadingError, reload } = useLoader(
    () => loadCommittee(parseInt(committeeId as string, 10)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [committeeId],
  );

  /* End loading */
  if (loading) return <Loading />;
  if (loadingError)
    return <LoaderErrors reload={reload} error={loadingError} />;

  const canModify = !(loadedCommittee as CommitteeType).closureDate;

  return (
    <CommitteeContext.Provider value={committee}>
      <div className="page-head">
        <h1 className="page-title">{t("committees:COMMITTEE")}</h1>
        <Link className="link link-small" to={COMMITTEES_PANEL_LINK}>
          &lt; {t("ui:GO_BACK")}
        </Link>
        <ul className="tabs">
          <NavLink
            className="tab-item"
            to={"." + COMMITTEE_DETAILS_EVALUATIONS}
          >
            {t("committees:CONTRIBUTIONS_IN_THE_COMMITTEE")}
          </NavLink>
          {canModify && (
            <NavLink
              className="tab-item"
              to={"." + COMMITTEE_DETAILS_CONTRIBUTIONS}
            >
              {t("committees:ADD_CONTRIBUTIONS")}
            </NavLink>
          )}
          <NavLink className="tab-item" to={"." + COMMITTEE_DETAILS}>
            {t("committees:DETAILS")}
          </NavLink>
        </ul>
      </div>
      <Router>
        <Redirect from={"/"} to={uri + COMMITTEE_DETAILS} default noThrow />
        <CommitteeDetails path={COMMITTEE_DETAILS} />
        {canModify && (
          <CommitteeDetailsContributions
            path={COMMITTEE_DETAILS_CONTRIBUTIONS}
          />
        )}
        <CommitteeDetailsEvaluation path={COMMITTEE_DETAILS_EVALUATIONS} />
      </Router>
    </CommitteeContext.Provider>
  );
};

export default Committee;
