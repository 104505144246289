import { ADMIN } from "./admin";

export const COMMITTEES_PANEL = "/committees" as const;
export const COMMITTEES_PANEL_PATH = "/committees/*" as const;
export const COMMITTEES_PANEL_LINK = `${ADMIN}${COMMITTEES_PANEL}` as const;

export const COMMITTEE_NEW = `/new` as const;
export const COMMITTEE_NEW_LINK = `${COMMITTEES_PANEL_LINK}${COMMITTEE_NEW}` as const;

export const COMMITTEE = `/:committeeId` as const;
export const COMMITTEE_PATH = `/:committeeId/*` as const;
export const COMMITTEE_LINK = `${COMMITTEES_PANEL_LINK}/:committeeId` as const;

export const COMMITTEE_DETAILS = `/infos` as const;
export const COMMITTEE_DETAILS_LINK = `${COMMITTEE_LINK}${COMMITTEE_DETAILS}` as const;

export const COMMITTEE_DETAILS_CONTRIBUTIONS = `/contributions` as const;
export const COMMITTEE_DETAILS_CONTRIBUTIONS_LINK = `${COMMITTEE_LINK}${COMMITTEE_DETAILS_CONTRIBUTIONS}` as const;

export const COMMITTEE_DETAILS_EVALUATIONS = `/evaluations` as const;
export const COMMITTEE_DETAILS_EVALUATIONS_LINK = `${COMMITTEE_LINK}${COMMITTEE_DETAILS_EVALUATIONS}` as const;
