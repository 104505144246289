import { createContext } from "react";
import { AuthAPI } from "./types";

/**
 * Default value should never be used
 */
const AuthContext = createContext<AuthAPI>({
  user: null,
  auth0State: null,
  setAuth0State(state: AuthAPI["auth0State"]) {
    // DO NOTHING
  },
  login() {
    return Promise.reject();
  },
  logout(): Promise<void> {
    return Promise.reject();
  },
  checkUserValidity(): Promise<void> {
    return Promise.reject();
  },
  register(user) {
    return Promise.reject();
  },
  validateUserRegistration(guid) {
    return Promise.reject();
  },
  updateUser(newUser) {
    return Promise.reject();
  },
  lostPassword(email) {
    return Promise.reject();
  },
  resetPassword() {
    return Promise.reject();
  },
  registerAndCreateContributionWithCaptcha() {
    return Promise.reject();
  },
  registerAndCreateContributionWithAuth0() {
    return Promise.reject();
  },
});

export default AuthContext;
