import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";

const TermsOfUse: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation(["auth"]);

  return (
    <div>
      <h1 className="page-title">{t("auth:termsOfUse.TITLE")}</h1>

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.INTRO") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_1") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_2") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_3") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_4") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_5") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_6") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_7") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_8") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_9") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_10") }}
      />

      <div
        className={"section"}
        dangerouslySetInnerHTML={{ __html: t("auth:termsOfUse.ARTICLE_11") }}
      />
    </div>
  );
};

export default TermsOfUse;
