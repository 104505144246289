import React, { FunctionComponent } from "react";
import { cx } from "@emotion/css";
import IconCheck from "../../icons/IconCheck";

interface Props {
  partiallyChecked: boolean;
  checked: boolean;
  onClick(): void;
}

const PartialCheck: FunctionComponent<Props> = ({
  checked,
  partiallyChecked,
  onClick,
}) => {
  return (
    <button
      className={cx(["btn-checkbox", checked && "checked"])}
      onClick={onClick}
    >
      {checked ? <IconCheck /> : partiallyChecked ? "-" : ""}
    </button>
  );
};

export default PartialCheck;
