import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { ContributionDocumentBase as IContributionDocument } from "./contribution";
import { boolean, object, SchemaOf, string } from "yup";
import Lightbox from "../ui/elements/Lightbox";
import {
  download,
  downloadObjectURL,
  getObjectURL,
  ObjectURL,
} from "../auth/baseAPI";
import useLoader from "../routing/useLoader";
import Loading from "../routing/components/Loading";
import useFileAsObjectURL from "../files/useFileAsObjectURL";
import imgPlaceholder from "../../assets/img/placeholder.svg";
import { useTranslation } from "react-i18next";
import IconTrash from "../icons/IconTrash";
import IconFocus from "../icons/IconFocus";
import IconDownload from "../icons/IconDownload";

interface Props {
  document: IContributionDocument;
  canEdit?: boolean;
  onRemove?(): void;
  onDescriptionChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  onCoverChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  canCoverChange?: boolean;
}

export const ContributionDocumentSchema: SchemaOf<IContributionDocument> = object()
  .shape({
    name: string().required(),
    originalName: string().required(),
    cover: boolean().required().default(false),
  })
  .defined();

// eslint-disable-next-line i18next/no-literal-string
const imagesExtensions = [".jpg", ".jpeg", ".png", ".gif"];
function getIsImage(doc: IContributionDocument) {
  // when image has just been loaded, but not saved yet,
  // it is contained as base64 format in doc.name
  if (doc.name && doc.name.indexOf("data:image") !== -1) {
    return true;
  }
  // else, no doc.doc property => check file name extension
  const name = doc.originalName.toLowerCase();
  return imagesExtensions.some((e) => name.indexOf(e) !== -1);
}

function getIsPDF(doc: IContributionDocument) {
  return (
    (doc.name && doc.name.indexOf("application/pdf") !== -1) ||
    doc.originalName.toLowerCase().indexOf(".pdf") !== -1
  );
}

const downloadFile = (document: IContributionDocument) => {
  if (document.name.startsWith("http")) {
    return download(document.name);
  } else {
    return downloadObjectURL({
      url: document.name,
      name: document.originalName,
    });
  }
};

const ContributionDocument: FunctionComponent<Props> = ({
  document,
  canEdit,
  onRemove,
  onDescriptionChange,
  onCoverChange,
  canCoverChange,
}) => {
  /* APIs */
  const [lightbox, setLightbox] = useState(false);
  const { t } = useTranslation(["contributions"]);

  const isImage = getIsImage(document);
  const isPDF = getIsPDF(document);

  const linkDownloadRef = useRef<HTMLElement>(null);
  useEffect(() => {
    const link = linkDownloadRef.current as HTMLElement;
    const listener = () =>
      isImage || isPDF ? setLightbox(true) : downloadFile(document);

    link.addEventListener("click", listener);

    return () => {
      link.removeEventListener("click", listener);
    };
  });

  const visualizeRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (visualizeRef.current) {
      const visualize = visualizeRef.current as HTMLElement;
      const listener = () => setLightbox(true);

      visualize.addEventListener("click", listener);
      return () => {
        visualize.removeEventListener("click", listener);
      };
    }
  });
  const downloadRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (downloadRef.current) {
      const download = downloadRef.current as HTMLElement;
      const listener = () => downloadFile(document);

      download.addEventListener("click", listener);

      return () => {
        download.removeEventListener("click", listener);
      };
    }
  });

  return (
    <div className="attachment" key={document.id}>
      <div className="attachment-head">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <span
          role={"button"}
          className="attachment-file link"
          tabIndex={0}
          ref={linkDownloadRef}
        >
          {isImage && <SpanImagePreview document={document} />}
          <span className="attachment-name">{document.originalName}</span>
        </span>
        <div className="col-fit btns-bar">
          {(isImage || isPDF) && (
            <span
              role={"button"}
              tabIndex={0}
              className="btn-icon btn-icon-m"
              ref={visualizeRef}
            >
              <IconFocus />
            </span>
          )}
          <span
            role={"button"}
            tabIndex={0}
            className="btn-icon btn-icon-m"
            ref={downloadRef}
          >
            <IconDownload />
          </span>
          {canEdit && (
            <button
              className="btn-icon btn-icon-m"
              onClick={onRemove}
              type={"button"}
            >
              <IconTrash />
            </button>
          )}
        </div>
      </div>
      {(canEdit || document.description) && (
        <div className="attachment-desc-input">
          <input
            type="text"
            disabled={!canEdit}
            className="input"
            onChange={onDescriptionChange}
            placeholder={t("contributions:file.DESCRIPTION")}
            value={document.description || ""}
          />
        </div>
      )}
      {canCoverChange && canEdit && (
        <div className="attachment-cover-choice">
          {isImage && (
            <span>
              <input
                type="checkbox"
                name="cover"
                checked={document.cover || false}
                onChange={onCoverChange}
              />
              {document.cover
                ? t("contributions:file.COVER_IMAGE")
                : t("contributions:file.DEFINE_AS_COVER_IMAGE")}
            </span>
          )}
        </div>
      )}

      {lightbox && (
        <Lightbox onClose={() => setLightbox(false)}>
          <Preview document={document} />
        </Lightbox>
      )}
    </div>
  );
};

const Preview: FunctionComponent<{
  document: IContributionDocument;
}> = ({ document }) => {
  const isImage = getIsImage(document);
  const [objectUrl, setObjectUrl] = useState<ObjectURL>({
    url: document.name,
    name: document.originalName,
  });

  useEffect(() => {
    return () => {
      if (objectUrl.url.startsWith("blob:"))
        window.URL.revokeObjectURL(objectUrl.url);
    };
  });

  const { loading, error } = useLoader(
    () =>
      document.name.startsWith("http")
        ? getObjectURL(document.name).then(setObjectUrl)
        : Promise.resolve(),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (loading) return <Loading />;
  if (error)
    return (
      <img
        src={imgPlaceholder}
        alt={document.originalName}
        style={{ height: "500px", width: "500px" }}
      />
    );

  return isImage ? (
    <img src={objectUrl.url} alt={objectUrl.name} />
  ) : (
    <iframe src={objectUrl.url} title={objectUrl.name} />
  );
};

const SpanImagePreview: FunctionComponent<{
  document: IContributionDocument;
}> = ({ document }) => {
  const objectURL = useFileAsObjectURL({
    url: document.name,
    name: document.originalName,
  });

  return (
    <span
      className="attachment-img"
      style={
        objectURL
          ? {
              backgroundImage: `url(${objectURL.url})`,
              backgroundSize: "cover",
            }
          : undefined
      }
    />
  );
};

export default ContributionDocument;
