import React, { FunctionComponent } from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import useAuth from "../../services/auth/hooks/useAuth";
import { useToasts } from "../../services/toast-notifications";
import { useTranslation } from "react-i18next";
import { PUBLIC } from "../../routes/public";
import RegisterForm from "../../services/auth/RegisterForm";

const Register: FunctionComponent<RouteComponentProps> = () => {
  const auth = useAuth();
  const { error, success } = useToasts();
  const { t } = useTranslation(["auth"]);
  const navigate = useNavigate();

  return (
    <>
      <h1 className="page-title">{t("auth:REGISTER")}</h1>

      <RegisterForm
        onSubmit={(registerInfos, setSubmitting) => {
          auth.register(registerInfos).then(
            () => {
              success(t("auth:register.TOAST_SUCCESS"));
              navigate(PUBLIC);
              setSubmitting(false);
            },
            (err) => {
              if (err.response.status === 409) {
                error(t("auth:register.MAIL_ALREADY_USED"));
              } else {
                error(t("auth:register.TOAST_ERROR"));
              }
              setSubmitting(false);
            },
          );
        }}
      />
    </>
  );
};

export default Register;
