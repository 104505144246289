import { createContext, useContext, useState } from "react";
import { copyMapAndDelete } from "../data-structures/map";
import {
  addContributionsToCommittee as addContributionsToCommitteeAPI,
  AllContributionsResponse,
  bulkRefuseContributions as bulkRefuseContributionsAPI,
  CommitteeContribution,
  getAllContributions,
} from "./api";
import { Contribution, ContributionStatus } from "./contribution";
import { Committee } from "../committees/committee";

export interface ContributionsAPI {
  contributions: Map<Contribution["id"], AllContributionsResponse>;

  deleteContribution(id: Contribution["id"]): void;

  updateOrCreateContribution(contribution: AllContributionsResponse): void;

  loadAllContributions(): Promise<Contribution[]>;

  bulkRefuseContributions(
    contributionsIds: Contribution["id"][],
    status: ContributionStatus,
  ): Promise<void>;

  addContributionsToCommittee(
    committeeId: Committee["id"],
    contributionsIds: Contribution["id"][],
  ): Promise<Contribution[]>;
}

export function useProvideContributions(
  defaultContributions: Map<
    Contribution["id"],
    AllContributionsResponse
  > = new Map(),
): ContributionsAPI {
  const [contributions, setContributions] = useState(defaultContributions);

  const deleteContribution = (id: Contribution["id"]) => {
    setContributions((prevContributions) =>
      copyMapAndDelete(prevContributions, id),
    );
  };

  const updateOrCreateContribution = (
    contribution: AllContributionsResponse,
  ) => {
    setContributions((prevContributions) =>
      new Map(prevContributions).set(contribution.id, contribution),
    );
  };

  const loadAllContributions = () => {
    return getAllContributions().then((contributions) => {
      setContributions(new Map(contributions.data.map((c) => [c.id, c])));
      return contributions.data;
    });
  };

  const bulkRefuseContributions = (
    contributionsIds: Contribution["id"][],
  ): Promise<void> => {
    return bulkRefuseContributionsAPI(contributionsIds).then((res) => {
      setContributions((prevContributions) => {
        const nContributions = new Map(prevContributions);

        for (const history of res.data) {
          const prevContribution = nContributions.get(
            history.contributionId,
          ) as CommitteeContribution;
          nContributions.set(history.contributionId, {
            ...prevContribution,
            Histories: [...prevContribution.Histories, history],
          });
        }

        return nContributions;
      });
    });
  };

  const addContributionsToCommittee = (
    committeeId: Committee["id"],
    contributionsIds: Contribution["id"][],
  ): Promise<Contribution[]> => {
    return addContributionsToCommitteeAPI(committeeId, contributionsIds).then(
      loadAllContributions,
    );
  };

  return {
    contributions,
    deleteContribution,
    updateOrCreateContribution,
    loadAllContributions,
    bulkRefuseContributions,
    addContributionsToCommittee,
  };
}

export const ContributionsContext = createContext<ContributionsAPI>({
  contributions: new Map(),
  deleteContribution(): void {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
  },
  loadAllContributions() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject([]);
  },
  updateOrCreateContribution(): void {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
  },
  bulkRefuseContributions() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
  addContributionsToCommittee() {
    // Impossible https://youtu.be/Mhj15W23IjA?t=68
    return Promise.reject();
  },
});

export function useContributions(): ContributionsAPI {
  return useContext(ContributionsContext);
}

export interface WithProvideContributions {
  contributionAPI: ContributionsAPI;
}
