import React, { FunctionComponent } from "react";
import { Field } from "formik";
import FSelectParse from "../ui/elements/FSelectParse";
import { COMMITTEE_TYPES } from "./committee";
import { useTranslation } from "react-i18next";

const CommitteeFields: FunctionComponent = () => {
  const { t } = useTranslation(["committees"]);

  return (
    <>
      <div className="input-block">
        <label className="input-label" htmlFor="name">
          {t("committees:NAME")} *
        </label>
        <Field className="input" id="name" name="name" />
      </div>

      <div className="input-block">
        <label className="input-label" htmlFor="date">
          {t("committees:DATE")} *
        </label>
        <Field type="date" className="input" id="date" name="date" />
      </div>

      <div className="input-block">
        <label className="input-label" htmlFor="type">
          {t("committees:TYPE")} *
        </label>
        <FSelectParse
          name="type"
          className="select"
          parse={(val) => (val === "" ? null : parseInt(val))}
        >
          <option value={""} />
          {COMMITTEE_TYPES.map((committeeType) => (
            <option value={committeeType} key={committeeType}>
              {committeeType}
            </option>
          ))}
        </FSelectParse>
      </div>
    </>
  );
};

export default CommitteeFields;
