import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import iconCalendar from "../../assets/img/picto-calendar.svg";

const IconCalendar: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return (
    <img className={"icon"} src={iconCalendar} alt={t("icons:CALENDAR_ALT")} />
  );
};

export default IconCalendar;
