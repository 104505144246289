import { Field, FieldProps } from "formik";
import React, { FunctionComponent } from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  name: string;
  parse(val: string): unknown;
}

const FSelectParse: FunctionComponent<Props> = ({
  name,
  children,
  parse,
  ...otherProps
}) => {
  return (
    <Field name={name}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => (
        <select
          onChange={(event) => setFieldValue(name, parse(event.target.value))}
          onBlur={() => setFieldTouched(name)}
          value={value === null ? "" : value}
          {...otherProps}
        >
          {children}
        </select>
      )}
    </Field>
  );
};

export default FSelectParse;
