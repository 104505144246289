import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";

const CookiePolicy: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation(["auth"]);

  return (
    <div>
      <h1 className={"page-title"}>{t("auth:cookiePolicy.TITLE")}</h1>
      <div>
        <div className={"section-s"}>
          <p>{t("auth:cookiePolicy.INTRO")}</p>
        </div>

        <div
          className="section-s"
          dangerouslySetInnerHTML={{ __html: t("auth:cookiePolicy.SECTION_1") }}
        />

        <div
          className="section-s"
          dangerouslySetInnerHTML={{ __html: t("auth:cookiePolicy.SECTION_2") }}
        />

        <div
          className="section-s"
          dangerouslySetInnerHTML={{ __html: t("auth:cookiePolicy.SECTION_3") }}
        />

        <div className={"section"}>
          <h2 className={"section-title"}>
            {t("auth:cookiePolicy.SECTION_4_TITLE")}
          </h2>
          <h3 className={"section-subtitle"}>
            {t("auth:cookiePolicy.SECTION_4_SUBTITLE")}
          </h3>

          <p>{t("auth:cookiePolicy.SECTION_4_DESCRIPTION")}</p>

          <div className="section-s">
            <div className="overflow-auto">
              <table className={"table-1"}>
                <thead>
                  <tr
                    dangerouslySetInnerHTML={{
                      __html: t("auth:cookiePolicy.SECTION_4_TABLE_HEAD"),
                    }}
                  />
                </thead>
                <tbody
                  dangerouslySetInnerHTML={{
                    __html: t("auth:cookiePolicy.SECTION_4_TABLE_BODY"),
                  }}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
