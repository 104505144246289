import React, { FunctionComponent } from "react";
import { User } from "./user";
import Dialog from "../ui/elements/Dialog";
import { useTranslation } from "react-i18next";
import { useDate } from "../date/DateContext";

interface Props {
  user: User;
  onClose: () => void;
  canSeeAllFields?: boolean;
  mailSubject?: string;
}

const UserInfosDialog: FunctionComponent<Props> = ({
  user,
  onClose,
  mailSubject,
  canSeeAllFields,
}) => {
  const { t } = useTranslation(["auth", "ui"]);
  const { formatISO } = useDate();

  return (
    <Dialog onClose={onClose}>
      <div className="dialog-head">
        <h3 className="dialog-title">{t("auth:user.USER_PROFILE")}</h3>
      </div>
      <div className="popup-body grid">
        {/* <div className={"col-1-1"}>
          <label className="input-label">{t("auth:USER_TYPOLOGY")}</label>
          <p>{needsTypologyInput(user.type) ? user.otherType : user.type}</p>
        </div> */}

        <div className="col-md-1-2">
          <label className="input-label">{t("auth:LASTNAME")}</label>
          <p>{user.lastname}</p>
        </div>
        <div className="col-md-1-2">
          <label className="input-label">{t("auth:FIRSTNAME")}</label>
          <p>{user.firstname}</p>
        </div>
        {canSeeAllFields && (
          <>
            <div className="col-md-1-2">
              <label className="input-label">{t("auth:PSEUDO")}</label>
              {user.pseudo && user.pseudo.length > 0 ? (
                <p>{user.pseudo}</p>
              ) : (
                <p className="body-xs">{t("auth:USER_PSEUDO_IS_EMPTY")}</p>
              )}
            </div>
            <div className="col-md-1-2">
              <label className="input-label">{t("auth:EMAIL")}</label>
              <p>
                <a
                  className="link link-small"
                  href={`mailto:${user.email}?subject=${mailSubject}`}
                >
                  {user.email}
                </a>
              </p>
            </div>
            <div className="col-md-1-2">
              <label className="input-label">{t("auth:BIRTHDATE")}</label>

              {user.birthdate ? (
                <p>{formatISO(user.birthdate)}</p>
              ) : (
                <p className="body-xs">{t("auth:user.BIRTHDATE_EMPTY")}</p>
              )}
            </div>
            <div className="col-md-1-2">
              <label className="input-label">{t("auth:TEL")}</label>
              {user.tel ? (
                <p>
                  <a className="link link-small" href={`tel:${user.tel}`}>
                    {user.tel}
                  </a>
                </p>
              ) : (
                <p className="body-xs">{t("auth:user.TEL_EMPTY")}</p>
              )}
            </div>
            <div className="col-1-1">
              <label className="input-label">{t("auth:BIO")}</label>
              {user.bio && user.bio.length > 0 ? (
                <p>{user.bio}</p>
              ) : (
                <p className="body-xs">{t("auth:user.BIO_EMPTY")}</p>
              )}
            </div>
          </>
        )}
      </div>
      <div className="form-footer">
        <button type="button" className="btn-1" onClick={onClose}>
          {t("ui:OK")}
        </button>
      </div>
    </Dialog>
  );
};

export default UserInfosDialog;
