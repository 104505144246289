import { RouteComponentProps, useNavigate } from "@reach/router";
import React, { useMemo, useState } from "react";
import { searchGenerator } from "../../../../services/filter/search";
import {
  Contribution,
  ContributionStatus,
  filterContributionOnScoresGenerator,
  getContributionAverageScores,
} from "../../../../services/contributions/contribution";
import { useTranslation } from "react-i18next";
import {
  CommitteeAPILoaded,
  useCommittee,
} from "../../../../services/committees/useProvideCommittee";
import { useToasts } from "../../../../services/toast-notifications";
import ContributionsDetailsTable from "../../../../services/contributions/ContributionsDetailsTable";
import Dialog from "../../../../services/ui/elements/Dialog";
import useLoader from "../../../../services/routing/useLoader";
import Loading from "../../../../services/routing/components/Loading";
import LoaderErrors from "../../../../services/routing/components/LoaderErrors";
import { CommitteeType } from "../../../../services/committees/committee";
import CommitteeNotationPlotDialog from "../../../../services/committees/CommitteeNotationPlotDialog";
import generatePath from "../../../../services/routing/generatePath";
import { CONTRIBUTION_DETAILS_LINK } from "../../../../routes/private";
import IconChart from "../../../../services/icons/IconChart";

enum BatchActions {
  RemoveFromCommittee,
  RetainContributionsFor2ndCommittee,
  RetainContributions,
  RefuseContributions,
}

const CommitteeDetailsEvaluation: React.FC<RouteComponentProps> = () => {
  /* Loading */
  const {
    committee,
    removeContributionsFromCommittee,
    bulkUpdateContributionsStatuses,
    loadCommittee,
  } = useCommittee() as CommitteeAPILoaded;

  const { loading, error: loadingError, reload } = useLoader(
    () => loadCommittee(committee.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [committee.id],
  );

  /* APIs */
  const { t } = useTranslation(["contributions", "committees", "ui"]);
  const { success, error } = useToasts();
  const navigate = useNavigate();

  /* Hooks */
  const [searchedText, setSearchedText] = useState("");
  const [selectedContributions, setSelectedContributions] = useState(
    new Set<Contribution["id"]>(),
  );
  const [showPlot, setShowPlot] = useState(false);

  const [batchAction, setBatchAction] = useState(
    BatchActions.RetainContributionsFor2ndCommittee,
  );

  const [minCustomerFit, setMinCustomerFit] = useState<number | null>(null);
  const [maxCustomerFit, setMaxCustomerFit] = useState<number | null>(null);

  const [minCompanyFit, setMinCompanyFit] = useState<number | null>(null);
  const [maxCompanyFit, setMaxCompanyFit] = useState<number | null>(null);

  const [minGlobal, setMinGlobal] = useState<number | null>(null);
  const [maxGlobal, setMaxGlobal] = useState<number | null>(null);

  const [
    selectedVisibleContributionsIds,
    setSelectedVisibleContributionsIds,
  ] = useState<null | Contribution["id"][]>(null);

  const filteredContributions = useMemo(() => {
    let filteredContributions = [...committee.Contributions];

    if (
      minCustomerFit ||
      maxCustomerFit ||
      minCompanyFit ||
      maxCompanyFit ||
      minGlobal ||
      maxGlobal
    ) {
      filteredContributions = filteredContributions.filter(
        filterContributionOnScoresGenerator(
          filteredContributions.map((contribution) =>
            getContributionAverageScores(contribution),
          ),
          minCustomerFit,
          maxCustomerFit,
          minCompanyFit,
          maxCompanyFit,
          minGlobal,
          maxGlobal,
        ),
      );
    }

    filteredContributions = filteredContributions.filter(
      searchGenerator(searchedText),
    );

    return filteredContributions;
  }, [
    committee,
    searchedText,
    minCustomerFit,
    maxCustomerFit,
    minCompanyFit,
    maxCompanyFit,
    minGlobal,
    maxGlobal,
  ]);

  /* End loading */
  if (loading) return <Loading />;
  if (loadingError)
    return <LoaderErrors reload={reload} error={loadingError} />;

  /* Methods */
  const onAction = () => {
    const selectedVisibleContributionsIds = filteredContributions
      .filter((contribution) => selectedContributions.has(contribution.id))
      .map((contribution) => contribution.id);

    switch (batchAction) {
      case BatchActions.RetainContributions:
        setSelectedVisibleContributionsIds(selectedVisibleContributionsIds);
        break;
      case BatchActions.RetainContributionsFor2ndCommittee:
        setSelectedVisibleContributionsIds(selectedVisibleContributionsIds);
        break;
      case BatchActions.RemoveFromCommittee:
        removeContributionsFromCommittee(
          committee.id,
          selectedVisibleContributionsIds,
        ).then(
          () => {
            setSelectedContributions(new Set());
            success(
              t(
                "contributions:RETAIN_FOR_SECOND_EVALUATION_CONTRIBUTIONS.SUCCESS",
              ),
            );
          },
          () => {
            error(
              t(
                "contributions:RETAIN_FOR_SECOND_EVALUATION_CONTRIBUTIONS.ERROR",
              ),
            );
          },
        );
        break;
      case BatchActions.RefuseContributions:
        bulkUpdateContributionsStatuses(
          committee.id,
          selectedVisibleContributionsIds,
          ContributionStatus.REFUSED,
        ).then(
          () => {
            setSelectedContributions(new Set());
            success(t("contributions:REFUSE_CONTRIBUTIONS.SUCCESS"));
          },
          () => error(t("contributions:REFUSE_CONTRIBUTIONS.ERROR")),
        );
    }
  };

  const retainContributions = () => {
    if (
      selectedVisibleContributionsIds &&
      committee.type === CommitteeType.COMMITTEE_1
    ) {
      bulkUpdateContributionsStatuses(
        committee.id,
        selectedVisibleContributionsIds,
        ContributionStatus.RETAINED_FOR_SECOND_EVALUATION,
      ).then(
        () => {
          setSelectedContributions(new Set());
          success(
            t(
              "contributions:RETAIN_FOR_SECOND_EVALUATION_CONTRIBUTIONS.SUCCESS",
            ),
          );
          setSelectedVisibleContributionsIds(null);
        },
        () =>
          error(
            t("contributions:RETAIN_FOR_SECOND_EVALUATION_CONTRIBUTIONS.ERROR"),
          ),
      );
    } else if (
      selectedVisibleContributionsIds &&
      committee.type === CommitteeType.COMMITTEE_2
    ) {
      bulkUpdateContributionsStatuses(
        committee.id,
        selectedVisibleContributionsIds,
        ContributionStatus.RETAINED,
      ).then(
        () => {
          setSelectedContributions(new Set());
          success(t("contributions:RETAIN_CONTRIBUTIONS.SUCCESS"));
          setSelectedVisibleContributionsIds(null);
        },
        () => error(t("contributions:RETAIN_CONTRIBUTIONS.ERROR")),
      );
    }
  };

  return (
    <div className={"admin-tab-layout"}>
      <div className={"grid"}>
        <div className={"col-md-1-3"}>
          <input
            type="text"
            className="input input-search"
            placeholder={t("contributions:SEARCH_CONTRIBUTION")}
            onChange={(ev) => setSearchedText(ev.target.value)}
          />
        </div>
      </div>

      <div>
        <h2>{t("ui:FILTERS")}</h2>
        <div className={"grid"}>
          <div className={"col-md-3-10"}>
            {t("contributions:TOTAL_CUSTOMER_FIT")}
            <input
              className={"input input-s"}
              placeholder={t("ui:MIN")}
              min="0"
              type={"number"}
              onChange={(ev) =>
                setMinCustomerFit(parseInt(ev.target.value) || null)
              }
            />
            <input
              className={"input input-s"}
              placeholder={t("ui:MAX")}
              min="0"
              type={"number"}
              onChange={(ev) =>
                setMaxCustomerFit(parseInt(ev.target.value) || null)
              }
            />
          </div>

          <div className={"col-md-3-10"}>
            {t("contributions:TOTAL_COMPANY_FIT")}
            <input
              className={"input input-s"}
              placeholder={t("ui:MIN")}
              min="0"
              type={"number"}
              onChange={(ev) =>
                setMinCompanyFit(parseInt(ev.target.value) || null)
              }
            />
            <input
              className={"input input-s"}
              placeholder={t("ui:MAX")}
              min="0"
              type={"number"}
              onChange={(ev) =>
                setMaxCompanyFit(parseInt(ev.target.value) || null)
              }
            />
          </div>

          <div className={"col-md-3-10"}>
            {t("contributions:TOTAL_GLOBAL_FIT")}
            <input
              className={"input input-s"}
              placeholder={t("ui:MIN")}
              min="0"
              type={"number"}
              onChange={(ev) => setMinGlobal(parseInt(ev.target.value) || null)}
            />
            <input
              className={"input input-s"}
              placeholder={t("ui:MAX")}
              min="0"
              type={"number"}
              onChange={(ev) => setMaxGlobal(parseInt(ev.target.value) || null)}
            />
          </div>

          <div className={"col-md-1-10"}>
            <button
              className={"btn btn-1 btn-small btn-block"}
              onClick={() => setShowPlot(true)}
            >
              <IconChart />
            </button>
          </div>
        </div>
      </div>

      <div>
        <ContributionsDetailsTable
          readOnly={!!committee.closureDate}
          visibleContributions={filteredContributions}
          onSelectContributions={(selectedContributions) =>
            setSelectedContributions(selectedContributions)
          }
          selectedContributions={selectedContributions}
        />
      </div>

      {!committee.closureDate && (
        <fieldset disabled={filteredContributions.length <= 0}>
          <div className={"grid"}>
            <div className={"col-md-2-4"}>
              <select
                className={"select"}
                value={batchAction}
                onChange={(ev) => setBatchAction(parseInt(ev.target.value))}
              >
                {committee.type === CommitteeType.COMMITTEE_1 && (
                  <option
                    value={BatchActions.RetainContributionsFor2ndCommittee}
                  >
                    {t("committees:RETAIN_CONTRIBUTIONS_FOR_2ND_COMMITTEE")}
                  </option>
                )}
                {committee.type === CommitteeType.COMMITTEE_2 && (
                  <option value={BatchActions.RetainContributions}>
                    {t("committees:RETAIN_CONTRIBUTIONS")}
                  </option>
                )}
                <option value={BatchActions.RefuseContributions}>
                  {t("committees:REFUSE_SELECTED_CONTRIBUTIONS")}
                </option>
                <option value={BatchActions.RemoveFromCommittee}>
                  {t("committees:REMOVE_CONTRIBUTIONS_FROM_COMMITTEE")}
                </option>
              </select>
            </div>
            <div className={"col-md-1-4"}>
              <button
                className={"btn-1 btn-full-height"}
                type={"button"}
                onClick={onAction}
              >
                {t("ui:OK")}
              </button>
            </div>
          </div>
        </fieldset>
      )}

      {selectedVisibleContributionsIds && (
        <Dialog onClose={() => setSelectedVisibleContributionsIds(null)}>
          {t(
            committee.type === CommitteeType.COMMITTEE_1
              ? "committees:retainContributionsDialog.COMMITTEE_1"
              : "committees:retainContributionsDialog.COMMITTEE_2",
          )}
          <div className={"btns-bar dialog-footer"}>
            <button
              type={"button"}
              className={"btn-outlined"}
              onClick={() => setSelectedVisibleContributionsIds(null)}
            >
              {t("ui:CANCEL")}
            </button>
            <button
              type={"button"}
              className={"btn-1"}
              onClick={retainContributions}
            >
              {t("ui:OK")}
            </button>
          </div>
        </Dialog>
      )}

      {showPlot && (
        <CommitteeNotationPlotDialog
          contributions={committee.Contributions}
          onClose={() => setShowPlot(false)}
          onContributionClick={(contributionId) =>
            navigate(
              generatePath(CONTRIBUTION_DETAILS_LINK, { contributionId }),
            )
          }
        />
      )}
    </div>
  );
};

export default CommitteeDetailsEvaluation;
