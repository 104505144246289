import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import pictoUser from "../../assets/img/picto-user-grey.svg";

const IconUser: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img src={pictoUser} className="picto" alt={t("icons:USER_ALT")} />;
};

export default IconUser;
