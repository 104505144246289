import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const Loading: FunctionComponent = () => {
  const { t } = useTranslation(["ui"]);

  return <div>{t("ui:LOADING")}</div>;
};

export default Loading;
