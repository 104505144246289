import React, { FunctionComponent, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import { number, object, SchemaOf, string } from "yup";
import {
  ContributionEvaluationToSend,
  getCompanyScore,
  getCustomerScore,
  getTotalScoreSum,
  isClosed,
  PartialContributionToSend,
} from "./contribution";
import { cx } from "@emotion/css";
import FSelectParse from "../ui/elements/FSelectParse";
import ValidationsErrors from "../validations/ValidationsErrors";
import useAuth from "../auth/hooks/useAuth";
import { AuthAPIConnected } from "../auth/types";
import {
  getCanEvaluateCompanyFit,
  getCanEvaluateCustomerFit,
} from "../auth/user";
import { useTranslation } from "react-i18next";
import IconScore from "../icons/IconScore";

interface Props {
  contribution: PartialContributionToSend;
  initialEvaluation: ContributionEvaluationToSend;
  onSubmit: (values: ContributionEvaluationToSend) => void;
  onCancel?: (isDirty: boolean) => void;
  onDirtyChange?: (isDirty: boolean) => void;
}

const ContributionEvaluationForm: FunctionComponent<Props> = ({
  initialEvaluation,
  contribution,
  onSubmit,
  onDirtyChange,
  onCancel,
}) => {
  /* APIs */
  const { user } = useAuth() as AuthAPIConnected;
  const { t } = useTranslation(["contributions", "ui"]);

  const EvaluationSchema: SchemaOf<ContributionEvaluationToSend> = useMemo(
    () =>
      object({
        customerScore1: number()
          .label(t("contributions:form.IMPORTANCE"))
          .nullable()
          .defined(),
        customerScore2: number()
          .label(t("contributions:form.TANGIBLE"))
          .nullable()
          .defined(),
        customerScore3: number()
          .label(t("contributions:form.SATISFACTION"))
          .nullable()
          .defined(),
        companyScore1: number()
          .label(t("contributions:form.LUCRATIVE"))
          .nullable()
          .defined(),
        companyScore2: number()
          .label(t("contributions:form.PROFITABLE"))
          .nullable()
          .defined(),
        companyScore3: number()
          .label(t("contributions:form.ACHIEVABLE"))
          .nullable()
          .defined(),
        comment: string()
          .label(t("contributions:form.COMMENT"))
          .default("")
          .defined(),
      }).defined(),
    [t],
  );

  /* Getters */
  const canModify = !isClosed(contribution);
  const canEvaluateCustomerFit = canModify && getCanEvaluateCustomerFit(user);
  const canEvaluateCompanyFit = canModify && getCanEvaluateCompanyFit(user);

  return (
    <Formik
      validationSchema={EvaluationSchema}
      initialValues={initialEvaluation}
      validateOnChange={true}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(EvaluationSchema.validateSync(values));
      }}
    >
      {({ values, errors, dirty }) => {
        const customerScore = getCustomerScore(values);
        const companyScore = getCompanyScore(values);
        const totalScore = getTotalScoreSum(values);

        if (onDirtyChange) onDirtyChange(dirty);

        return (
          <Form>
            <fieldset disabled={!canEvaluateCustomerFit}>
              <div className="input-block grid">
                <label className="section-subtitle">
                  {t("contributions:CUSTOMER_FIT")}
                </label>
                <div className="col-s-1-2 col-md-1-4">
                  <label className="input-label">
                    {t("contributions:form.IMPORTANCE")}&nbsp;
                    <div className="tooltip">
                      <span className="tooltiptext">
                        {t("contributions:tooltip.IMPORTANCE")}
                      </span>
                    </div>
                  </label>
                  <FSelectParse
                    name="customerScore1"
                    className="select"
                    parse={(val) => (val === "" ? null : parseInt(val))}
                  >
                    <option value={""} />
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </FSelectParse>
                </div>
                <div className="col-s-1-2 col-md-1-4">
                  <label className="input-label">
                    {t("contributions:form.TANGIBLE")}&nbsp;
                    <div className="tooltip">
                      <span className="tooltiptext">
                        {t("contributions:tooltip.TANGIBLE")}
                      </span>
                    </div>
                  </label>
                  <FSelectParse
                    name="customerScore2"
                    className="select"
                    parse={(val) => (val === "" ? null : parseInt(val))}
                  >
                    <option value={""} />
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </FSelectParse>
                </div>
                <div className="col-s-1-2 col-md-1-4">
                  <label className="input-label">
                    {t("contributions:form.SATISFACTION")}&nbsp;
                    <div className="tooltip">
                      <span className="tooltiptext">
                        {t("contributions:tooltip.SATISFACTION")}
                      </span>
                    </div>
                  </label>
                  <FSelectParse
                    name="customerScore3"
                    className="select"
                    parse={(val) => (val === "" ? null : parseInt(val))}
                  >
                    <option value={""} />
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </FSelectParse>
                </div>
                <div className="col-s-1-2 col-md-1-4">
                  <label className="input-label">
                    <strong>{t("contributions:form.SUM")}</strong>
                  </label>
                  <p className="input computed-result">
                    {customerScore || "-"}
                  </p>
                </div>
              </div>
            </fieldset>

            <fieldset disabled={!canEvaluateCompanyFit}>
              <div className="input-block grid">
                <label className="section-subtitle">
                  {t("contributions:COMPANY_FIT")}
                </label>
                <div className="col-s-1-2 col-md-1-4">
                  <label className="input-label">
                    {t("contributions:form.LUCRATIVE")}&nbsp;
                    <div className="tooltip">
                      <span className="tooltiptext">
                        {t("contributions:tooltip.LUCRATIVE")}
                      </span>
                    </div>
                  </label>
                  <FSelectParse
                    name="companyScore1"
                    className="select"
                    parse={(val) => (val === "" ? null : parseInt(val))}
                  >
                    <option value={""} />
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </FSelectParse>
                </div>
                <div className="col-s-1-2 col-md-1-4">
                  <label className="input-label">
                    {t("contributions:form.PROFITABLE")}&nbsp;
                    <div className="tooltip">
                      <span className="tooltiptext">
                        {t("contributions:tooltip.PROFITABLE")}
                      </span>
                    </div>
                  </label>
                  <FSelectParse
                    name="companyScore2"
                    className="select"
                    parse={(val) => (val === "" ? null : parseInt(val))}
                  >
                    <option value={""} />
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </FSelectParse>
                </div>
                <div className="col-s-1-2 col-md-1-4">
                  <label className="input-label">
                    {t("contributions:form.ACHIEVABLE")}&nbsp;
                    <div className="tooltip">
                      <span className="tooltiptext">
                        {t("contributions:tooltip.ACHIEVABLE")}
                      </span>
                    </div>
                  </label>
                  <FSelectParse
                    name="companyScore3"
                    className="select"
                    parse={(val) => (val === "" ? null : parseInt(val))}
                  >
                    <option value={""} />
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </FSelectParse>
                </div>
                <div className="col-s-1-2 col-md-1-4">
                  <label className="input-label">
                    <strong>{t("contributions:form.SUM")}</strong>
                  </label>
                  <p className="input computed-result">{companyScore || "-"}</p>
                </div>
              </div>
            </fieldset>
            <div className="input-block">
              <label className="input-label">
                {t("contributions:GLOBAL_FIT")}
              </label>
              <div className={cx(["score score-big", !totalScore && "nd"])}>
                <IconScore />
                <span>{totalScore || "ND"}</span>
              </div>
            </div>

            <div className="input-block">
              <label htmlFor={"comment-input"} className="input-label">
                {t("contributions:form.COMMENT")}
              </label>
              <Field
                as={"textarea"}
                className="textarea"
                cols={30}
                rows={10}
                name="comment"
                id={"comment-input"}
                disabled={!canModify}
              />
            </div>

            {/* Handling errors */}
            <ValidationsErrors errors={errors} />

            {canModify && (
              <div className="form-footer btns-bar">
                {onCancel && (
                  <button
                    onClick={() => onCancel(dirty)}
                    type={"button"}
                    className="btn-outlined"
                  >
                    {t("ui:CANCEL")}
                  </button>
                )}
                <button type="submit" className="btn-1">
                  {t("ui:SAVE")}
                </button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContributionEvaluationForm;
