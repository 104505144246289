import { isBefore, parseISO } from "date-fns";
import { CommitteeContribution } from "../contributions/api";

export interface CommitteeBase {
  name: string;
  date: string;
  type: number;
}

export interface CommitteeToSend extends CommitteeBase {}

export interface Committee extends CommitteeBase {
  id: number;
  closureDate: string | null;
  Contributions: CommitteeContribution[];
}

export const COMMITTEE_ALL_TYPES = -1;

export enum CommitteeType {
  COMMITTEE_1 = 1,
  COMMITTEE_2 = 2,
}
export const COMMITTEE_TYPES = Object.entries(CommitteeType)
  .filter(([num]) => !isNaN(parseInt(num)))
  .map(([num]) => parseInt(num));

export const COMMITTEE_ALL_STATUSES = -1;

export enum CommitteeStatus {
  UPCOMING,
  IN_PROGRESS,
  CLOSED,
}

export const COMMITTEE_STATUS_ENTRIES = Object.entries(CommitteeStatus).filter(
  ([id]) => !isNaN(parseInt(id)),
) as [string, keyof typeof CommitteeStatus][];

export const COMMITTEE_STATUS_MAP = Object.fromEntries(
  Object.entries(CommitteeStatus).map(([key, value]) => [
    value as CommitteeStatus,
    key as keyof typeof CommitteeStatus,
  ]),
);

export function getCommitteeStatus(committee: {
  date: CommitteeBase["date"];
  closureDate?: Committee["closureDate"];
}): CommitteeStatus {
  const committeeDate = parseISO(committee.date);

  return !!committee.closureDate
    ? CommitteeStatus.CLOSED
    : isBefore(new Date(), committeeDate)
    ? CommitteeStatus.UPCOMING
    : CommitteeStatus.IN_PROGRESS;
}
