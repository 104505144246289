/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useMemo } from "react";
import "./services/i18n";
import AuthContext from "./services/auth/AuthContext";
import { useProvideAuth } from "./services/auth/hooks/useProvideAuth";
import Routes from "./routes";
import { ProvideToast } from "./services/toast-notifications";
import { useTranslation } from "react-i18next";
import baseAPI from "./services/auth/baseAPI";
import { Auth0Provider } from "@auth0/auth0-react";
const App: React.FC = () => {
  const auth = useProvideAuth();
  const translationProvider = useTranslation(["auth", "ui"]);

  useMemo(() => {
    baseAPI.defaults.headers["Accept-Language"] =
      translationProvider.i18n.language;
  }, [translationProvider]);

  useEffect(() => {
    const cookieScript = document.createElement("script");
    cookieScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    cookieScript.setAttribute("data-document-language", "true");
    cookieScript.setAttribute(
      "data-domain-script",
      process.env.REACT_APP_ONETRUST_DOMAIN || "",
    );
    cookieScript.type = "text/javascript";
    cookieScript.charset = "UTF-8";

    const callCookieScript = document.createElement("script");
    callCookieScript.type = "text/javascript";
    callCookieScript.innerHTML = "function OptanonWrapper() { }";
    document.body.appendChild(cookieScript);
    document.body.appendChild(callCookieScript);

    auth.checkUserValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        redirectUri={window.location.origin}
        onRedirectCallback={auth.setAuth0State}
      >
        <ProvideToast>
          <Routes />
        </ProvideToast>
      </Auth0Provider>
    </AuthContext.Provider>
  );
};

export default App;
