import React, { ReactElement } from "react";
import { ExtractRouteParams } from "../types";
import { Link as RRLink, LinkProps as RRLinkProps } from "@reach/router";
import generatePath from "../generatePath";

interface OwnProps<T extends string>
  extends React.PropsWithoutRef<RRLinkProps<never>> {
  to: T;
  params?: never;
}
interface OwnPropsWithParams<T extends string>
  extends React.PropsWithoutRef<RRLinkProps<never>> {
  to: T;
  params: ExtractRouteParams<T>;
}

type Props<T extends string> = (T extends
  | `${string}:${string}/${string}`
  | `${string}:${string}`
  ? OwnPropsWithParams<T>
  : OwnProps<T>) &
  React.RefAttributes<HTMLAnchorElement>;

const NavLink = <T extends string>(props: Props<T>): ReactElement => {
  return (
    <RRLink
      {...props}
      to={generatePath(props.to, props.params)}
      getProps={({ isCurrent }) =>
        isCurrent && {
          className: props.className
            ? props.className.concat(" active")
            : "active",
        }
      }
    />
  );
};

export default NavLink;
