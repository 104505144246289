import React, { FunctionComponent, useRef, useState } from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { useScrollTo } from "../../services/ui/utils";
import { CONTRIBUTIONS_LINK } from "../../routes/private";
import useAuth from "../../services/auth/hooks/useAuth";
import { AuthAPIConnected } from "../../services/auth/types";
import ContributionForm from "../../services/contributions/ContributionForm";
import { getDefaultContribution } from "../../services/contributions/contribution";
import useProvideContribution from "../../services/contributions/useProvideContribution";
import { useToasts } from "../../services/toast-notifications";
import { useTranslation } from "react-i18next";
import Dialog from "../../services/ui/elements/Dialog";

const NewContribution: FunctionComponent<RouteComponentProps> = () => {
  /* APIs */
  useScrollTo();
  const { user } = useAuth() as AuthAPIConnected;
  const navigate = useNavigate();
  const { success, error } = useToasts();
  const { createContribution } = useProvideContribution();
  const contribution = getDefaultContribution();
  const { t } = useTranslation(["contributions", "ui"]);

  /* Hooks */
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const formIsDirty = useRef(false);

  /* Methods */
  const cancel = (isDirty: boolean) => {
    isDirty ? setCancelModalOpen(true) : navigate(CONTRIBUTIONS_LINK);
  };

  return (
    <>
      <div className="page-head">
        <div className="row-s">
          <div className="col">
            <h1 className="page-title">
              {t("contributions:NEW_CONTRIBUTION")}
            </h1>
            <button
              className="link link-small"
              onClick={() => cancel(formIsDirty.current)}
            >
              &lt; {t("ui:GO_BACK")}
            </button>
          </div>
        </div>
      </div>

      <ContributionForm
        user={user}
        contribution={{ ...contribution, User: user, userId: user.userId }}
        onSubmit={(values) => {
          createContribution(values).then(
            () => {
              success(
                values.draft
                  ? t("contributions:sent-contribution.SAVED_DRAFT")
                  : t("contributions:sent-contribution.SUBMITTED"),
              );
              return navigate(CONTRIBUTIONS_LINK);
            },
            (err) => {
              if (err?.response?.status === 400) {
                error(
                  t("contributions:sent-contribution.ERROR_CREATION_REQUIRED"),
                );
              } else {
                error(t("contributions:sent-contribution.ERROR_CREATION"));
              }
            },
          );
        }}
        onCancel={cancel}
        onDirtyChange={(isDirty) => (formIsDirty.current = isDirty)}
      />

      {cancelModalOpen && (
        <Dialog>
          {t("contributions:sent-contribution.CANCELLED")}

          <div className={"btns-bar dialog-footer"}>
            <button
              type={"button"}
              className={"btn-outlined"}
              onClick={() => setCancelModalOpen(false)}
            >
              {t("ui:CANCEL")}
            </button>
            <button
              type={"button"}
              className={"btn-1"}
              onClick={() => navigate(CONTRIBUTIONS_LINK)}
            >
              {t("ui:OK")}
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default NewContribution;
