import { RouteComponentProps } from "@reach/router";
import React, { useMemo, useState } from "react";
import useLoader from "../../../../services/routing/useLoader";
import { useProvideContributions } from "../../../../services/contributions/useProvideContributions";
import { searchGenerator } from "../../../../services/filter/search";
import Loading from "../../../../services/routing/components/Loading";
import LoaderErrors from "../../../../services/routing/components/LoaderErrors";
import {
  Contribution,
  ContributionStatus,
  filterContributionOnScoresGenerator,
  getContributionAverageScores,
  getCurrentStatus,
} from "../../../../services/contributions/contribution";
import { useTranslation } from "react-i18next";
import {
  CommitteeAPILoaded,
  useCommittee,
} from "../../../../services/committees/useProvideCommittee";
import { useToasts } from "../../../../services/toast-notifications";
import ContributionsDetailsTable from "../../../../services/contributions/ContributionsDetailsTable";
import { CommitteeType } from "../../../../services/committees/committee";

enum BatchActions {
  AddToCommittee,
  RefuseContributions,
}

const CommitteeDetailsContributions: React.FC<RouteComponentProps> = () => {
  /* Loading */
  const {
    loadAllContributions,
    contributions,
    bulkRefuseContributions,
    addContributionsToCommittee,
  } = useProvideContributions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { loading, error: loadingError, reload } = useLoader(
    loadAllContributions,
    [],
  );

  /* APIs */
  const { t } = useTranslation(["contributions", "committees", "ui"]);
  const { committee } = useCommittee() as CommitteeAPILoaded;
  const { success, error } = useToasts();

  /* Hooks */
  const [searchedText, setSearchedText] = useState("");
  const [selectedContributions, setSelectedContributions] = useState(
    new Set<Contribution["id"]>(),
  );

  const [batchAction, setBatchAction] = useState(BatchActions.AddToCommittee);

  const [minCustomerFit, setMinCustomerFit] = useState<number | null>(null);
  const [maxCustomerFit, setMaxCustomerFit] = useState<number | null>(null);

  const [minCompanyFit, setMinCompanyFit] = useState<number | null>(null);
  const [maxCompanyFit, setMaxCompanyFit] = useState<number | null>(null);

  const [minGlobal, setMinGlobal] = useState<number | null>(null);
  const [maxGlobal, setMaxGlobal] = useState<number | null>(null);

  const filteredContributions = useMemo(() => {
    let filteredContributions = [...contributions.values()].filter(
      (contribution) => {
        const currentStatus = getCurrentStatus(contribution);

        return (
          (committee.type === CommitteeType.COMMITTEE_1 &&
            currentStatus === ContributionStatus.SUBMITTED) ||
          (committee.type === CommitteeType.COMMITTEE_2 &&
            currentStatus === ContributionStatus.RETAINED_FOR_SECOND_EVALUATION)
        );
      },
    );

    if (
      minCustomerFit ||
      maxCustomerFit ||
      minCompanyFit ||
      maxCompanyFit ||
      minGlobal ||
      maxGlobal
    ) {
      filteredContributions = filteredContributions.filter(
        filterContributionOnScoresGenerator(
          filteredContributions.map((contribution) =>
            getContributionAverageScores(contribution),
          ),
          minCustomerFit,
          maxCustomerFit,
          minCompanyFit,
          maxCompanyFit,
          minGlobal,
          maxGlobal,
        ),
      );
    }

    const committeeContributionsIds = committee.Contributions.map(
      (contribution) => contribution.id,
    );
    if (committeeContributionsIds.length > 0) {
      filteredContributions = filteredContributions.filter(
        (contribution) =>
          committeeContributionsIds.indexOf(contribution.id) < 0,
      );
    }

    filteredContributions = filteredContributions.filter(
      searchGenerator(searchedText),
    );

    return filteredContributions;
  }, [
    contributions,
    searchedText,
    minCustomerFit,
    maxCustomerFit,
    minCompanyFit,
    maxCompanyFit,
    minGlobal,
    maxGlobal,
    committee.Contributions,
    committee.type,
  ]);

  /* End loading */
  if (loading) return <Loading />;
  if (loadingError)
    return <LoaderErrors reload={reload} error={loadingError} />;

  /* Methods */
  const onAction = () => {
    const selectedVisibleContributionsIds = filteredContributions
      .filter((contribution) => selectedContributions.has(contribution.id))
      .map((contribution) => contribution.id);

    switch (batchAction) {
      case BatchActions.AddToCommittee:
        addContributionsToCommittee(
          committee.id,
          selectedVisibleContributionsIds,
        ).then(
          () => {
            setSelectedContributions(new Set());
            success(t("committees:ADD_CONTRIBUTIONS_TO_COMMITTEE.SUCCESS"));
          },
          () => error(t("committees:ADD_CONTRIBUTIONS_TO_COMMITTEE.ERROR")),
        );
        break;
      case BatchActions.RefuseContributions:
        bulkRefuseContributions(
          selectedVisibleContributionsIds,
          ContributionStatus.REFUSED,
        ).then(
          () => {
            setSelectedContributions(new Set());
            success(t("contributions:REFUSE_CONTRIBUTIONS.SUCCESS"));
          },
          () => error(t("contributions:REFUSE_CONTRIBUTIONS.ERROR")),
        );
    }
  };

  return (
    <div className={"admin-tab-layout"}>
      <div className={"grid"}>
        <div className={"col-md-1-3"}>
          <input
            type="text"
            className="input input-search"
            placeholder={t("contributions:SEARCH_CONTRIBUTION")}
            onChange={(ev) => setSearchedText(ev.target.value)}
          />
        </div>
      </div>

      <div>
        <h2>{t("ui:FILTERS")}</h2>
        <div className={"grid"}>
          <div className={"col-md-1-3"}>
            {t("contributions:TOTAL_CUSTOMER_FIT")}
            <input
              className={"input input-s"}
              placeholder={t("ui:MIN")}
              min="0"
              type={"number"}
              onChange={(ev) =>
                setMinCustomerFit(parseInt(ev.target.value) || null)
              }
            />
            <input
              className={"input input-s"}
              placeholder={t("ui:MAX")}
              min="0"
              type={"number"}
              onChange={(ev) =>
                setMaxCustomerFit(parseInt(ev.target.value) || null)
              }
            />
          </div>

          <div className={"col-md-1-3"}>
            {t("contributions:TOTAL_COMPANY_FIT")}
            <input
              className={"input input-s"}
              placeholder={t("ui:MIN")}
              min="0"
              type={"number"}
              onChange={(ev) =>
                setMinCompanyFit(parseInt(ev.target.value) || null)
              }
            />
            <input
              className={"input input-s"}
              placeholder={t("ui:MAX")}
              min="0"
              type={"number"}
              onChange={(ev) =>
                setMaxCompanyFit(parseInt(ev.target.value) || null)
              }
            />
          </div>

          <div className={"col-md-1-3"}>
            {t("contributions:TOTAL_GLOBAL_FIT")}
            <input
              className={"input input-s"}
              placeholder={t("ui:MIN")}
              min="0"
              type={"number"}
              onChange={(ev) => setMinGlobal(parseInt(ev.target.value) || null)}
            />
            <input
              className={"input input-s"}
              placeholder={t("ui:MAX")}
              min="0"
              type={"number"}
              onChange={(ev) => setMaxGlobal(parseInt(ev.target.value) || null)}
            />
          </div>
        </div>
      </div>

      <div>
        <ContributionsDetailsTable
          visibleContributions={filteredContributions}
          onSelectContributions={(selectedContributions) =>
            setSelectedContributions(selectedContributions)
          }
          hideStatusColumn={true}
          selectedContributions={selectedContributions}
        />
      </div>

      {!committee.closureDate && (
        <fieldset
          disabled={
            filteredContributions.length <= 0 ||
            [...selectedContributions.values()].length <= 0
          }
        >
          <div className={"grid"}>
            <div className={"col-md-2-4"}>
              <select
                className={"select"}
                value={batchAction}
                onChange={(ev) => setBatchAction(parseInt(ev.target.value))}
              >
                <option value={BatchActions.AddToCommittee}>
                  {t("committees:ADD_SELECTED_CONTRIBUTIONS_TO_COMMITTEE")}
                </option>
                <option value={BatchActions.RefuseContributions}>
                  {t("committees:REFUSE_SELECTED_CONTRIBUTIONS")}
                </option>
              </select>
            </div>
            <div className={"col-md-1-4"}>
              <button
                className={"btn-1 btn-full-height"}
                type={"button"}
                onClick={onAction}
              >
                {t("ui:OK")}
              </button>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default CommitteeDetailsContributions;
