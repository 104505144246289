import { css } from "@emotion/css";

const baseFlex = css`
  display: flex;
`;

export const flexStart = css`
  ${baseFlex};
  justify-content: flex-start;
`;

export const flexCenter = css`
  ${baseFlex};
  justify-content: center;
`;

export const flexCrossCenter = css`
  ${baseFlex};
  align-content: center;
`;

export const flexBetween = css`
  ${baseFlex};
  justify-content: space-between;
`;

export const flexAround = css`
  ${baseFlex};
  justify-content: space-around;
`;

export const flexEvenly = css`
  ${baseFlex};
  justify-content: space-evenly;
`;

export const flexColumn = css`
  ${baseFlex};
  flex-direction: column;
`;

export const flexRow = css`
  ${baseFlex};
  flex-direction: row;
`;

export const redBackground = css`
  background-color: red;
`;
