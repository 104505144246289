import baseAPI from "../auth/baseAPI";
import { AxiosPromise } from "axios";
import { Committee, CommitteeToSend } from "./committee";
import {
  Contribution,
  ContributionStatus,
} from "../contributions/contribution";

export function getAllCommittees(): AxiosPromise<Committee[]> {
  return baseAPI.get("/committees");
}

export function getCommitteeById(
  committeeId: Committee["id"],
): AxiosPromise<Committee> {
  return baseAPI.get(`/committees/${committeeId}`);
}

export function createCommittee(
  committee: CommitteeToSend,
): AxiosPromise<Committee> {
  return baseAPI.post("/committees", committee);
}

export function updateCommittee(committee: Committee): AxiosPromise<void> {
  return baseAPI.put(`/committees/${committee.id}`, committee);
}

export function deleteCommittee(
  committeeId: Committee["id"],
): AxiosPromise<void> {
  return baseAPI.delete(`/committees/${committeeId}`);
}

export function removeContributionsFromCommittee(
  committeeId: Committee["id"],
  contributionsIds: Contribution["id"][],
): AxiosPromise<void> {
  return baseAPI.post(
    `/committees/${committeeId}/contributions/remove`,
    contributionsIds,
  );
}

export function bulkUpdateContributionsStatuses(
  committeeId: Committee["id"],
  contributionsIds: Contribution["id"][],
  status: ContributionStatus,
): AxiosPromise<void> {
  return baseAPI.post(`/committees/${committeeId}/contributions/status`, {
    status,
    contributionsIds,
  });
}

export function closeCommitteeById(
  committeeId: Committee["id"],
): AxiosPromise<Committee> {
  return baseAPI.post(`/committees/${committeeId}/close`);
}

export function reopenCommitteeById(
  committeeId: Committee["id"],
): AxiosPromise<Committee> {
  return baseAPI.post(`/committees/${committeeId}/reopen`);
}
