import React, { ReactElement } from "react";
import { LinkProps as RRLinkProps, Link as RRLink } from "@reach/router";
import { ExtractRouteParams } from "../types";
import generatePath from "../generatePath";

interface OwnProps<T extends string>
  extends React.PropsWithoutRef<RRLinkProps<never>> {
  to: T;
  params?: never;
}
interface OwnPropsWithParams<T extends string>
  extends React.PropsWithoutRef<RRLinkProps<never>> {
  to: T;
  params: ExtractRouteParams<T>;
}

type Props<T extends string> = (T extends
  | `${string}:${string}/${string}`
  | `${string}:${string}`
  ? OwnPropsWithParams<T>
  : OwnProps<T>) &
  React.RefAttributes<HTMLAnchorElement>;

const Link = <T extends string>(props: Props<T>): ReactElement => {
  return <RRLink {...props} to={generatePath(props.to, props.params)} />;
};

export default Link;
