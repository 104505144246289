import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import pictoTrash from "../../assets/img/picto-trash.svg";

const IconTrash: FunctionComponent = () => {
  const { t } = useTranslation(["icons"]);

  return <img src={pictoTrash} alt={t("icons:TRASH_ALT")} />;
};

export default IconTrash;
